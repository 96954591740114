<template>
  <div
    class="flex flex-col min-h-96 justify-between border-l-8 border-2 border-blue-500 text-base p-3"
  >
    <div class="flex justify-between mb-2 ml-2">
      <div class="text-3xl">
        {{ airport.name }}
      </div>
      <button @click="close" class="btn-icon">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
    <div class="flex-1 mt-5 px-2">
      <div class="text-3xl border-b border-gray-400 mb-3">
        {{ `${airport.icao} - ${airport.iata}` }}
      </div>
      <div class="text-lg">City: {{ `${airport.city}` }}</div>
      <div class="text-lg">Country: {{ `${airport.country}` }}</div>

      <div class="text-2xl mt-2">Local Time: {{ currentLocalTime }}</div>
    </div>

    <div class="text-right text-sm text-gray-400">
      {{ `${airport._id} | ${airport.timeZone}` }}
    </div>
  </div>
</template>

<script>
import { mixin } from "../mixins/mixin";
export default {
  name: "AirportInformation",
  mixins: [mixin],
  data() {
    return {
      dialog: true,
    };
  },
  props: ["airport"],
  computed: {
    currentLocalTime() {
      function getLocalTime(timeZone) {
        return new Intl.DateTimeFormat("en-US", {
          timeZone,
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",

          hour12: false, // Use 24-hour format
        }).format(new Date());
      }

      return getLocalTime(this.airport.timeZone);
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
