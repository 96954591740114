<template>
  <div>
    <!-- #region NAVBAR -->
    <nav>
      <ul class="nav-wrapper">
        <li class="icon" @click="handleGoBack">
          <a href="#">
            <span class="tooltip">Go Back</span>
            <span><i class="ml-3 fa-solid fa-arrow-left text-white"> </i></span>
          </a>
        </li>

        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white ml-3"> </i></span
          ></RouterLink>
        </li>

        <li>
          <a href="#">
            <div class="mx-3 text-base lg:text-2xl text-white">Assignments</div>
          </a>
        </li>

        <li>
          <a href="#">
            <div v-if="pages.length" class="flex justify-between fnt-sm">
              <button class="btn-icon" @click="handlePageUp">
                <i class="fa-solid fa-chevrons-up text-white"></i>
              </button>
              <div class="text-white">
                {{ `${pageNumber + 1} of ${pages.length}` }}
              </div>

              <button class="btn-icon" @click="handlePageDown">
                <i class="fa-solid fa-chevrons-down text-white"></i>
              </button>
            </div>
          </a>
        </li>

        <li @click="zoomIn" class="icon">
          <a href="#">
            <span class="tooltip">Zoom In</span>
            <span><i class="fa-regular fa-magnifying-glass-plus"></i></span>
          </a>
        </li>
        <li @click="zoomOut" class="icon">
          <a href="#">
            <span class="tooltip">Zoom Out</span>
            <span><i class="fa-regular fa-magnifying-glass-minus"></i></span>
          </a>
        </li>
        <li @click="getData" class="icon">
          <a href="#">
            <span class="tooltip">Refresh</span>
            <span><i class="fa-solid fa-rotate"></i></span>
          </a>
        </li>

        <li @click="handleToggleTimeZone" class="icon">
          <a href="#">
            <span class="tooltip">Change Time Zone</span>
            <span>
              <i
                :class="[
                  'fa-solid fa-clock',
                  localTime ? 'text-red-500' : 'text-white',
                ]"
              ></i>
            </span>
          </a>
        </li>
      </ul>
    </nav>
    <!-- #endregion -->

    <!-- SNACKBAR -->
    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <!-- UNABLE TO ASSIGN DIALOG -->
    <r-modal
      v-if="showUnableToAssignDialog"
      @close="handleHideUnableToAssignDialog"
    >
      <div v-if="showUnableToAssignDialog" class="p-5 bg-red-200">
        <div class="flex justify-between mb-2">
          <div class="flex items-center">
            <i class="fa-solid fa-exclamation-triangle"></i>
            <div class="text-2xl font-bold ml-2">Unable to Assign</div>
          </div>
          <button @click="handleHideUnableToAssignDialog" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div
          v-if="conflicts.length"
          class="w-full mb-5 border-b pb-3 border-gray-400"
        >
          <div class="text-lg font-bold px-1 mb-1">Assignment Conflicts</div>
          <ul>
            <li v-for="conflict in conflicts" :key="conflict._id">
              {{ formatConflictMessage(conflict) }}
            </li>
          </ul>
        </div>

        <div
          v-if="documentWarnings.length"
          class="w-full mb-5 border-b pb-3 border-gray-400"
        >
          <div class="text-lg font-bold px-1 mb-1">
            Documents Missing or Expired
          </div>
          <ul>
            <li v-for="(warning, index) in documentWarnings" :key="index">
              {{ warning }}
            </li>
          </ul>
        </div>

        <div
          v-if="trainingWarnings.length"
          class="w-full mb-5 border-b pb-3 border-gray-400"
        >
          <div class="text-lg font-bold px-1 mb-1">
            Training Missing or Expired
          </div>
          <ul>
            <li v-for="(warning, index) in trainingWarnings" :key="index">
              {{ warning }}
            </li>
          </ul>
        </div>

        <div class="flex justify-end mt-1">
          <button
            @click="handleHideUnableToAssignDialog"
            class="btn bg-gray-500 text-white"
          >
            Close
          </button>
        </div>
      </div>
    </r-modal>

    <!-- DATE FILTER DIALOG -->
    <r-modal v-if="showDateFilterDialog" @close="showDateFilterDialog = false">
      <div v-if="showDateFilterDialog" class="p-5">
        <div class="flex justify-between mb-2">
          <div class="text-2xl font-bold">Date Filter</div>
          <button @click="showDateFilterDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="flex">
          <div>
            <label
              for="date-filter"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Start Date</label
            >

            <input
              class="block w-32 rounded-md border-0 py-1.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="startDate"
              type="date"
              id="date-filter"
            />
          </div>

          <div class="ml-3">
            <label
              for="date-filter"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Number of Days</label
            >

            <select
              class="block w-24 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="numberOfDays"
            >
              <option
                v-for="option in numberOfDaysOptions"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
        </div>

        <div class="flex justify-end mt-1">
          <button
            @click="handleSaveDateFilter"
            class="btn bg-blue-500 text-white"
          >
            Apply Filter
          </button>
        </div>
      </div>
    </r-modal>

    <r-spinner v-if="loading"> </r-spinner>

    <div class="flight-panel-container" v-if="flight">
      <div class="flight-panel-header">
        <div class="text-lg font-bold">
          {{
            `${flight.aircraft.registration} | ${flight.flightNumber} | ${flight.origin.iata}-${flight.destination.iata}`
          }}
        </div>
      </div>

      <!-- Captain -->
      <div
        class="seat capt-seat"
        @click="handleSeatAction(captain)"
        :style="getSeatStyle(captain)"
      >
        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
        >
          {{ captain.seat }}
        </div>

        <i :class="getSeatIcon(captain)"> </i>

        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
        >
          {{ getSeatFooterText(captain) }}
        </div>
      </div>

      <!-- First Officer -->
      <div
        class="seat fo-seat"
        @click="handleSeatAction(firstOfficer)"
        :style="getSeatStyle(firstOfficer)"
      >
        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
        >
          {{ firstOfficer.seat }}
        </div>

        <i :class="getSeatIcon(firstOfficer)"> </i>

        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
        >
          {{ getSeatFooterText(firstOfficer) }}
        </div>
      </div>

      <!-- ACM-1 -->
      <div
        class="seat acm1-seat"
        @click="handleSeatAction(acm1)"
        :style="getSeatStyle(acm1)"
      >
        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
        >
          {{ acm1.seat }}
        </div>

        <i :class="getSeatIcon(acm1)"> </i>

        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
        >
          {{ getSeatFooterText(acm1) }}
        </div>
      </div>

      <!-- FA-A -->
      <div
        class="seat faa-seat"
        @click="handleSeatAction(faa)"
        :style="getSeatStyle(faa)"
      >
        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
        >
          {{ faa.seat }}
        </div>

        <i :class="getSeatIcon(faa)"> </i>

        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
        >
          {{ getSeatFooterText(faa) }}
        </div>
      </div>

      <!-- FA-B -->
      <div
        class="seat fab-seat"
        @click="handleSeatAction(fab)"
        :style="getSeatStyle(fab)"
      >
        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
        >
          {{ fab.seat }}
        </div>

        <i :class="getSeatIcon(fab)"> </i>

        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
        >
          {{ getSeatFooterText(fab) }}
        </div>
      </div>

      <!-- MX -->
      <div
        class="seat mx-seat"
        @click="handleSeatAction(mx)"
        :style="getSeatStyle(mx)"
      >
        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
        >
          {{ mx.seat }}
        </div>

        <i :class="getSeatIcon(mx)"> </i>

        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
        >
          {{ getSeatFooterText(mx) }}
        </div>
      </div>

      <!-- GSC -->
      <div
        class="seat gsc-seat"
        @click="handleSeatAction(gsc)"
        :style="getSeatStyle(gsc)"
      >
        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
        >
          {{ gsc.seat }}
        </div>

        <i :class="getSeatIcon(gsc)"> </i>

        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
        >
          {{ getSeatFooterText(gsc) }}
        </div>
      </div>

      <!-- DH-1 -->
      <div
        class="seat dh1-seat"
        @click="handleSeatAction(dh1)"
        :style="getSeatStyle(dh1)"
      >
        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
        >
          {{ dh1.seat }}
        </div>

        <i :class="getSeatIcon(dh1)"> </i>

        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
        >
          {{ getSeatFooterText(dh1) }}
        </div>
      </div>

      <!-- DH-2 -->
      <div
        class="seat dh2-seat"
        @click="handleSeatAction(dh2)"
        :style="getSeatStyle(dh2)"
      >
        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
        >
          {{ dh2.seat }}
        </div>

        <i :class="getSeatIcon(dh2)"> </i>

        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
        >
          {{ getSeatFooterText(dh2) }}
        </div>
      </div>

      <!-- DH-3 -->
      <div
        class="seat dh3-seat"
        @click="handleSeatAction(dh3)"
        :style="getSeatStyle(dh3)"
      >
        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
        >
          {{ dh3.seat }}
        </div>

        <i :class="getSeatIcon(dh3)"> </i>

        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
        >
          {{ getSeatFooterText(dh3) }}
        </div>
      </div>

      <!-- DH-4 -->
      <div
        class="seat dh4-seat"
        @click="handleSeatAction(dh4)"
        :style="getSeatStyle(dh4)"
      >
        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
        >
          {{ dh4.seat }}
        </div>

        <i :class="getSeatIcon(dh4)"> </i>

        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
        >
          {{ getSeatFooterText(dh4) }}
        </div>
      </div>

      <!-- DH-5 -->
      <div
        class="seat dh5-seat"
        @click="handleSeatAction(dh5)"
        :style="getSeatStyle(dh5)"
      >
        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
        >
          {{ dh5.seat }}
        </div>

        <i :class="getSeatIcon(dh5)"> </i>

        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
        >
          {{ getSeatFooterText(dh5) }}
        </div>
      </div>

      <!-- DH-6 -->
      <div
        class="seat dh6-seat"
        @click="handleSeatAction(dh6)"
        :style="getSeatStyle(dh6)"
      >
        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
        >
          {{ dh6.seat }}
        </div>

        <i :class="getSeatIcon(dh6)"> </i>

        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
        >
          {{ getSeatFooterText(dh6) }}
        </div>
      </div>

      <!-- ACM-2 -->
      <div
        class="seat acm2-seat"
        @click="handleSeatAction(acm2)"
        :style="getSeatStyle(acm2)"
      >
        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
        >
          {{ acm2.seat }}
        </div>

        <i :class="getSeatIcon(acm2)"> </i>

        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
        >
          {{ getSeatFooterText(acm2) }}
        </div>
      </div>

      <!-- ACM-3 -->
      <div
        class="seat acm3-seat"
        @click="handleSeatAction(acm3)"
        :style="getSeatStyle(acm3)"
      >
        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
        >
          {{ acm3.seat }}
        </div>

        <i :class="getSeatIcon(acm3)"> </i>

        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
        >
          {{ getSeatFooterText(acm3) }}
        </div>
      </div>

      <!-- ACM-4 -->
      <div
        class="seat acm4-seat"
        @click="handleSeatAction(acm4)"
        :style="getSeatStyle(acm4)"
      >
        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
        >
          {{ acm4.seat }}
        </div>

        <i :class="getSeatIcon(acm4)"> </i>

        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
        >
          {{ getSeatFooterText(acm4) }}
        </div>
      </div>

      <!-- ACM-5 -->
      <div
        class="seat acm5-seat"
        @click="handleSeatAction(acm5)"
        :style="getSeatStyle(acm5)"
      >
        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
        >
          {{ acm5.seat }}
        </div>

        <i :class="getSeatIcon(acm5)"> </i>

        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
        >
          {{ getSeatFooterText(acm5) }}
        </div>
      </div>

      <!-- ACM-6 -->

      <div
        class="seat acm6-seat"
        @click="handleSeatAction(acm6)"
        :style="getSeatStyle(acm6)"
      >
        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
        >
          {{ acm6.seat }}
        </div>

        <i :class="getSeatIcon(acm6)"> </i>

        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
        >
          {{ getSeatFooterText(acm6) }}
        </div>
      </div>

      <!-- ACM-7 -->

      <div
        class="seat acm7-seat"
        @click="handleSeatAction(acm7)"
        :style="getSeatStyle(acm7)"
      >
        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
        >
          {{ acm7.seat }}
        </div>

        <i :class="getSeatIcon(acm7)"> </i>

        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
        >
          {{ getSeatFooterText(acm7) }}
        </div>
      </div>

      <!-- FA-C -->
      <div
        class="seat fac-seat"
        @click="handleSeatAction(fac)"
        :style="getSeatStyle(fac)"
      >
        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
        >
          {{ fac.seat }}
        </div>

        <i :class="getSeatIcon(fac)"> </i>

        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
        >
          {{ getSeatFooterText(fac) }}
        </div>
      </div>

      <!-- FA-D -->
      <div
        class="seat fad-seat"
        @click="handleSeatAction(fad)"
        :style="getSeatStyle(fad)"
      >
        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
        >
          {{ fad.seat }}
        </div>

        <i :class="getSeatIcon(fad)"> </i>

        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
        >
          {{ getSeatFooterText(fad) }}
        </div>
      </div>

      <!-- FA-E -->
      <div
        class="seat fae-seat"
        @click="handleSeatAction(fae)"
        :style="getSeatStyle(fae)"
      >
        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
        >
          {{ fae.seat }}
        </div>

        <i :class="getSeatIcon(fae)"> </i>

        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
        >
          {{ getSeatFooterText(fae) }}
        </div>
      </div>

      <!-- FA-F -->
      <div
        class="seat faf-seat"
        @click="handleSeatAction(faf)"
        :style="getSeatStyle(faf)"
      >
        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
        >
          {{ faf.seat }}
        </div>

        <i :class="getSeatIcon(faf)"> </i>

        <div
          class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
        >
          {{ getSeatFooterText(faf) }}
        </div>
      </div>
    </div>

    <div class="board" ref="board">
      <!-- LEFT PANEL -->
      <!-- Each page has a list of employees -->

      <div v-if="page" class="left-panel">
        <div class="left-panel-header bg-gray-500-l2">
          <div
            class="text-base flex justify-center employees-center w-[90px] cursor-pointer"
            @click="handleSeatAction({ seat: '*' })"
          >
            Employees
          </div>
        </div>
        <div
          class="left-panel-row bg-gray-500-l4"
          v-for="item in page.items"
          :key="item.employee._id"
          :style="{ height: `${rowHeight}px` }"
        >
          <div class="text-base text-align text-bold text-truncate">
            {{
              `${item.employee.surname}, ${item.employee.givenName.substring(
                0,
                1
              )}`
            }}
          </div>
          <div class="text-[10px] text-truncate">
            {{ `${item.employee.jobTitle} ` }}
          </div>
          <div class="text-xs text-truncate">
            {{ `${item.employee.companyId.number}` }}
          </div>
        </div>
      </div>

      <!-- RIGHT PANEL -->
      <div v-if="page" class="right-panel-container" ref="scrollContainer">
        <div class="right-panel">
          <!-- Header -->
          <div
            class="right-panel-header bg-gray-500-l4 cursor-pointer"
            @click="handleOpenDateFilter(item)"
            v-for="(item, index) in dateFilter.days"
            :key="index"
            :style="{
              top: 0,
              left: `${(1440 / scale) * index}px`,
              width: `${dayWidth}px`,
            }"
          >
            {{ formatHeaderDate(item) }}
          </div>

          <!-- Day Lines -->
          <div
            class="day-line"
            v-for="(day, index) in dateFilter.days"
            :key="day"
            :style="{
              left: `${(1440 / scale) * index}px`,
              height: `${availableBoardHeight + 50}px`,
            }"
          ></div>

          <!-- Hour Boxes -->
          <div
            class="hour-box text-[10px]"
            :class="{ hidden: marker.value === '00' || scale > 4 }"
            v-for="(marker, index) in dateFilter.hourBoxes"
            :key="marker.key"
            :style="{ left: `${(60 / scale) * index}px` }"
          >
            {{ marker.value }}
          </div>

          <!-- Hour Lines -->
          <div
            class="hour-line"
            :class="{ hidden: marker.value === '00' || scale > 6 }"
            v-for="(marker, index) in dateFilter.hourLines"
            :key="marker.key"
            :style="{
              left: `${(60 / scale) * index}px`,
              height: `${availableBoardHeight + 7}px`,
            }"
          ></div>

          <!-- Now Box -->
          <div
            class="now-box"
            v-if="showCurrentTime"
            :style="{
              left: `${nowLineLeft}px`,
            }"
          >
            {{ formatTime(new Date(), "UTC").substring(0, 5) }}
          </div>

          <!-- Now Line -->
          <div
            class="now-line"
            v-if="showCurrentTime"
            :style="{
              left: `${nowLineLeft}px`,
              height: `${availableBoardHeight + 7}px`,
            }"
          ></div>

          <!-- Employees -->
          <div
            class="right-panel-row"
            v-for="(item, index) in page.items"
            :key="index"
            :style="{
              top: `${index * rowHeight + 50}px`,
              width: `${boardWidth}px`,
              height: `${rowHeight}px`,
            }"
          >
            <!-- Assignment Shadow  -->
            <div
              v-if="selectedSeat"
              class="assignment-shadow"
              @click="handleAssignEmployee(item.employee._id)"
              :style="computedAssignmentShadow"
            ></div>
            <!--Assignments-->
            <div
              v-for="_assignment in item.employee.flightAssignments"
              :key="_assignment._id"
              class="board-label-frame"
              :style="{
                width: `${_assignment.duration.totalMinutes / scale}px`,
                left: `${getLocationLeft(
                  _assignment._start,
                  dateFilter.days[0],
                  scale
                )}px`,
              }"
            >
              <div>
                <FlightAssignmentTile :assignment="_assignment" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import { mixin } from "../mixins/mixin";
import FlightAssignmentTile from "../components/FlightAssignmentTile.vue";

import EmployeeInformation from "../components/EmployeeInformation.vue";

import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";
import RModal from "../components/RModal.vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
  Switch,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";

export default {
  name: "FlightAssignmentBoard",
  components: {
    EmployeeInformation,
    FlightAssignmentTile,

    RSnackbar,
    RSpinner,
    RModal,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronUpDownIcon,
    Switch,
  },
  data() {
    return {
      loading: false,

      selectedAction: "Assign",
      selectedJobTitle: "*", //Used to filter schedule

      //-------------------------------Unable to Assign
      conflicts: [],
      documentWarnings: [],
      trainingWarnings: [],

      //---------------------------------------Dialogs
      showFlightAssignmentDialog: false,
      showUnableToAssignDialog: false,

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,

      //#region-----------------------------------Paginate
      availableBoardHeight: 0,
      fullSchedule: null,
      schedule: null,
      itemsPerPage: 0,
      pages: [],
      page: null,
      rowHeight: 60,
      disableNextPageButton: true,
      disablePreviousPageButton: true,
      //#endregion

      //----------------------------------------Board
      scale: 4,

      // assignmentsListDialog: false,
      originIata: "", //Used to sort station assignment flights
      paddingBefore: 30,
      paddingAfter: 30,

      //------------------------------------------Employee
      employeeDialog: false,
      showEmployeeInformationDialog: false,
      employee: null,
      workMonth: null,

      //------------------------------------------Filters and Settings

      search: "",

      showDateFilterDialog: false,
      startDate: null,
      numberOfDays: 7,
      numberOfDaysOptions: [3, 5, 7, 14, 21, 31],
    };
  },
  mixins: [mixin],
  created() {
    this.getData();
  },

  mounted() {
    this.$nextTick(() => {
      const board = this.$refs.board;

      this.availableBoardHeight = board.clientHeight - 120;

      this.itemsPerPage = Math.floor(
        this.availableBoardHeight / this.rowHeight
      );
    });

    window.addEventListener("resize", this.onResize, { passive: true });
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  computed: {
    localTime() {
      return this.$store.state.localTime;
    },

    flight() {
      return this.$store.getters.flight;
    },

    selectedSeat() {
      return this.$store.state.selectedSeat;
    },

    pageNumber() {
      return this.$store.getters.flightAssignmentBoardPageNumber;
    },

    showCurrentTime() {
      const now = new Date().getTime();

      return (
        now > new Date(this.dateFilter.start).getTime() &&
        now < new Date(this.dateFilter.end).getTime()
      );
    },

    nowLineLeft() {
      const x = this.getLocationLeft(
        new Date().toISOString(),
        this.dateFilter.start,
        this.scale
      );

      if (x > 0 || x < this.boardWidth) {
        return x;
      } else {
        return 0;
      }
    },

    boardWidth() {
      return this.dateFilter.days.length * (1440 / this.scale);
    },

    dayWidth() {
      return 1440 / this.scale;
    },

    dateFilter() {
      return this.$store.getters.dateFilter;
    },

    computedAssignmentShadow() {
      if (this.flight) {
        const minutes =
          (new Date(this.flight._start).getTime() -
            new Date(this.dateFilter.days[0]).getTime()) /
          1000 /
          60;

        const width =
          (this.paddingBefore +
            this.flight.duration.totalMinutes +
            this.paddingAfter) /
          this.scale;
        const left = (minutes - this.paddingBefore) / this.scale;

        let style = {
          width: `${width}px`,
          left: `${left}px`,
          height: "60px",
          borderLeft: "1px dashed blue",
          borderRight: "1px dashed blue",
          backgroundColor: "#a8a8f5b2",
          opacity: 0.5,
          color: "white",
          "z-index": 200,
        };

        return style;
      } else {
        return {
          width: "1px",
          left: "1px",
          height: "1px",
          visibility: "hidden",
        };
      }
    },

    auth() {
      return this.$store.state.auth;
    },

    captain() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "CAPT";
      });
    },

    firstOfficer() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "FO";
      });
    },

    acm1() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "ACM-1";
      });
    },

    acm2() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "ACM-2";
      });
    },

    acm3() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "ACM-3";
      });
    },

    acm4() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "ACM-4";
      });
    },

    acm5() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "ACM-5";
      });
    },

    acm6() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "ACM-6";
      });
    },

    acm7() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "ACM-7";
      });
    },

    faa() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "FA-A";
      });
    },

    fab() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "FA-B";
      });
    },
    fac() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "FA-C";
      });
    },

    fad() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "FA-D";
      });
    },

    fae() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "FA-E";
      });
    },

    faf() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "FA-F";
      });
    },

    dh1() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "DH-1";
      });
    },

    dh2() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "DH-2";
      });
    },

    dh3() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "DH-3";
      });
    },

    dh4() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "DH-4";
      });
    },

    dh5() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "DH-5";
      });
    },

    dh6() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "DH-6";
      });
    },

    mx() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "MX";
      });
    },

    gsc() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "GSC";
      });
    },
  },
  methods: {
    handleToggleTimeZone() {
      const localTime = !this.localTime;
      this.$store.commit("updateLocalTime", localTime);
    },

    handleHideUnableToAssignDialog() {
      this.showUnableToAssignDialog = false;
    },

    formatConflictMessage(conflict) {
      let message = "Undefined conflict";

      //TODO: Add logic to handle different conflict types
      switch (conflict.type) {
        case "Flight":
          const { flightNumber, aircraft, origin, destination, _start, _end } =
            conflict;

          message = ` ${flightNumber} . ${aircraft.registration} . ${
            origin.iata
          }-${destination.iata} . ${
            (this.formatDateTime(_start), origin.timeZone)
          } - ${this.formatTime(_end, destination.timezone)}`;

          break;

        default:
          break;
      }

      return message;
    },

    getSeatStyle(item) {
      let style = {
        backgroundColor: "var(--light-gray)",
      };

      if (item.employee) {
        style.backgroundColor = "var(--assigned)";
      } else {
        if (item.required) {
          style.backgroundColor = "var(--missing)";
        }
      }

      if (this.selectedSeat === item.seat) {
        style.border = "2px solid blue";
        style.boxShadow = "3px 3px 6px blue";
      }

      return style;
    },

    getSeatFooterText(item) {
      if (item.employee) {
        return `${item.employee.surname}, ${item.employee.givenName.substring(
          0,
          1
        )}`;
      } else {
        if (item.required) {
          return "Assign";
        } else {
          return "";
        }
      }
    },

    getSeatIcon(item) {
      //TODO: Add logic to handle different icons

      let icon = "fa-solid fa-user-slash";

      switch (item.seat) {
        case "CAPT":
          if (item.required) {
            if (item.employee) {
              icon = "fa-solid fa-user-pilot";
            } else {
              icon = "fa-solid fa-exclamation";
            }
          }

          break;

        default:
          if (item.required) {
            if (item.employee) {
              icon = "fa-solid fa-user";
            } else {
              icon = "fa-solid fa-exclamation";
            }
          }
          break;
      }

      return icon;
    },

    handleSeatAction(item) {
      this.$store.commit("updateSelectedSeat", item.seat);

      //TODO: Add logic to handle different actions

      switch (this.selectedAction) {
        case "Assign":
          this.handleFilterSchedule();
          break;
        default:
          break;
      }
    },

    handleFilterSchedule() {
      // Deep comparison (for objects):
      function isEqual(obj1, obj2) {
        return JSON.stringify(obj1) === JSON.stringify(obj2); // Simple deep comparison, might need more robust solution for complex objects
      }

      if (this.selectedSeat) {
        this.selectedJobTitle = "*";

        switch (this.selectedSeat) {
          case "CAPT":
            this.selectedJobTitle = "Captain";
            break;
          case "FO":
            this.selectedJobTitle = "First Officer";
            break;
          case "FA-A":
            this.selectedJobTitle = "Senior Flight Attendant";
            break;
          case "FA-B":
          case "FA-C":
          case "FA-D":
          case "FA-E":
          case "FA-F":
            this.selectedJobTitle = "Flight Attendant";
            break;
          case "MX":
            this.selectedJobTitle = "Ground Mechanic";
            break;
          case "GSC":
            //TODO set based on qualification
            this.selectedJobTitle = "*";
            break;
          default:
            break;
        }

        if (this.selectedJobTitle === "*") {
          this.schedule = [...this.fullSchedule];
        } else {
          this.schedule = this.fullSchedule.filter((item) => {
            return item.employee.jobTitle === this.selectedJobTitle;
          });
        }
      } else {
        this.schedule = [...this.fullSchedule];
      }

      this.paginate();
    },
    async handleAssignEmployee(id) {
      //TODO warning if another employee is already assigned to this seat

      if (!this.selectedSeat) {
        this.snackbarColor = "red";
        this.snackbarText = "Please select a seat to assign.";
        this.snackbarVisible = true;

        setTimeout(() => {
          this.snackbarVisible = false;
        }, 3000);

        return;
      }

      if (!this.selectedSeat) return;

      try {
        const token = this.auth.token;
        const _id = this.flight._id;
        let res;
        this.conflicts = [];
        this.documentWarnings = [];
        this.trainingWarnings = [];

        res = await api.put(
          `/aircraft-events/${_id}/crewmembers/precheck/assign`,
          {
            employeeId: id,
            seat: this.selectedSeat,
          },
          {
            headers: { Authorization: "Bearer " + token },
          }
        );

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;

          return;
        }

        this.conflicts = res.data.conflicts;
        this.documentWarnings = res.data.documentWarnings;
        this.trainingWarnings = res.data.trainingWarnings;

        if (
          this.conflicts.length +
            this.documentWarnings.length +
            this.trainingWarnings.length >
          0
        ) {
          this.showUnableToAssignDialog = true;
          return;
        }

        res = await api.put(
          `/aircraft-events/${_id}/crewmembers/assign`,
          {
            seat: this.selectedSeat,
            employeeId: id,
          },
          {
            headers: { Authorization: "Bearer " + token },
          }
        );

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;

          setTimeout(() => {
            this.snackbarVisible = false;
          }, 3000);
        }

        const flight = res.data.flight;

        this.$store.commit("updateFlight", flight);

        this.getData();
      } catch (error) {
        this.loading = false;
        this.$store.commit("updateSelectedSeat", null);
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async handleRemoveEmployee(assignment, e) {
      e.stopPropagation();
      try {
        const token = this.auth.token;
        const _id = this.flight._id;

        const res = await api.put(
          `/aircraft-events/${_id}/crewmembers/remove`,
          {
            assignment,
          },
          {
            headers: { Authorization: "Bearer " + token },
          }
        );

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;

          setTimeout(() => {
            this.snackbarVisible = false;
          }, 3000);
        }

        const flight = res.data.flight;

        this.$store.commit("updateFlight", flight);
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    formatCrewmember(crewmember) {
      if (crewmember.employee) {
        return `${crewmember.employee.surname}, ${crewmember.employee.givenName}`;
      } else {
        if (crewmember.required) {
          return "Please Assign.";
        } else {
          return "Not Required";
        }
      }
    },

    async getData() {
      if (!this.auth) {
        this.$router.push({ name: "Home" });
        return;
      }
      this.loading = true;

      this.fullSchedule = null;
      this.schedule = null;
      this.pages = [];
      this.page = null;

      try {
        const token = this.auth.token;

        const res = await api.get("/assignments", {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: {
            _start: this.dateFilter.start,
            _end: this.dateFilter.end,
          },
        });

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.fullSchedule = res.data.schedule;

        this.handleFilterSchedule();
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    paginate() {
      this.pages = [];
      let _pages = [];
      this.page = null;

      if (this.schedule?.length) {
        let chunk = Math.min(
          Math.floor(this.availableBoardHeight / this.rowHeight),
          this.schedule.length
        );

        //Show warning if not enough space to display
        if (chunk < 1) {
          this.snackbarColor = "red";
          this.snackbarText = "Not enough screen space to display data";
          this.snackbarVisible = true;
          return;
        }

        //Split data into pages
        for (let i = 0; i < this.schedule.length; i += chunk) {
          let tempArray;
          tempArray = this.schedule.slice(i, i + chunk);
          _pages.push({
            index: _pages.length,
            items: tempArray,
          });
        }

        this.pages = [..._pages];

        const i = this.pages.findIndex((page) => {
          return page.index === this.pageNumber;
        });

        if (i < 0) {
          this.$store.commit("updateFlightAssignmentBoardPageNumber", 0);
        }

        this.page = this.pages[this.pageNumber];
      } else {
        this.page = {
          index: 0,
          items: [],
        };
      }
    },

    onResize() {
      const board = this.$refs.board;

      if (!board) return;

      this.availableBoardHeight = board.clientHeight - 120;

      this.itemsPerPage = Math.floor(
        this.availableBoardHeight / this.rowHeight
      );

      this.paginate();
    },

    handlePageUp() {
      if (this.pageNumber > 0) {
        const val = this.pageNumber - 1;
        this.$store.commit("updateFlightAssignmentBoardPageNumber", val);
        this.page = this.pages[this.pageNumber];
      }
    },

    handlePageDown() {
      if (this.pageNumber < this.pages.length - 1) {
        const val = this.pageNumber + 1;
        this.$store.commit("updateFlightAssignmentBoardPageNumber", val);
        this.page = this.pages[this.pageNumber];
      }
    },

    handleGoBack() {
      // Access the previous route
      const previousRoute = this.$previousRoute();

      // Handle different types
      switch (previousRoute.name) {
        case "FlightView":
          this.$store.commit("updateFlight", { ...this.flight });
          this.$router.push({ name: "FlightView" });
          break;
        default:
          break;
      }

      this.$router.go(-1);
    },

    handleNavigateToHome() {
      this.$router.go({ name: "home" });
    },

    handleShowFlightTooltip(assignment, index) {
      this.tooltip = { ...assignment };

      this.tooltipStyle = {
        display: "block",
        top: `${index * this.rowHeight + 50 + 45}px`,
        left: `${
          this.getLocationLeft(
            assignment.startTime,
            this.dateFilter.days[0],
            this.scale
          ) + 30
        }px`,
      };
    },

    handleHideFlightTooltip() {
      setTimeout(() => {
        this.tooltipStyle = {
          display: "none",
        };
      }, 150);
    },

    handleNavigateToEmployeeProfile() {
      this.$router.push({
        name: "employeeUpdate",
        params: { id: this.employee._id },
      });
    },

    zoomIn() {
      if (this.scale > 1) {
        this.scale -= 1;
      }
    },

    zoomOut() {
      if (this.scale < 8) {
        this.scale += 1;
      }
    },

    handleToggleBoardStyle() {
      if (this.boardStyle === "Board") {
        this.boardStyle = "List";
      } else {
        this.boardStyle = "Board";
      }
    },

    //-------------------------------------Filters and Settings
    handleOpenDateFilter(item) {
      this.startDate = item.substring(0, 10);
      this.numberOfDays = this.dateFilter.days.length;

      this.showDateFilterDialog = true;
    },

    handleSaveDateFilter() {
      const filterStart = new Date(this.startDate).getTime();

      this.showDateFilterDialog = false;

      //Pass filterStart in miliseconds, numberOfDays as integer
      this.$store.commit("updateDateFilter", {
        filterStart,
        numberOfDays: this.numberOfDays,
      });

      this.getData();
    },

    //--------------------------Employee Information
    handleShowEmployeeInformationDialog(employee) {
      if (this.canReadEmployeeContactInformation) {
        this.employee = { ...employee };

        this.showEmployeeInformationDialog = true;
      }
    },

    handleShowFlightAssignmentDialog(item) {
      this.employee = { ...item };

      this.showFlightAssignmentDialog = true;
    },

    handleCloseFlightAssignmentDialog() {
      this.showFlightAssignmentDialog = false;
      this.employee = null;
      this.getData();
    },

    //------------------------Formatters and Stylers

    formatHeaderDate(string) {
      const date = new Date(string);

      let options = {
        timeZone: "UTC",
        year: "numeric",
        month: "short",
        day: "numeric",
        weekday: "long",
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    },

    getBidDayStyle(bidDay) {
      //Default color "DO" - Day Off
      let backgroundColor = "#E74C3C";

      switch (bidDay.code) {
        case "RES":
          backgroundColor = "#2ECC71";
          break;
        case "VAC":
          backgroundColor = "#A569BD";
          break;
        default:
          break;
      }

      const x =
        (new Date(Date.UTC(bidDay.year, bidDay.month, bidDay.day)).getTime() -
          new Date(this.dateFilter.days[0]).getTime()) /
        1000 /
        60 /
        this.scale;

      const style = {
        top: 0,
        left: `${x}px`,
        height: `${this.rowHeight}px`,
        width: `${this.dayWidth}px`,
        backgroundColor,
      };

      return style;
    },
  },
};
</script>

<style scoped>
.board {
  position: absolute;
  top: 50px;
  left: 250px;
  box-sizing: border-box;
  height: calc(100% - 50px);
  width: calc(100% - 400px);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.flight-panel-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 10px;
  row-gap: 5px;
  width: 250px;
  padding-bottom: 20px;
  border-bottom: 1px solid gray;
}

.flight-panel-header {
  grid-column: 1/13;
  grid-row: 1;
  /* width: 100%; */
  height: 50px;
  border-bottom: 1px solid gray;
  background-color: #f3f4f6;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
}

.seat {
  width: 60px;
  height: 70px;
  border: 1px solid gray;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
}

.capt-seat {
  grid-column: 3/7;
  grid-row: 2;
}

.fo-seat {
  grid-column: 7/10;
  grid-row: 2;
}

.acm1-seat {
  grid-column: 5/9;
  grid-row: 3;
}

.faa-seat {
  grid-column: 3/7;
  grid-row: 4;
}

.fab-seat {
  grid-column: 7/10;
  grid-row: 4;
}

.mx-seat {
  grid-column: 3/7;
  grid-row: 5;
}

.gsc-seat {
  grid-column: 7/10;
  grid-row: 5;
}

.dh1-seat {
  grid-column: 3/7;
  grid-row: 6;
}

.dh2-seat {
  grid-column: 7/10;
  grid-row: 6;
}

.dh3-seat {
  grid-column: 3/7;
  grid-row: 7;
}

.dh4-seat {
  grid-column: 7/10;
  grid-row: 7;
}

.dh5-seat {
  grid-column: 3/7;
  grid-row: 8;
}

.dh6-seat {
  grid-column: 7/10;
  grid-row: 8;
}

.acm2-seat {
  grid-column: 3/7;
  grid-row: 9;
}

.acm3-seat {
  grid-column: 7/10;
  grid-row: 9;
}

.acm4-seat {
  grid-column: 3/7;
  grid-row: 10;
}

.acm5-seat {
  grid-column: 7/10;
  grid-row: 10;
}

.acm6-seat {
  grid-column: 3/7;
  grid-row: 11;
}

.acm7-seat {
  grid-column: 7/10;
  grid-row: 11;
}

.fac-seat {
  grid-column: 3/7;
  grid-row: 12;
}

.fad-seat {
  grid-column: 3/7;
  grid-row: 13;
}

.fae-seat {
  grid-column: 7/10;
  grid-row: 13;
}

.faf-seat {
  grid-column: 7/10;
  grid-row: 12;
}

.seat i {
  font-size: 1.8rem;
  color: #3b82f6;
}

.assignment-shadow {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: "Abel", Arial, Helvetica, sans-serif;
  font-size: 1.3rem;
  padding: auto;
  z-index: 200;
}

.flight-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  min-width: 250px;
  padding: 5px;
  border-radius: 3px;
  background-color: rgba(128, 128, 128, 0.9);
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  z-index: 700;
}

.right-panel-row {
  position: absolute;
  top: 50px;
  left: 0;
  height: 0;
  width: 0;
  border-bottom: 1px solid gray;
  background-color: white;
  overflow: hidden;
}

.right-panel-row:nth-child(odd) {
  background-color: rgb(236, 225, 225);
}

section {
  padding: 0 1rem;
  min-height: 10rem;
  max-height: 26rem;
  overflow-y: auto;
}

.warning-tile {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 5px 10px;
  color: black;
  margin-bottom: 3px;
}

.fa-user:hover {
  color: #3b82f6;
}

.fa-exclamation-triangle {
  color: var(--red);
  font-size: 2rem;
}
</style>
