<template>
  <div
    v-if="employee"
    class="border-l-8 border-2 border-blue-500 p-5 text-base"
  >
    <div class="flex justify-between text-xl">
      <div class="flex">
        <div class="font-bold">
          {{ `${employee.surname}, ${employee.givenName}` }}
        </div>
      </div>

      <button @click="close" class="btn-icon ml-3">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>

    <div>
      <span>Company:</span
      ><span class="text-base font-bold ml-2 text-blue-600">{{
        employee.companyName
      }}</span>
    </div>
    <div>
      <span>Employee ID:</span
      ><span class="text-base font-bold ml-2 text-blue-600">{{
        employee.companyId.number
      }}</span>
    </div>
    <div>
      <span>Gateway:</span
      ><span class="text-base font-bold ml-2 text-blue-600">{{
        employee.gatewayCode
      }}</span>
    </div>
    <div>
      <span>Job Title:</span
      ><span class="font-bold ml-2 text-blue-600">{{ employee.jobTitle }}</span>
    </div>
    <div>
      <span>Email:</span
      ><span class="font-bold ml-2 text-blue-600">{{ employee.mail }}</span>
    </div>
    <div>
      <span>Secondary Email:</span
      ><span class="font-bold ml-2 text-blue-600">{{
        employee.secondaryMail ? employee.secondaryMail : "Not provided"
      }}</span>
    </div>
    <div>
      <span>Phone:</span
      ><span class="font-bold ml-2 text-blue-600">{{
        employee.mobilePhone
      }}</span>
    </div>
    <div class="flex justify-end">
      <button class="btn bg-blue-500 text-white" @click="handleButtonClick">
        Employee Profile
      </button>
    </div>
  </div>
</template>

<script>
import { mixin } from "../mixins/mixin";
export default {
  name: "EmployeeInformation",
  mixins: [mixin],
  data() {
    return {
      dialog: true,
    };
  },
  props: ["employee"],
  methods: {
    close() {
      this.$emit("close");
    },
    handleButtonClick() {
      this.$emit("employeeProfileButtonClick");
    },
  },
};
</script>
