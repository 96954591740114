<template>
  <!-- #region NAVBAR -->
  <nav>
    <ul class="nav-wrapper">
      <li class="icon" @click="handleGoBack">
        <a href="#">
          <span class="tooltip">Go Back</span>
          <span><i class="fa-solid fa-arrow-left text-white ml-5"> </i></span>
        </a>
      </li>

      <li class="icon">
        <RouterLink to="/">
          <span class="tooltip">Home</span>
          <span><i class="fa-solid fa-house text-white ml-3"> </i></span
        ></RouterLink>
      </li>

      <li>
        <a href="#"> </a>
      </li>
    </ul>
  </nav>
  <!-- #endregion -->

  <!-- SNACKBAR -->
  <r-snackbar
    v-if="snackbarVisible"
    :backgroundColor="snackbarColor"
    :message="snackbarText"
    :timeout="3000"
    @close="snackbarVisible = false"
  >
  </r-snackbar>

  <div class="h-screen-minus-toolbar overflow-auto p-5">
    <r-spinner v-if="loading"> </r-spinner>

    <!-- TRAINING RECORD -->
    <r-modal v-if="showTrainingRecordDialog">
      <div v-if="showTrainingRecordDialog" class="p-5">
        <div class="flex justify-between mb-2">
          <div class="text-2xl font-bold">TrainingRecord</div>
          <button @click="showTrainingRecordDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="flex gap-2 flex-wrap">
          <div class="mb-2">
            <p class="pl-3 text-sm text-gray-500">Trainig Type</p>
            <select
              class="block w-48 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="trainingType"
            >
              <option
                v-for="(option, index) in trainingTypeOptions"
                :key="index"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>

          <div class="mb-2">
            <p class="pl-3 text-sm text-gray-500">Trainig Event</p>
            <select
              class="block w-full py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="trainingName"
            >
              <option
                v-for="(option, index) in trainingNameOptions"
                :key="index"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
        </div>

        <div class="flex gap-2 flwex-wrap">
          <div>
            <div class="flex flex-wrap">
              <div>
                <p class="ml-2 text-sm text-gray-500">Start Date</p>
                <input
                  class="block rounded-md border-0 py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm"
                  v-model="startDateString"
                  type="date"
                />
              </div>
              <div>
                <p class="ml-2 text-sm text-gray-500">Start Time</p>
                <input
                  class="ml-1 block rounded-md border-0 py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm"
                  v-model="startTimeString"
                  type="time"
                />
              </div>
            </div>
          </div>

          <div>
            <div class="flex flex-wrap">
              <div>
                <p class="ml-2 text-sm text-gray-500">End Date</p>
                <input
                  class="block rounded-md border-0 py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm"
                  v-model="endDateString"
                  type="date"
                />
              </div>
              <div>
                <p class="ml-2 text-sm text-gray-500">End Time</p>
                <input
                  class="ml-1 block rounded-md border-0 py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm"
                  v-model="endTimeString"
                  type="time"
                />
              </div>
            </div>
          </div>

          <div v-if="!isPermanent" class="flex flex-wrap ml-10">
            <div>
              <p class="ml-2 text-sm text-gray-500">Valid Until (end of day)</p>
              <input
                class="block rounded-md border-0 py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm"
                v-model="validUntilDateString"
                type="date"
              />
            </div>
          </div>
        </div>

        <div class="mt1">
          <p class="ml-2 text-sm text-gray-500">Remarks</p>
          <div>
            <textarea
              v-model="remarks"
              rows="2"
              maxlength="400"
              siz
              name="remarks"
              id="remarks"
              resize="none"
              class="block w-full rounded-md border-0 py-0.5 px-1.5 text-base text-blue-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:leading-6"
            />
          </div>
        </div>

        <div class="flex justify-end p-2">
          <button
            @click="showTrainingRecordDialog = false"
            class="btn bg-gray-500 text-white"
          >
            Cancel
          </button>
          <button
            @click="handleAddTrainingRecord"
            class="ml-1 btn bg-blue-500 text-white"
            :class="{ disabled: !isValidTrainingRecord }"
          >
            Add Record
          </button>
        </div>
      </div>
    </r-modal>

    <div v-if="!loading">
      <div class="flex justify-between">
        <div>
          <div class="text-2xl text-blue-700">
            {{ `${surname}, ${givenName}` }}
          </div>
          <div class="text-xl text-blue-700">
            {{ `Position: ${jobTitle}` }}
          </div>
          <div class="text-xl text-blue-700">
            {{ `Emp. No ${companyId.number}` }}
          </div>
        </div>
        <figure class="profile-pic-figure">
          <img
            :src="imageUrl"
            alt="Profile Image"
            title="Profile Image"
            width="150"
            height="150"
          />
        </figure>
      </div>

      <div class="w-full px-1 py-1.5 mt-3 mb-1">
        <button
          class="btn-small bg-blue-500 text-white text-lg"
          @click="handleInitTrainingRecord"
        >
          New Training Record
        </button>
      </div>

      <div v-if="trainingRecords.length" class="border border-gray-600">
        <!-- Data Table -->

        <div class="overflow-x-auto">
          <table
            class="table-auto w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
          >
            <thead
              class="text-xs text-gray-200 bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
            >
              <tr>
                <th scope="col" class="px-6 w-1/3 py-2">Training Name</th>
                <th scope="col" class="text-center py-2">Start</th>
                <th scope="col" class="text-center py-2">End</th>
                <th scope="col" class="text-center py-2">Valid Until</th>
                <th scope="col" class="w-[200px] py-2 text-center">Status</th>
                <th scope="col" class="w-[50px] py-2 text-center">Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in trainingRecords"
                :key="item._id"
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                :style="getRowStyle(item)"
              >
                <td
                  scope="row"
                  class="px-6 py-1.5 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {{ item.name }}
                </td>

                <td class="text-center">
                  {{ formatDateTime(item._start, "UTC") }}
                </td>
                <td class="text-center">
                  {{
                    item._end ? formatDateTime(item._end, "UTC") : "In progress"
                  }}
                </td>
                <td class="text-center">
                  {{
                    !item.isPermanent
                      ? formatDate(item.validUntil, "UTC")
                      : "Permanent"
                  }}
                </td>

                <td class="text-center">{{ item.status }}</td>
                <td class="text-center">
                  <i
                    @click.stop="handleRemoveTrainingRecord(item._id)"
                    class="fa-solid fa-trash-can cursor-pointer"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        v-else
        class="text-gray-500 border border-gray-400 rounded-md text-base p-2"
      >
        No training records.
      </div>

      <div class="flex justify-end mt-2">
        <div class="flex justify-between">
          <button @click="goBack" class="btn bg-gray-500 text-white">
            Cancel
          </button>

          <button
            @click="handleSaveTrainingRecords"
            class="ml-1 btn bg-green-500 text-white"
          >
            Save Training Records
          </button>
        </div>
      </div>

      <div class="flex justify-between text-sm text-gray-300 p-2">
        <div>{{ `ID: ${_id}` }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import { mixin } from "../mixins/mixin";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";
import RModal from "../components/RModal.vue";
import { Switch, SwitchGroup } from "@headlessui/vue";

export default {
  name: "EmployeeTrainingRecords",
  components: { RSnackbar, RSpinner, RModal, Switch, SwitchGroup },
  mixins: [mixin],
  data() {
    return {
      //-------------------------Employee
      _id: "",
      displayName: "",
      givenName: "",
      surname: "",
      jobTitle: "",
      imageKey: "",
      companyId: {
        number: "",
        expirationDate: new Date().toISOString().substring(0, 10),
        image: "employee-id-default.png",
      },

      trainingRecords: [],

      //-------------------------Training Record

      trainingName: "",
      trainingType: "",
      startDateString: new Date().toISOString().substring(0, 10),
      startTimeString: new Date().toISOString().substring(11, 16),
      endDateString: new Date().toISOString().substring(0, 10),
      endTimeString: new Date().toISOString().substring(11, 16),
      validUntilDateString: new Date().toISOString().substring(0, 10),

      remarks: "",

      //-------------------------Values use to calculate validity
      _start: 0,
      _end: 0,
      _validUntil: 0,

      //-------------------------Form Options
      trainingTypeOptions: [
        "Initial",
        "Recurrent",
        "Upgrade",
        "Transition",
        "Other",
      ],

      trainingNameOptions: [
        "Consolidation of Knowledge and Skills",
        "Emergency Drills",
        "Ground School",
        "Initial Operating Experience",
        "Line Check",
        "Line Training",
        "Proficiency Check",
        "Proficiency Training",
        "Route Check",
        "Route Qualification",
        "Other",
      ],

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 3000,

      //--------------------------Data
      loading: false,

      //--------------------------Modals

      showTrainingRecordDialog: false,

      baseImageUrl:
        "https://dev-flighttimes-employee-profile-images.s3.amazonaws.com/",
    };
  },

  computed: {
    employee() {
      return this.$store.getters.employee;
    },

    auth() {
      return this.$store.state.auth;
    },

    imageUrl() {
      return `${this.baseBucketUrl}${this.imageKey}`;
    },

    isValidStart() {
      this._start = new Date(
        `${this.startDateString}T${this.startTimeString}:00.000+00:00`
      );

      if (!this.isValidDate(this._start)) {
        return false;
      }

      return true;
    },

    isValidEnd() {
      if (!this.isValidStart) return false;

      this._end = new Date(
        `${this.endDateString}T${this.endTimeString}:00.000+00:00`
      );

      if (!this.isValidDate(this._end)) {
        return false;
      }

      const diff = this._end.getTime() - this._start.getTime();

      //Minimum duration is 1 hour
      if (diff < 60 * 60 * 1000) {
        return false;
      }

      return true;
    },

    isValidValidUntil() {
      if (this.isPermanent) return true;

      this._validUntil = new Date(
        `${this.validUntilDateString}T23:59:59.999+00:00`
      );

      if (!this.isValidDate(this._validUntil)) {
        return false;
      }

      const diff = this._validUntil.getTime() - this._end.getTime();

      //Minimum duration is 1 day
      if (diff < 24 * 60 * 60 * 1000) {
        return false;
      }

      return true;
    },

    isValidTrainingRecord() {
      return (
        this.isValidStart &&
        this.isValidEnd &&
        this.isValidValidUntil &&
        this.trainingName &&
        this.trainingType
      );
    },

    isPermanent() {
      let isPermanent = false;

      switch (this.trainingName) {
        case "Consolidation of Knowledge and Skills":
          isPermanent = true;
          break;

        case "Emergency Drills":
          break;

        case "Ground School":
          break;

        case "Initial Operating Experience":
          isPermanent = true;
          break;

        case "Line Check":
          break;

        case "Line Training":
          break;

        case "Proficiency Check":
          break;

        case "Proficiency Training":
          break;

        case "Route Check":
          break;

        case "Route Qualification":
          break;

        case "Other":
          break;

        default:
          break;
      }

      return isPermanent;
    },
  },

  created() {
    this.$nextTick(() => {
      this.loading = true;

      const updateRecordStatus = function (trainingRecords) {
        const now = new Date().getTime();
        const in30Days = new Date().getTime() + 30 * 24 * 60 * 60 * 1000;

        trainingRecords.forEach((record) => {
          if (!record.isPermanent) {
            if (new Date(record.validUntil).getTime() < now) {
              record.status = "Expired";
            } else if (new Date(record.validUntil).getTime() < in30Days) {
              record.status = "Expiring in less than 30 days";
            } else {
              record.status = "OK";
            }
          } else {
            record.status = "Permanent";
          }
        });

        return trainingRecords;
      };

      this._id = this.employee._id;
      this.displayName = this.employee.displayName;
      this.givenName = this.employee.givenName;
      this.surname = this.employee.surname;
      this.jobTitle = this.employee.jobTitle;
      this.companyId = {
        number: this.employee.companyId.number,
        expirationDate: new Date(this.employee.companyId.expirationDate)
          .toISOString()
          .substring(0, 10),
        image: this.employee.companyId.image,
      };

      this.imageKey = this.employee.imageKey;

      this.trainingRecords = updateRecordStatus(this.employee.trainingRecords);
      this.trainingRecords.sort((a, b) => {
        return new Date(b._start).getTime() - new Date(a._start).getTime();
      });

      this.loading = false;
    });
  },

  methods: {
    handleGoBack() {
      this.$router.go(-1);
    },

    getRowStyle(item) {
      if (item.status === "Expired") {
        return "background-color: #FFC7CE";
      } else if (item.status === "Expiring in less than 30 days") {
        return "background-color: #FFEB9C";
      } else {
        return "background-color: #A7F3D0";
      }
    },

    sortCertificates() {
      //TODO: Implement sorting
    },

    handleInitTrainingRecord() {
      const _start = new Date().toISOString();
      const _end = new Date(
        new Date().getTime() + 4 * 60 * 60 * 1000
      ).toISOString(); //Add 4 hours
      const validUntil = new Date(
        new Date().getTime() + 30 * 24 * 60 * 60 * 1000
      ).toISOString(); //Add 30 days

      this.trainingType = "Recurrent";
      this.trainingName = "Proficiency Check";
      this.startDateString = _start.substring(0, 10);
      this.startTimeString = _start.substring(11, 16);
      this.endDateString = _end.substring(0, 10);
      this.endTimeString = _end.substring(11, 16);
      this.validUntilDateString = validUntil.substring(0, 10);

      this.remarks = "";
      this.showTrainingRecordDialog = true;
    },

    handleAddTrainingRecord() {
      if (!this.isValidTrainingRecord) return;

      let trainingRecord = {
        _id: new Date().getTime(),
        name: this.trainingName,
        type: this.trainingType,
        _start: this._start,
        _end: this._end,
        remarks: this.remarks,
        status: "OK",
        isPermanent: this.isPermanent,
      };

      if (!this.isPermanent) {
        trainingRecord.validUntil = this._validUntil;
      }

      const index = this.trainingRecords.findIndex(
        (record) => record.name === this.trainingName
      );

      if (index !== -1) {
        this.trainingRecords.splice(index, 1);
      } else {
        this.trainingRecords.push(trainingRecord);
      }

      this.trainingRecords.sort((a, b) => {
        return b._start - a._start;
      });

      this.showTrainingRecordDialog = false;
    },

    handleRemoveTrainingRecord(id) {
      let index = this.trainingRecords.findIndex((record) => record._id === id);

      if (index === -1) return;

      //TODO: add confirmation dialog

      this.trainingRecords.splice(index, 1);
    },

    async handleSaveTrainingRecords() {
      //TODO: Implement saving training records

      this.loading = true;

      try {
        const token = this.auth.token;

        const res = await api.put(
          `/employees/training-records/${this._id}`,
          { trainingRecords: this.trainingRecords },
          {
            headers: { Authorization: "Bearer " + token },
          }
        );

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        const employee = res.data.employee;

        this.$store.commit("updateEmployee", employee);
        this.$router.go(-1);
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.is-expired {
  background-color: tomato;
  color: white;
}

.profile-pic-figure {
  border: 1px solid gray;
  width: 150px;
  cursor: none;
}

.profile-pic-figure img {
  width: 100%;
  height: auto;
  min-width: 100px;
  max-width: 150px;
  border: 1px solid var(--gray);
}

.container p {
  margin: 0;
  padding: 0;
  font-size: 0.8rem;
  color: var(--gray);
}

.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
</style>
