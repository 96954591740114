<template>
  <div>
    <nav>
      <ul class="nav-wrapper">
        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white ml-3"> </i></span
          ></RouterLink>
        </li>

        <li>
          <a href="#">
            <div class="mx-3 text-base lg:text-2xl text-white">Airports</div>
          </a>
        </li>

        <li>
          <a href="#">
            <input
              @click="[(search = ''), handleFilterAirports()]"
              class="mx-3 text-align-center w-24 h-8 rounded-md border-gray-500"
              v-model="search"
              @input="handleFilterAirports()"
              type="text"
              maxlength="6"
              placeholder="Name"
            />
          </a>
        </li>

        <li @click="getData" class="icon">
          <a href="#">
            <span class="tooltip">Refresh</span>
            <span><i class="fa-solid fa-rotate ml-2"></i></span>
          </a>
        </li>
      </ul>
    </nav>

    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <!-- LOADING -->

    <r-spinner v-if="loading"> </r-spinner>

    <!-- AIRPORT INFORMATION DIALOG -->
    <r-modal
      v-if="showAirportInformationDialog"
      @close="handleHideAirportInformationDialog"
    >
      <AirportInformation
        v-if="airport"
        :airport="airport"
        @close="handleHideAirportInformationDialog"
      />
    </r-modal>

    <div v-else>
      <!-- Data Table -->
      <div class="h-screen-minus-toolbar overflow-auto">
        <table class="table-auto border-collapse border border-gray-300 w-full">
          <thead class="text-xs text-gray-200 bg-gray-500 sticky top-0">
            <tr>
              <th
                class="border border-gray-300 px-4 py-1.5 w-[150px] cursor-pointer"
                @click="sortArrayByKey('name')"
              >
                Airports Name
              </th>
              <th
                class="border border-gray-300 px-4 py-1.5 w-[150px] cursor-pointer"
                @click="sortArrayByKey('city')"
              >
                City
              </th>
              <th
                class="border border-gray-300 px-4 py-1.5 cursor-pointer"
                @click="sortArrayByKey('country')"
              >
                Country
              </th>
              <th
                class="border border-gray-300 px-4 py-1.5 cursor-pointer"
                @click="sortArrayByKey('iata')"
              >
                IATA
              </th>
              <th
                class="border border-gray-300 px-4 py-1.5 cursor-pointer"
                @click="sortArrayByKey('icao')"
              >
                ICAO
              </th>
              <th class="border border-gray-300 px-4 py-1.5">Time Zone</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="airport in airports"
              :key="airport._id"
              @click="handleSelectAirport(airport)"
              class="bg-white border-b hover:bg-gray-50 cursor-pointer"
            >
              <td class="border border-gray-300 px-4 py1.5">
                {{ airport.name }}
              </td>
              <td class="border border-gray-300 px-4 py1.5">
                {{ airport.city }}
              </td>
              <td class="border border-gray-300 px-4 py1.5">
                {{ airport.country }}
              </td>
              <td class="border border-gray-300 px-4 py1.5">
                {{ airport.iata }}
              </td>
              <td class="border border-gray-300 px-4 py1.5">
                {{ airport.icao }}
              </td>
              <td class="border border-gray-300 px-4 py1.5">
                {{ airport.timeZone }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin } from "../mixins/mixin";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";
import RModal from "../components/RModal.vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";
import AirportInformation from "../components/AirportInformation.vue";

export default {
  name: "Airports",
  mixins: [mixin],
  components: {
    AirportInformation,
    RSnackbar,
    RSpinner,
    RModal,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronUpDownIcon,
  },

  data() {
    return {
      loading: false,

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,

      //-------------------------Dialog
      showAirportInformationDialog: false,

      //-------------------------Data
      airport: null,
      allAirports: [],
      airports: [],
      search: "",
    };
  },

  created() {
    this.getData();
  },

  computed: {
    auth() {
      return this.$store.state.auth;
    },
  },

  methods: {
    async getData() {
      if (!this.auth) {
        this.$router.push({ name: "Home" });
        return;
      }

      this.loading = true;

      try {
        const res = await this.$store.dispatch("getAirports");

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.allAirports = res.data.airports;
        this.airports = [...this.allAirports];
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    handleFilterAirports() {
      this.airports = this.allAirports.filter((airport) => {
        return (
          airport.iata.toUpperCase().startsWith(this.search.toUpperCase()) ||
          airport.icao.toUpperCase().startsWith(this.search.toUpperCase()) ||
          airport.name.toUpperCase().startsWith(this.search.toUpperCase())
        );
      });
    },

    handleSelectAirport(airport) {
      this.airport = { ...airport };
      this.showAirportInformationDialog = true;
    },

    handleHideAirportInformationDialog() {
      this.airport = null;
      this.showAirportInformationDialog = false;
    },

    isSortedAsc(arr, key) {
      for (let i = 0; i < arr.length - 1; i++) {
        if (arr[i][key] > arr[i + 1][key]) {
          return false;
        }
      }
      return true;
    },

    sortArrayByKey(key) {
      const sortedAsc = this.isSortedAsc(this.airports, key);

      if (sortedAsc) {
        this.airports.sort((a, b) => (a[key] < b[key] ? 1 : -1));
      } else {
        this.airports.sort((a, b) => (a[key] > b[key] ? 1 : -1));
      }
    },

    handleGoBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.fa-chevron-left {
  font-size: 1.3rem;
  color: white;
}
</style>
