<template>
  <div>
    <!-- #region NAVBAR -->
    <nav>
      <ul class="nav-wrapper">
        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white ml-3"> </i></span
          ></RouterLink>
        </li>
        <li>
          <a href="#">
            <div class="mx-3 text-base lg:text-2xl text-white">
              Flight Board
            </div>
          </a>
        </li>
        <li>
          <a href="#">
            <div
              v-if="boardStyle === 'Board' && pages.length"
              class="flex justify-between fnt-sm"
            >
              <button class="btn-icon" @click="handlePageUp">
                <i class="fa-solid fa-chevrons-up text-white"></i>
              </button>
              <div class="text-white">
                {{ `${pageNumber + 1} of ${pages.length}` }}
              </div>
              <button class="btn-icon" @click="handlePageDown">
                <i class="fa-solid fa-chevrons-down text-white"></i>
              </button>
            </div>
          </a>
        </li>

        <li
          v-if="boardStyle !== 'List'"
          class="icon"
          @click="handleCaptureFlightBoard"
        >
          <a href="#">
            <span class="tooltip">Print Board</span>
            <span><i class="fa-solid fa-print text-white"></i></span>
          </a>
        </li>

        <li @click="zoomIn" class="icon" v-if="boardStyle !== 'List'">
          <a href="#">
            <span class="tooltip">Zoom In</span>
            <span><i class="fa-regular fa-magnifying-glass-plus"></i></span>
          </a>
        </li>
        <li @click="zoomOut" class="icon" v-if="boardStyle !== 'List'">
          <a href="#">
            <span class="tooltip">Zoom Out</span>
            <span><i class="fa-regular fa-magnifying-glass-minus"></i></span>
          </a>
        </li>
        <li @click="getData" class="icon">
          <a href="#">
            <span class="tooltip">Refresh</span>
            <span><i class="fa-solid fa-rotate"></i></span>
          </a>
        </li>

        <li @click="handleToggleBoardStyle" class="icon">
          <a href="#">
            <span class="tooltip">Change Board Style</span>
            <span>
              <i class="fa-solid fa-bolt text-yellow-500"></i>
            </span>
          </a>
        </li>

        <li @click="handleToggleTimeZone" class="icon">
          <a href="#">
            <span class="tooltip">Change Time Zone</span>
            <span>
              <i
                :class="[
                  'fa-solid fa-clock',
                  localTime ? 'text-red-500' : 'text-white',
                ]"
              ></i>
            </span>
          </a>
        </li>

        <li>
          <a href="#">
            <span class="text-sm text-white px-2">
              {{ `Total Flights: ${totalEvents}` }}
            </span>
          </a>
        </li>
      </ul>
    </nav>
    <!-- #endregion -->

    <!-- #region WARNINGS -->

    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <!-- #region LOADING -->

    <r-spinner v-if="loading"> </r-spinner>
    <!-- #endregion -->

    <!-- #region DIALOGS -->

    <!-- DATE FILTER DIALOG -->
    <r-modal v-if="showDateFilterDialog" @close="showDateFilterDialog = false">
      <div v-if="showDateFilterDialog" class="p-5">
        <div class="flex justify-between mb-2">
          <div class="text-2xl font-bold">Date Filter</div>
          <button @click="showDateFilterDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="flex">
          <div>
            <label
              for="date-filter"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Start Date</label
            >

            <input
              class="block w-32 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="startDate"
              type="date"
              id="date-filter"
            />
          </div>

          <div class="ml-3">
            <label
              for="date-filter"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Number of Days</label
            >

            <select
              class="block w-24 py-1 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="numberOfDays"
            >
              <option
                v-for="option in numberOfDaysOptions"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
        </div>

        <div class="flex justify-end mt-1">
          <button
            @click="handleSaveDateFilter"
            class="btn bg-blue-500 text-white"
          >
            Apply Filter
          </button>
        </div>
      </div>
    </r-modal>

    <!-- AIRPORT INFORMATION DIALOG -->
    <r-modal
      v-if="showAirportInformationDialog"
      @close="handleHideAirportInformationDialog"
    >
      <AirportInformation
        v-if="airport"
        :airport="airport"
        @close="handleHideAirportInformationDialog"
      />
    </r-modal>

    <!-- AIRCRAFT INFORMATION DIALOG -->
    <r-modal v-if="showAircraftDialog">
      <div
        v-if="myAircraft"
        class="border-l-8 border-2 border-blue-500 text-base p-3"
      >
        <div class="flex justify-between mb-5 ml-2">
          <div class="text-3xl">
            {{ ` ${myAircraft.registration} - ${myAircraft.model}` }}
          </div>
          <button
            @click="[(showAircraftDialog = false), (myAircraft = null)]"
            class="btn-icon"
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="py-2">
          <div class="text-lg font-bold">Cabin Configuration</div>
          <div v-for="(item, index) in myAircraft.cabinConfig" :key="index">
            {{ `Class "${item.class}": ${item.seats} Seat(s)` }}
          </div>
        </div>
      </div>
    </r-modal>

    <!-- #endregion -->

    <!-- #region FLIGHT DETAIL -->
    <r-modal v-if="showFlightDialog" @close="handleHideFlightDialog">
      <div v-if="flight" class="pl-3 pt-3 pr-3 pb-1 bg-green-100">
        <div class="flex justify-between text-lg lg:text-xl cursor-pointer">
          <div
            @click="handleNavigateToFlightView"
            class="btn-text text-2xl font-bold cursor-pointer"
          >
            <span>
              {{ `${flight.aircraft.registration} | ` }}
            </span>
            <span>{{ `${flight.flightNumber} | ` }}</span>

            <span>{{ `${flight.customer.name}` }}</span>
            <span>
              {{ ` | ${flight.flightRules}` }}
            </span>

            <span class="ml-2 text-red-500">
              {{ `${flight.hidden ? "(Hidden)" : ""}` }}
            </span>
          </div>

          <button @click="handleHideFlightDialog" class="btn-icon">
            <i class="fa-solid fa-close"></i>
          </button>
        </div>

        <!-- ORIGIN DESTINATION -->

        <div class="flex justify-between border-b border-gray-500 pb-2 mb-2">
          <div @click="handleGetPreviousFlight">
            <i class="fa-solid fa-left"></i>
          </div>

          <!-- Origin -->
          <div class="mx-auto">
            <div
              @click="handleShowAirportInformationDialog(flight.origin)"
              class="text-center text-3xl font-bold cursor-pointer"
            >
              {{ flight.origin.iata }}
            </div>

            <div class="text-center">
              {{ formatDate(flight._start, flight.origin.timeZone) }}
            </div>
            <div class="text-center text-xl lg:text-3xl font-bold grey--text">
              {{ `${formatTime(flight._start, flight.origin.timeZone)}` }}
            </div>
          </div>

          <!-- Center -->
          <div>
            <div
              class="mx-auto my-auto cursor-pointer"
              @click="handleNavigateToFlightView"
            >
              <div
                v-if="flight.unscheduledEvent !== 'None'"
                class="text-center text-2xl text-red-500"
              >
                {{ flight.unscheduledEvent }}
              </div>
              <div v-else class="text-center text-2xl">
                {{ flight.status }}
              </div>
              <!-- <div class="text-center text-xl font-bold">Block Time</div> -->
              <div
                class="text-center text-xl lg:text-3xl text-blue-800 font-bold grey--text"
              >
                {{ `${flight.duration.text} ` }}
              </div>
            </div>
          </div>

          <!-- Destination -->
          <div class="mx-auto">
            <div
              @click="handleShowAirportInformationDialog(flight.destination)"
              class="text-center text-3xl font-bold cursor-pointer"
            >
              {{ flight.destination.iata }}
            </div>

            <div class="text-center">
              {{ formatDate(flight._end, flight.destination.timeZone) }}
            </div>
            <div class="text-center text-xl lg:text-3xl font-bold grey--text">
              {{ `${formatTime(flight._end, flight.destination.timeZone)}` }}
            </div>
          </div>

          <div @click="handleGetNextFlight">
            <i class="fa-solid fa-right"></i>
          </div>
        </div>

        <!-- #region CREWMEMBERS -->

        <div class="mb-1">
          <div class="flex items-center">
            <!-- TODO: disable based on authorizations -->
            <div
              v-if="!canEditCrew"
              class="btn-text text-2xl font-bold cursor-pointer"
              :class="{ disabled: flight.status !== 'SCHEDULED' }"
              @click="handleBeginCrewEdit"
            >
              Crew
            </div>
            <div v-else>
              <div
                class="flex justify-between items-center border border-gray-500 rounded-md p-1 mb-1"
              >
                <div>
                  <select
                    class="py-1.5 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
                    v-model="selectedCrewAction"
                  >
                    <option
                      v-for="(item, index) in crewActionOptions"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                </div>
                <button @click="handleCloseCrewEdit" class="btn-icon">
                  <i class="fa-solid fa-close"></i>
                </button>
              </div>
            </div>
          </div>

          <!-- CREWMEMBERS -->

          <div
            class="crewmembers-container"
            :class="{ 'bg-red-500': selectedCrewAction === 'Unassign Crew' }"
          >
            <!-- Captain -->
            <div
              class="seat capt-seat"
              @click="handleSeatAction(captain)"
              :style="getSeatStyle(captain)"
            >
              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
              >
                {{ captain.seat }}
              </div>

              <i :class="getSeatIcon(captain)"> </i>

              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
              >
                {{ getSeatFooterText(captain) }}
              </div>
            </div>

            <!-- First Officer -->
            <div
              class="seat fo-seat"
              @click="handleSeatAction(firstOfficer)"
              :style="getSeatStyle(firstOfficer)"
            >
              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
              >
                {{ firstOfficer.seat }}
              </div>

              <i :class="getSeatIcon(firstOfficer)"> </i>

              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
              >
                {{ getSeatFooterText(firstOfficer) }}
              </div>
            </div>

            <!-- ACM-1 -->
            <div
              class="seat acm1-seat"
              @click="handleSeatAction(acm1)"
              :style="getSeatStyle(acm1)"
            >
              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
              >
                {{ acm1.seat }}
              </div>

              <i :class="getSeatIcon(acm1)"> </i>

              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
              >
                {{ getSeatFooterText(acm1) }}
              </div>
            </div>

            <!-- FA-A -->
            <div
              class="seat faa-seat"
              @click="handleSeatAction(faa)"
              :style="getSeatStyle(faa)"
            >
              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
              >
                {{ faa.seat }}
              </div>

              <i :class="getSeatIcon(faa)"> </i>

              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
              >
                {{ getSeatFooterText(faa) }}
              </div>
            </div>

            <!-- FA-B -->
            <div
              class="seat fab-seat"
              @click="handleSeatAction(fab)"
              :style="getSeatStyle(fab)"
            >
              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
              >
                {{ fab.seat }}
              </div>

              <i :class="getSeatIcon(fab)"> </i>

              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
              >
                {{ getSeatFooterText(fab) }}
              </div>
            </div>

            <!-- MX -->
            <div
              class="seat mx-seat"
              @click="handleSeatAction(mx)"
              :style="getSeatStyle(mx)"
            >
              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
              >
                {{ mx.seat }}
              </div>

              <i :class="getSeatIcon(mx)"> </i>

              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
              >
                {{ getSeatFooterText(mx) }}
              </div>
            </div>

            <!-- GSC -->
            <div
              class="seat gsc-seat"
              @click="handleSeatAction(gsc)"
              :style="getSeatStyle(gsc)"
            >
              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
              >
                {{ gsc.seat }}
              </div>

              <i :class="getSeatIcon(gsc)"> </i>

              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
              >
                {{ getSeatFooterText(gsc) }}
              </div>
            </div>

            <!-- DH-1 -->
            <div
              class="seat dh1-seat"
              @click="handleSeatAction(dh1)"
              :style="getSeatStyle(dh1)"
            >
              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
              >
                {{ dh1.seat }}
              </div>

              <i :class="getSeatIcon(dh1)"> </i>

              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
              >
                {{ getSeatFooterText(dh1) }}
              </div>
            </div>

            <!-- DH-2 -->
            <div
              class="seat dh2-seat"
              @click="handleSeatAction(dh2)"
              :style="getSeatStyle(dh2)"
            >
              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
              >
                {{ dh2.seat }}
              </div>

              <i :class="getSeatIcon(dh2)"> </i>

              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
              >
                {{ getSeatFooterText(dh2) }}
              </div>
            </div>

            <!-- DH-3 -->
            <div
              class="seat dh3-seat"
              @click="handleSeatAction(dh3)"
              :style="getSeatStyle(dh3)"
            >
              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
              >
                {{ dh3.seat }}
              </div>

              <i :class="getSeatIcon(dh3)"> </i>

              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
              >
                {{ getSeatFooterText(dh3) }}
              </div>
            </div>

            <!-- DH-4 -->
            <div
              class="seat dh4-seat"
              @click="handleSeatAction(dh4)"
              :style="getSeatStyle(dh4)"
            >
              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
              >
                {{ dh4.seat }}
              </div>

              <i :class="getSeatIcon(dh4)"> </i>

              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
              >
                {{ getSeatFooterText(dh4) }}
              </div>
            </div>

            <!-- DH-5 -->
            <div
              class="seat dh5-seat"
              @click="handleSeatAction(dh5)"
              :style="getSeatStyle(dh5)"
            >
              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
              >
                {{ dh5.seat }}
              </div>

              <i :class="getSeatIcon(dh5)"> </i>

              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
              >
                {{ getSeatFooterText(dh5) }}
              </div>
            </div>

            <!-- DH-6 -->
            <div
              class="seat dh6-seat"
              @click="handleSeatAction(dh6)"
              :style="getSeatStyle(dh6)"
            >
              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
              >
                {{ dh6.seat }}
              </div>

              <i :class="getSeatIcon(dh6)"> </i>

              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
              >
                {{ getSeatFooterText(dh6) }}
              </div>
            </div>

            <!-- ACM-2 -->
            <div
              class="seat acm2-seat"
              @click="handleSeatAction(acm2)"
              :style="getSeatStyle(acm2)"
            >
              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
              >
                {{ acm2.seat }}
              </div>

              <i :class="getSeatIcon(acm2)"> </i>

              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
              >
                {{ getSeatFooterText(acm2) }}
              </div>
            </div>

            <!-- ACM-3 -->
            <div
              class="seat acm3-seat"
              @click="handleSeatAction(acm3)"
              :style="getSeatStyle(acm3)"
            >
              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
              >
                {{ acm3.seat }}
              </div>

              <i :class="getSeatIcon(acm3)"> </i>

              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
              >
                {{ getSeatFooterText(acm3) }}
              </div>
            </div>

            <!-- ACM-4 -->
            <div
              class="seat acm4-seat"
              @click="handleSeatAction(acm4)"
              :style="getSeatStyle(acm4)"
            >
              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
              >
                {{ acm4.seat }}
              </div>

              <i :class="getSeatIcon(acm4)"> </i>

              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
              >
                {{ getSeatFooterText(acm4) }}
              </div>
            </div>

            <!-- ACM-5 -->
            <div
              class="seat acm5-seat"
              @click="handleSeatAction(acm5)"
              :style="getSeatStyle(acm5)"
            >
              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
              >
                {{ acm5.seat }}
              </div>

              <i :class="getSeatIcon(acm5)"> </i>

              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
              >
                {{ getSeatFooterText(acm5) }}
              </div>
            </div>

            <!-- ACM-6 -->

            <div
              class="seat acm6-seat"
              @click="handleSeatAction(acm6)"
              :style="getSeatStyle(acm6)"
            >
              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
              >
                {{ acm6.seat }}
              </div>

              <i :class="getSeatIcon(acm6)"> </i>

              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
              >
                {{ getSeatFooterText(acm6) }}
              </div>
            </div>

            <!-- ACM-7 -->

            <div
              class="seat acm7-seat"
              @click="handleSeatAction(acm7)"
              :style="getSeatStyle(acm7)"
            >
              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
              >
                {{ acm7.seat }}
              </div>

              <i :class="getSeatIcon(acm7)"> </i>

              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
              >
                {{ getSeatFooterText(acm7) }}
              </div>
            </div>

            <!-- FA-C -->
            <div
              class="seat fac-seat"
              @click="handleSeatAction(fac)"
              :style="getSeatStyle(fac)"
            >
              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
              >
                {{ fac.seat }}
              </div>

              <i :class="getSeatIcon(fac)"> </i>

              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
              >
                {{ getSeatFooterText(fac) }}
              </div>
            </div>

            <!-- FA-D -->
            <div
              class="seat fad-seat"
              @click="handleSeatAction(fad)"
              :style="getSeatStyle(fad)"
            >
              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
              >
                {{ fad.seat }}
              </div>

              <i :class="getSeatIcon(fad)"> </i>

              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
              >
                {{ getSeatFooterText(fad) }}
              </div>
            </div>

            <!-- FA-E -->
            <div
              class="seat fae-seat"
              @click="handleSeatAction(fae)"
              :style="getSeatStyle(fae)"
            >
              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
              >
                {{ fae.seat }}
              </div>

              <i :class="getSeatIcon(fae)"> </i>

              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
              >
                {{ getSeatFooterText(fae) }}
              </div>
            </div>

            <!-- FA-F -->
            <div
              class="seat faf-seat"
              @click="handleSeatAction(faf)"
              :style="getSeatStyle(faf)"
            >
              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
              >
                {{ faf.seat }}
              </div>

              <i :class="getSeatIcon(faf)"> </i>

              <div
                class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
              >
                {{ getSeatFooterText(faf) }}
              </div>
            </div>
          </div>
        </div>
        <!-- #endregion -->
        <div class="ml-2">Remarks</div>
        <div class="border border-gray-500 rounded-md px-2">
          <p
            v-if="flight.remarks"
            class="user-select-text text-sm text-blue-600 mt-2"
          >
            {{ flight.remarks }}
          </p>
          <p v-else></p>
        </div>

        <div class="flex justify-end mt-1">
          <div class="text-[10px] text-gray-500 selectable-text">
            {{ flight._id }}
          </div>
        </div>
      </div>
    </r-modal>
    <!-- #endregion -->

    <!-- #region BOARD -->
    <div
      v-if="boardStyle === 'Board'"
      class="board"
      ref="board"
      :style="{
        top: `${boardY}px`,
      }"
    >
      <!-- LEFT PANEL -->
      <div class="left-panel">
        <div class="left-panel-header bg-gray-500-l4">
          <div
            class="flex justify-center px-2 text-xl font-bold cursor-pointer"
          >
            Aircraft
          </div>
        </div>
        <div v-if="page">
          <div
            @click="handleShowAircraftInformation(item.aircraft)"
            class="left-panel-row bg-gray-500-l4 cursor-pointer"
            v-for="item in page.items"
            :key="item.aircraft.registration"
            :style="{ height: `${rowHeight}px` }"
          >
            <div class="text-lg text-bold">
              {{ item.aircraft.registration }}
            </div>
            <div :style="getAircraftTypeStyle(item.aircraft.model)">
              {{ item.aircraft.model }}
            </div>
          </div>
        </div>
      </div>
      <!-- RIGHT PANEL -->
      <div class="right-panel-container">
        <div v-if="page">
          <div class="right-panel">
            <!-- Event Tooltip -->
            <div
              v-if="hoverEvent"
              class="event-tooltip text-[12px]"
              :style="tooltipStyle"
            >
              <div class="border-b border-gray-500 pb-1 mb-1 text-base">
                {{ hoverEvent.customerName }}
              </div>

              <div>
                <span v-if="hoverEvent.type === 'Flight'">{{
                  `${hoverEvent.flightNumber} | `
                }}</span>
                <span v-else>{{ `${hoverEvent.type} | ` }}</span>
                <span>{{ `${hoverEvent.aircraft.registration} | ` }}</span>
                <span>{{ `${hoverEvent.origin.iata} | ` }}</span>
                <span v-if="hoverEvent.type === 'Flight'">{{
                  `${hoverEvent.destination.iata}`
                }}</span>
              </div>

              <div class="border-b border-gray-500 pb-1 mb-1">
                {{
                  `${formatDateTime(
                    hoverEvent._start,
                    hoverEvent.origin.timeZone
                  )} . ${formatTime(
                    hoverEvent._end,
                    hoverEvent.destination.timeZone
                  )}`
                }}
              </div>

              <div v-if="hoverEvent.type === 'Flight'">
                <div
                  v-for="crewmember in hoverEvent.crewmembers"
                  :key="crewmember._id"
                >
                  <div v-if="crewmember.employee">
                    <span>{{ crewmember.seat }}:</span
                    ><span class="ml-1">
                      {{
                        `${crewmember.employee.surname}, ${crewmember.employee.givenName}`
                      }}
                    </span>
                  </div>
                  <div v-else>
                    <div v-if="crewmember.required">
                      <span>{{ crewmember.seat }}:</span
                      ><span class="ml-1"> Please assign. </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Header -->
            <div
              class="right-panel-header bg-gray-500-l4 cursor-pointer"
              @click="handleOpenDateFilter(item)"
              v-for="(item, index) in dateFilter.days"
              :key="index"
              :style="{
                top: 0,
                left: `${(1440 / scale) * index}px`,
                width: `${dayWidth}px`,
              }"
            >
              {{ formatHeaderDate(item) }}
            </div>

            <!-- Day Lines -->
            <div
              class="day-line"
              v-for="(day, index) in dateFilter.days"
              :key="day"
              :style="{
                left: `${(1440 / scale) * index}px`,
                height: `${availableBoardHeight + 50}px`,
              }"
            ></div>

            <!-- Hour Boxes -->
            <div
              class="hour-box text-[10px]"
              :class="{ hidden: marker.value === '00' || scale > 4 }"
              v-for="(marker, index) in dateFilter.hourBoxes"
              :key="marker.key"
              :style="{ left: `${(60 / scale) * index}px` }"
            >
              {{ marker.value }}
            </div>

            <!-- Hour Lines -->
            <div
              class="hour-line"
              :class="{ hidden: marker.value === '00' || scale > 6 }"
              v-for="(marker, index) in dateFilter.hourLines"
              :key="marker.key"
              :style="{
                left: `${(60 / scale) * index}px`,
                height: `${availableBoardHeight + 7}px`,
              }"
            ></div>

            <!-- Now Box -->
            <div
              class="now-box"
              v-if="showCurrentTime"
              :style="{
                left: `${nowLineLeft}px`,
              }"
            >
              {{ formatTime(new Date(), "UTC").substring(0, 5) }}
            </div>

            <!-- Now Line -->
            <div
              class="now-line"
              v-if="showCurrentTime"
              :style="{
                left: `${nowLineLeft}px`,
                height: `${availableBoardHeight + 7}px`,
              }"
            ></div>

            <!-- Aircraft Event -->
            <div
              class="right-panel-row"
              v-for="(item, index) in page.items"
              :key="item.aircraft.registration"
              :style="getRowStyle(index)"
            >
              <div
                v-for="event in item.events"
                @click="handleShowFlightDialog(event)"
                @mousemove="handleShowEventTooltip(event, index)"
                @mouseleave="handleHideEventTooltip"
                :key="event._id"
                class="board-label-frame"
                :style="{
                  width: `${event.duration.totalMinutes / scale}px`,
                  left: `${getLocationLeft(
                    event._start,
                    dateFilter.days[0],
                    scale
                  )}px`,
                }"
              >
                <FlightTile
                  v-if="event.type === 'Flight'"
                  :flight="event"
                ></FlightTile>
                <!-- TODO Operational Event -->
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="right-panel">
            <div
              class="right-panel-header bg-gray-500-l4 cursor-pointer"
              @click="handleOpenDateFilter(item)"
              v-for="(item, index) in dateFilter.days"
              :key="index"
              :style="{
                top: 0,
                left: `${(1440 / scale) * index}px`,
                width: `${dayWidth}px`,
              }"
            >
              {{ formatHeaderDate(item) }}
            </div>

            <!-- Day Lines -->
            <div
              class="day-line"
              v-for="(day, index) in dateFilter.days"
              :key="day"
              :style="{
                left: `${(1440 / scale) * index}px`,
                height: `${availableBoardHeight + 50}px`,
              }"
            ></div>

            <!-- Hour Boxes -->
            <div
              class="hour-box text-[10px]"
              :class="{ hidden: marker.value === '00' || scale > 4 }"
              v-for="(marker, index) in dateFilter.hourBoxes"
              :key="marker.key"
              :style="{ left: `${(60 / scale) * index}px` }"
            >
              {{ marker.value }}
            </div>

            <!-- Hour Lines -->
            <div
              class="hour-line"
              :class="{ hidden: marker.value === '00' || scale > 6 }"
              v-for="(marker, index) in dateFilter.hourLines"
              :key="marker.key"
              :style="{
                left: `${(60 / scale) * index}px`,
                height: `${availableBoardHeight + 7}px`,
              }"
            ></div>

            <!-- Now Box -->
            <div
              class="now-box"
              v-if="showCurrentTime"
              :style="{
                left: `${nowLineLeft}px`,
              }"
            >
              {{ formatTime(new Date(), "UTC").substring(0, 5) }}
            </div>

            <!-- Now Line -->
            <div
              class="now-line"
              v-if="showCurrentTime"
              :style="{
                left: `${nowLineLeft}px`,
                height: `${availableBoardHeight + 7}px`,
              }"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="board px-2 py-1.5"
      ref="board"
      :style="{
        top: `${boardY}px`,
      }"
    >
      <div class="text-2xl font-bold ml-3">Aircraft Schedule</div>

      <div class="flex gap-2 px-2 py-2 mb-3 border border-gray-300 rounded-md">
        <div class="flex-1">
          <select
            class="block py-1.5 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
            v-model="selectedBoardAction"
          >
            <option
              v-for="(option, index) in board"
              :key="index"
              :value="option"
            >
              {{ option.name }}
            </option>
          </select>
          <div
            class="text-sm text-gray-500 border border-gray-300 rounded-md p-2 my-1"
          >
            {{ selectedBoardAction.description }}
          </div>
        </div>
      </div>

      <div
        v-if="selectedBoardAction?.warning"
        class="p-2 border border-gray-500 bg-red-400 rounded-md"
      >
        <div class="text-2xl font-extrabold text-white">WARNING</div>

        <div class="text-lg text-white">
          {{ selectedBoardAction.warning }}
        </div>
      </div>

      <div v-for="item in aircraftSchedule" :key="item.aircraft.registraion">
        <div
          v-if="item.events.length"
          class="border border-gray-500 rounded-md p-2 my-2"
        >
          <div class="flex justify-between items-center px-2 py-1 mb-2">
            <div class="text-2xl font-bold">
              {{ item.aircraft.registration }}
            </div>
            <button class="btn-icon" @click="handleToggleCardExpanded(item)">
              <i
                :class="{
                  'fa-solid fa-chevron-down': !expandedCards.includes(
                    item.aircraft.registration
                  ),
                  'fa-solid fa-chevron-up': expandedCards.includes(
                    item.aircraft.registration
                  ),
                }"
              ></i>
            </button>
          </div>

          <div
            v-if="expandedCards.includes(item.aircraft.registration)"
            class="p-2"
          >
            <div
              class="event-card bg-blue-300 hover:bg-blue-400 cursor-pointer"
              :class="{ private: event.hidden }"
              v-for="(event, index) in item.events"
              :key="event.tempId"
              @click="handleSelectedAction(event)"
            >
              <div class="flex justify-between items-center">
                <div class="flex gap-2">
                  <div class="text-lg font-bold">
                    {{ `${index + 1} - ` }}
                  </div>

                  <div class="text-lg font-bold">
                    {{ event.flightNumber }}
                  </div>
                  <div class="text-lg font-bold">
                    {{ event.origin.iata }}-{{ event.destination.iata }}
                  </div>
                  <div class="text-lg font-bold">
                    {{ formatDateTime(event._start, event.origin.timeZone) }}
                    -
                    {{ formatTime(event._end, event.destination.timeZone) }}
                  </div>
                  <div class="text-lg font-bold">
                    {{ event.duration.text }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- #endregion -->
  </div>
</template>

<script>
import api from "../services/api";
import { mixin } from "../mixins/mixin";
import FlightTile from "../components/FlightTile.vue";
import domtoimage from "dom-to-image";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";
import RModal from "../components/RModal.vue";
import AirportInformation from "../components/AirportInformation.vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";

export default {
  name: "flightBoard",
  components: {
    AirportInformation,
    FlightTile,
    RSnackbar,
    RSpinner,
    RModal,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronUpDownIcon,
  },
  data() {
    return {
      loading: false,

      //---------------------------------Crew
      canEditCrew: false,
      selectedCrewAction: "Assign Crew",

      //-------------------------------Options
      crewActionOptions: ["Assign Crew", "Unassign Crew", "Toogle Required"],
      selectedBoardAction: {
        name: "View Flight Details",
        description: "Expands the selected flight",
      },
      board: [
        {
          name: "View Flight Details",
          description: "Expands the selected flight",
        },
        {
          name: "Remove Flights",
          description: "Deletes a flight when the flight is clicked",
          warning: `This action is irreversible. Clicking on any flight below will permanently delete the flight, along with all assigned crew, fuel details, and any other associated data from the system.`,
        },
        //TODO: Add more Options
      ],

      //-------------------------------Auto Refresh
      doNotDisturb: false,
      refreshDataTimer: null,
      refreshDataInterval: 5 * 60 * 1000,
      lastDataUpdate: 0,

      showFlightDialog: false,

      //-----------------------------------Tooltip
      hoverEvent: null,
      tooltipStyle: {
        display: "none",
      },

      flights: [],
      myAircraft: null,

      //-----------------------------------List View
      expandedCards: [],

      //-----------------------------------Paginate
      availableBoardHeight: 0,
      aircraftSchedule: null,
      page: null,
      itemsPerPage: 0,
      pages: [],
      rowHeight: 60,
      disableNextPageButton: true,
      disablePreviousPageButton: true,

      timer: null,

      scale: 3,

      //-----------------------Filters
      showDateFilterDialog: false,
      startDate: null,
      numberOfDays: 7,
      numberOfDaysOptions: [3, 5, 7, 14, 21, 31],

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,

      //--------------------------------------Airport Info
      showAirportInformationDialog: false,
      airport: null,

      //-------------------------------------Aircraft Info
      showAircraftDialog: false,

      crewmember: null,
      flight: null,
    };
  },
  mixins: [mixin],
  created() {
    this.getData();
  },

  mounted() {
    this.$nextTick(() => {
      const board = this.$refs.board;

      this.availableBoardHeight = board.clientHeight - 120;

      this.itemsPerPage = Math.floor(
        this.availableBoardHeight / this.rowHeight
      );
    });

    window.addEventListener("resize", this.onResize, { passive: true });
    this.startRefreshTimer();
  },

  unmounted() {
    this.clearRefreshTimer();
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  computed: {
    localTime() {
      return this.$store.state.localTime;
    },

    auth() {
      return this.$store.state.auth;
    },

    totalEvents() {
      if (this.aircraftSchedule) {
        return this.aircraftSchedule.reduce((acc, item) => {
          return acc + item.events.length;
        }, 0);
      }
      return 0;
    },

    boardStyle() {
      return this.$store.state.flightBoardStyle;
    },

    captain() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "CAPT";
      });
    },

    firstOfficer() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "FO";
      });
    },

    acm1() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "ACM-1";
      });
    },

    acm2() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "ACM-2";
      });
    },

    acm3() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "ACM-3";
      });
    },

    acm4() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "ACM-4";
      });
    },

    acm5() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "ACM-5";
      });
    },

    acm6() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "ACM-6";
      });
    },

    acm7() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "ACM-7";
      });
    },

    faa() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "FA-A";
      });
    },

    fab() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "FA-B";
      });
    },
    fac() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "FA-C";
      });
    },

    fad() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "FA-D";
      });
    },

    fae() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "FA-E";
      });
    },

    faf() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "FA-F";
      });
    },

    dh1() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "DH-1";
      });
    },

    dh2() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "DH-2";
      });
    },

    dh3() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "DH-3";
      });
    },

    dh4() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "DH-4";
      });
    },

    dh5() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "DH-5";
      });
    },

    dh6() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "DH-6";
      });
    },

    mx() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "MX";
      });
    },

    gsc() {
      return this.flight.crewmembers.find((item) => {
        return item.seat === "GSC";
      });
    },

    showCurrentTime() {
      const now = new Date().getTime();

      return (
        now > new Date(this.dateFilter.start).getTime() &&
        now < new Date(this.dateFilter.end).getTime()
      );
    },

    pageNumber() {
      return this.$store.getters.flightBoardPageNumber;
    },

    nowLineLeft() {
      const x = this.getLocationLeft(
        new Date().toISOString(),
        this.dateFilter.start,
        this.scale
      );

      if (x > 0 || x < this.boardWidth) {
        return x;
      } else {
        return 0;
      }
    },

    boardWidth() {
      return this.dateFilter.days.length * (1440 / this.scale);
    },

    boardY() {
      return 50;
    },

    dayWidth() {
      return 1440 / this.scale;
    },

    // board() {
    //   return this.$store.getters.board;
    // },

    airports() {
      return this.$store.state.airports;
    },

    convertedWeight() {
      let w = 0;

      if (this.units === "Lbs") {
        if (this.rounded) {
          w = Math.ceil(this.weight / 2.20462);
        } else {
          w = (this.weight / 2.20462).toFixed(5);
        }
        return `${this.formatWeight(w)} Kgs`;
      } else {
        if (this.rounded) {
          w = Math.ceil(this.weight * 2.20462);
        } else {
          w = (this.weight * 2.20462).toFixed(5);
        }
        return `${this.formatWeight(w)} Lbs`;
      }
    },

    dateFilter() {
      return this.$store.getters.dateFilter;
    },

    errors() {
      return this.$store.getters.errors;
    },
  },

  methods: {
    handleToggleTimeZone() {
      const localTime = !this.localTime;
      this.$store.commit("updateLocalTime", localTime);
    },

    handleToggleCardExpanded(item) {
      const index = this.expandedCards.findIndex(
        (i) => i === item.aircraft.registration
      );

      if (index === -1) {
        this.expandedCards.push(item.aircraft.registration);
      } else {
        this.expandedCards.splice(index, 1);
      }
    },

    handleSelectedAction(event) {
      switch (this.selectedBoardAction.name) {
        case "Remove Flights":
          this.handleRemoveFlight(event);
          break;
        case "View Flight Details":
          this.handleShowFlightDialog(event);
          break;

        default:
          break;
      }
    },

    async handleRemoveFlight(event) {
      try {
        const token = this.auth.token;
        const _id = event._id;

        const res = await api.delete(`/aircraft-events/flight-ops/${_id}`, {
          headers: { Authorization: "Bearer " + token },
        });

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;

          setTimeout(() => {
            this.snackbarVisible = false;
          }, 3000);
          return;
        }

        await this.getData();

        if (this.totalEvents === 0) {
          this.selectedBoardAction = {
            name: "View Flight Details",
            description: "Expands the selected flight",
          };
        }
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    handleToggleBoardStyle() {
      if (this.boardStyle === "Board") {
        this.aircraftSchedule.forEach((item) => {
          if (item.events.length) {
            this.expandedCards.push(item.aircraft.registration);
          }
        });

        this.$store.commit("updateFlightBoardStyle", "List");
      } else {
        this.$store.commit("updateFlightBoardStyle", "Board");
      }
    },

    handleBeginCrewEdit() {
      if (this.flight.status !== "SCHEDULED") return;
      this.canEditCrew = true;
    },

    handleCloseCrewEdit() {
      this.canEditCrew = false;
      this.selectedCrewAction = "Assign Crew";
    },

    handleSeatAction(item) {
      if (!this.canEditCrew) return;

      // Only assign if flight is scheduled
      if (this.flight.status !== "SCHEDULED") {
        this.snackbarColor = "red";
        this.snackbarText =
          "Flight crew can only be assigned when flight is scheduled.";
        this.snackbarVisible = true;

        return;
      }

      switch (this.selectedCrewAction) {
        case "Assign Crew":
          this.$store.commit("updateSelectedSeat", item.seat);
          this.$store.commit("updateFlight", { ...this.flight });
          this.$router.push({ name: "FlightAssignmentBoard" });
          break;

        case "Unassign Crew":
          if (item.employee) {
            this.handleRemoveEmployee(item);
          } else {
            this.snackbarColor = "red";
            this.snackbarText = "No employee assigned to this seat.";
            this.snackbarVisible = true;

            setTimeout(() => {
              this.snackbarVisible = false;
            }, 3000);
          }

          break;

        case "Toogle Required":
          if (!item.employee) {
            this.handleToogleCrewRequirement(item);
          } else {
            this.snackbarColor = "red";
            this.snackbarText =
              "Employee is already assigned to this seat. Please unassign first.";
            this.snackbarVisible = true;
          }

          break;

        default:
          break;
      }
    },

    async handleRemoveEmployee(item) {
      if (this.flight.status !== "SCHEDULED") return; // Only remove if flight is scheduled

      try {
        const token = this.auth.token;
        const _id = this.flight._id;

        const res = await api.put(
          `/aircraft-events/${_id}/crewmembers/remove`,
          {
            seat: item.seat,
          },
          {
            headers: { Authorization: "Bearer " + token },
          }
        );

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;

          setTimeout(() => {
            this.snackbarVisible = false;
          }, 3000);
          return;
        }

        const flight = res.data.flight;
        this.flight = { ...flight };
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async handleToogleCrewRequirement(item) {
      if (this.flight.status !== "SCHEDULED") return; // Only remove if flight is scheduled

      try {
        const token = this.auth.token;
        const _id = this.flight._id;

        const res = await api.put(
          `/aircraft-events/${_id}/crewmembers/toggle-required`,
          {
            seat: item.seat,
          },
          {
            headers: { Authorization: "Bearer " + token },
          }
        );

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;

          setTimeout(() => {
            this.snackbarVisible = false;
          }, 3000);
          return;
        }

        const flight = res.data.flight;
        this.flight = { ...flight };
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    getSeatStyle(item) {
      let style = {
        backgroundColor: "var(--light-gray)",
      };

      if (item.employee) {
        style.backgroundColor = "var(--assigned)";
      } else {
        if (item.required) {
          style.backgroundColor = "var(--missing)";
        }
      }

      return style;
    },

    getSeatIcon(item) {
      //TODO: Add logic to handle different icons

      let icon = "fa-solid fa-user-slash";

      switch (item.seat) {
        case "CAPT":
          if (item.required) {
            if (item.employee) {
              icon = "fa-solid fa-user-pilot";
            } else {
              icon = "fa-solid fa-exclamation";
            }
          }

          break;

        default:
          if (item.required) {
            if (item.employee) {
              icon = "fa-solid fa-user";
            } else {
              icon = "fa-solid fa-exclamation";
            }
          }
          break;
      }

      return icon;
    },

    getSeatFooterText(item) {
      if (item.employee) {
        return `${item.employee.surname}, ${item.employee.givenName.substring(
          0,
          1
        )}`;
      } else {
        if (item.required) {
          return "Assign";
        } else {
          return "";
        }
      }
    },

    startRefreshTimer() {
      this.clearRefreshTimer();
      this.refreshDataTimer = setInterval(() => {
        if (
          new Date().getTime() - this.lastDataUpdate >
          this.refreshDataInterval
        ) {
          //console.log("Auto Refreshing Data", new Date().toISOString());
          this.getData();
        }
      }, this.refreshDataInterval);
    },

    clearRefreshTimer() {
      if (this.refreshDataTimer) {
        clearTimeout(this.refreshDataTimer);
      }
    },

    resetRefreshTimer() {
      this.startRefreshTimer();
    },

    async getData() {
      if (!this.auth) {
        this.$router.push({ name: "Home" });
        return;
      }

      if (this.doNotDisturb) return;
      this.doNotDisturb = true;

      //console.log("Refreshing Data", new Date().toISOString());

      this.loading = true;

      this.aircraftSchedule = null;
      this.page = null;

      try {
        const token = this.auth.token;

        const res = await api.get("/aircraft-events/flight-ops", {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: {
            _start: this.dateFilter.start,
            _end: this.dateFilter.end,
          },
        });

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.aircraftSchedule = res.data.schedule;
        this.paginate();
        this.resetRefreshTimer();
      } catch (error) {
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      } finally {
        this.loading = false;
        this.doNotDisturb = false;
        this.lastDataUpdate = new Date().getTime();
      }
    },

    async handleNavigateToPlanning() {
      try {
        let res;

        //----------------------Get Customers, Aircraft, and Airports
        this.loading = true;

        res = await this.$store.dispatch("getResources");

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        const customers = res.data.customers;
        const aircraft = res.data.aircraft;
        const airports = res.data.airports;

        this.$store.commit("updateCustomers", customers);
        this.$store.commit("updateAircraft", aircraft);
        this.$store.commit("updateAirports", airports);

        this.$router.push({ name: "Planning" });
      } catch (error) {
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      } finally {
        this.loading = false;
      }
    },

    handleNavigateToFlightView() {
      //TODO handle different types
      switch (this.flight.type) {
        case "Flight":
          this.$store.commit("updateFlight", { ...this.flight });
          this.$router.push({ name: "FlightView" });

          break;

        default:
          break;
      }
    },

    handleNavigateToAircraftOperationalView() {
      //TEMP

      this.snackbarColor = "orange";
      this.snackbarText = "Under Development";
      this.snackbarVisible = true;

      setTimeout(() => {
        this.snackbarVisible = false;
      }, 3000);
    },

    handleShowAircraftInformation(aircraft) {
      this.myAircraft = { ...aircraft };
      this.showAircraftDialog = true;
    },

    getRowStyle(i) {
      let style = {
        top: `${i * this.rowHeight + 50}px`,
        width: `${this.boardWidth}px`,
        height: `${this.rowHeight}px`,
      };

      return style;
    },

    onResize() {
      const board = this.$refs.board;

      if (!board) return;

      //Notes
      //Reduce 100px for menus, 20px for scrolling bar,

      this.availableBoardHeight = board.clientHeight - 120;

      this.itemsPerPage = Math.floor(
        this.availableBoardHeight / this.rowHeight
      );

      this.paginate();
    },

    handleShowEventTooltip(item, index) {
      let top = 0;

      if (index >= this.page.items.length - 2) {
        top = index * this.rowHeight - 50;
      } else {
        top = index * this.rowHeight + 50 + 45;
      }

      let left = this.getLocationLeft(
        item._start,
        this.dateFilter.days[0],
        this.scale
      );

      if (left < 0) {
        left = 15;
      } else {
        left += 15;
      }

      let style = {
        display: "block",
        top: `${top}px`,
        left: `${left}px`,
      };

      this.tooltipStyle = style;
      this.hoverEvent = { ...item };
    },

    handleHideEventTooltip() {
      setTimeout(() => {
        this.tooltipStyle = {
          display: "none",
        };
      }, 150);
    },

    paginate() {
      if (!this.aircraftSchedule) {
        return;
      }

      this.pages = [];
      let _pages = [];

      let data = [...this.aircraftSchedule];

      if (data.length) {
        let chunk = Math.min(
          Math.floor(this.availableBoardHeight / this.rowHeight),
          data.length
        );

        //Show warning if not enough space to display
        if (chunk < 1) {
          this.snackbarColor = "red";
          this.snackbarText = "Not enough screen space to display data";
          this.snackbarVisible = true;
          return;
        }

        //Split data into pages
        for (let i = 0; i < data.length; i += chunk) {
          let tempArray;
          tempArray = data.slice(i, i + chunk);
          _pages.push({
            index: _pages.length,
            items: tempArray,
          });
        }

        this.pages = [..._pages];

        const i = this.pages.findIndex((page) => {
          return page.index === this.pageNumber;
        });

        if (i < 0) {
          this.$store.commit("updateFlightBoardPageNumber", 0);
        }

        this.page = this.pages[this.pageNumber];
      }
    },

    handlePageUp() {
      if (this.pageNumber > 0) {
        const val = this.pageNumber - 1;
        this.$store.commit("updateFlightBoardPageNumber", val);
        this.page = this.pages[this.pageNumber];
      }
    },

    handlePageDown() {
      if (this.pageNumber < this.pages.length - 1) {
        const val = this.pageNumber + 1;
        this.$store.commit("updateFlightBoardPageNumber", val);
        this.page = this.pages[this.pageNumber];
      }
    },

    handleGoBack() {
      this.$router.go(-1);
    },

    handleNavigateToHome() {
      this.$router.go({ name: "home" });
    },

    compareAircraftTypes(a, b) {
      if (a.type < b.type) {
        return -1;
      }
      if (b.type < a.type) {
        return 1;
      }
      return 0;
    },

    handleOpenDateFilter(item) {
      this.startDate = item.substring(0, 10);
      this.numberOfDays = this.dateFilter.days.length;

      this.showDateFilterDialog = true;
    },

    handleSaveDateFilter() {
      const filterStart = new Date(this.startDate).getTime();

      this.showDateFilterDialog = false;

      //Pass filterStart in miliseconds, numberOfDays as integer
      this.$store.commit("updateDateFilter", {
        filterStart,
        numberOfDays: this.numberOfDays,
      });

      this.getData();
    },

    async handleGetPreviousFlight() {
      if (!this.page) {
        return;
      }
      const scheduleItem = this.page.items.find((item) => {
        return item.aircraft.registration === this.flight.aircraft.registration;
      });

      if (!scheduleItem) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = "Unable to find data";
        this.snackbarVisible = true;
        return;
      }

      const flights = scheduleItem.events.filter((item) => {
        return item.type === "Flight";
      });

      flights.sort(function (a, b) {
        let dateA = new Date(a._start);
        let dateB = new Date(b._start);

        return dateA - dateB;
      });

      const i = flights.findIndex((item) => {
        return item._id === this.flight._id;
      });

      if (i > 0) {
        this.flight = { ...flights[i - 1] };
      } else {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = "No previous event found within the selected range";
        this.showFlightDialog = false;
        this.snackbarVisible = true;
        return;
      }
    },

    async handleGetNextFlight() {
      if (!this.page) {
        return;
      }

      const scheduleItem = this.page.items.find((item) => {
        return item.aircraft.registration === this.flight.aircraft.registration;
      });

      if (!scheduleItem) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = "Unable to find data";
        this.snackbarVisible = true;
        return;
      }

      const flights = scheduleItem.events.filter((item) => {
        return item.type === "Flight";
      });

      flights.sort(function (a, b) {
        let dateA = new Date(a._start);
        let dateB = new Date(b._start);

        return dateA - dateB;
      });

      const i = flights.findIndex((item) => {
        return item._id === this.flight._id;
      });

      if (i < flights.length - 1) {
        this.flight = { ...flights[i + 1] };
      } else {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = "No next event found within the selected range";
        this.showFlightDialog = false;
        this.snackbarVisible = true;
        return;
      }
    },

    zoomIn() {
      if (this.scale > 1) {
        this.scale -= 1;
      }
    },

    zoomOut() {
      if (this.scale < 8) {
        this.scale += 1;
      }
    },

    async handleCaptureFlightBoard() {
      this.loading = true;

      const board = this.$refs.board;

      try {
        // Convert the DOM element to a blob
        const blob = await domtoimage.toBlob(board);

        // Convert the blob to an array buffer
        const response = new Response(blob);
        const arrayBuffer = await response.arrayBuffer();

        const pdfDoc = await PDFDocument.create();
        const pngImage = await pdfDoc.embedPng(arrayBuffer);

        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

        const title = `Flight Times- Flight Board`;
        pdfDoc.setTitle(title);

        const pngDims = pngImage.scale(0.85);
        pdfDoc.addPage([pngDims.width + 100, pngDims.height + 100]);
        let pages = pdfDoc.getPages();
        let page = pages[0];
        let pdfOptions = {
          x: 50,
          y: 50,
          size: 16,
          font: helveticaFont,
          color: rgb(0, 0, 0),
        };
        page.drawImage(pngImage, {
          x: pdfOptions.x,
          y: pdfOptions.y,
          width: pngDims.width,
          height: pngDims.height,
        });
        const pdfUrl = URL.createObjectURL(
          new Blob([await pdfDoc.save()], { type: "application/pdf" })
        );
        window.open(pdfUrl, "_blank");
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      } finally {
        this.loading = false;
      }
    },

    handleShowFlightDialog(flight) {
      this.doNotDisturb = true;

      this.flight = { ...flight };
      this.showFlightDialog = true;
    },

    handleHideFlightDialog() {
      this.flight = null;
      this.showFlightDialog = false;
      this.doNotDisturb = true;
      this.selectedCrewAction = "Assign Crew";
      this.canEditCrew = false;
      this.doNotDisturb = false;
      this.getData();
    },

    async handleShowAirportInformationDialog(airport) {
      this.airport = { ...airport };
      this.showFlightDialog = false;
      this.showAirportInformationDialog = true;
    },

    handleHideAirportInformationDialog() {
      this.airport = null;
      this.showAirportInformationDialog = false;
      this.showFlightDialog = true;
    },

    extractLastName(fullname) {
      if (fullname.length) {
        const res = fullname.split(" ");
        return res[0];
      } else {
        return "";
      }
    },

    formatHeaderDate(string) {
      const date = new Date(string);

      let options = {
        timeZone: "UTC",
        year: "numeric",
        month: "short",
        day: "numeric",
        weekday: "long",
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    },

    getAircraftTypeStyle(model) {
      let style = {
        backgroundColor: "white",
        paddingLeft: "5px",
        border: "1px solid #bebebe",
        borderRadius: "5px",
        fontFamily: "Abel, Arial, Helvetica, sans-serif",
        fontSize: "12px",
        textAlign: "center",
        cursor: "pointer",
      };

      switch (model) {
        case "MD-80":
          style.backgroundColor = "#31abb2";
          break;
        case "MD-83":
          style.backgroundColor = "#03abdb";
          break;
        default:
          break;
      }

      return style;
    },
  },
};
</script>

<style scoped>
.crewmembers-container {
  display: grid;
  grid-template-columns: repeat(27, 1fr);
  row-gap: 5px;
  margin: 5px auto;
  padding: 10px 0;
  border: 1px solid gray;
  border-radius: 5px;
  background-color: #f4f9fd;
}

.seat {
  width: 60px;
  height: 70px;
  border: 1px solid gray;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
}

.seat i {
  font-size: 1.8rem;
  color: #3b82f6;
}

.capt-seat {
  grid-column: 2/4;
  grid-row: 2;
}

.fo-seat {
  grid-column: 2/4;
  grid-row: 1;
}

.acm1-seat {
  grid-column: 4/6;
  grid-row: 2;
}

.faa-seat {
  grid-column: 8/10;
  grid-row: 2;
}

.fab-seat {
  grid-column: 8/10;
  grid-row: 1;
}

.mx-seat {
  grid-column: 10/12;
  grid-row: 2;
}

.gsc-seat {
  grid-column: 10/12;
  grid-row: 1;
}

.dh1-seat {
  grid-column: 12/14;
  grid-row: 2;
}

.dh2-seat {
  grid-column: 12/14;
  grid-row: 1;
}

.dh3-seat {
  grid-column: 14/16;
  grid-row: 2;
}

.dh4-seat {
  grid-column: 14/16;
  grid-row: 1;
}

.dh5-seat {
  grid-column: 16/18;
  grid-row: 2;
}

.dh6-seat {
  grid-column: 16/18;
  grid-row: 1;
}

.acm2-seat {
  grid-column: 18/20;
  grid-row: 2;
}

.acm3-seat {
  grid-column: 18/20;
  grid-row: 1;
}

.acm4-seat {
  grid-column: 20/22;
  grid-row: 2;
}

.acm5-seat {
  grid-column: 20/22;
  grid-row: 1;
}

.acm6-seat {
  grid-column: 22/24;
  grid-row: 2;
}

.acm7-seat {
  grid-column: 22/24;
  grid-row: 1;
}

.fac-seat {
  grid-column: 24/26;
  grid-row: 2;
}

.fad-seat {
  grid-column: 24/26;
  grid-row: 1;
}

.fae-seat {
  grid-column: 26/28;
  grid-row: 2;
}

.faf-seat {
  grid-column: 26/28;
  grid-row: 1;
}

.event-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  min-width: 250px;
  padding: 5px;
  border-radius: 3px;
  background-color: rgba(128, 128, 128, 0.9);
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  z-index: 700;
}

.right-panel-row {
  position: absolute;
  top: 50px;
  left: 0;
  height: 0;
  width: 0;
  border-bottom: 1px solid gray;
  background-color: #fff;
  overflow: hidden;
}

.right-panel-row:nth-child(odd) {
  background-color: #f4f9fd;
}

p {
  width: 100%;
  min-height: 100px;
}

.remove-icon:hover {
  color: red;
}

.fa-left {
  font-size: 2.3rem;
  color: #8e8e8e;
  cursor: pointer;
}

.fa-left:hover {
  color: #1d8de9;
}

.fa-right {
  font-size: 2.3rem;
  color: #8e8e8e;
  cursor: pointer;
}

.fa-right:hover {
  color: #1d8de9;
}

.fa-star {
  font-size: 1.1rem;
  color: #d4af37;
  padding: 0 1rem;
}

.fa-add:hover {
  color: blue;
}

.fa-close {
  font-size: 1.1rem;
  color: #8e8e8e;
  cursor: pointer;
  padding: 0 15px;
}

.fa-close:hover {
  color: red;
}

.fa-calculator {
  font-size: 2rem;
  color: #8e8e8e;
  cursor: pointer;
  padding: 0 15px;
}

.weight-converter {
  align-items: center;
  padding: 5px 10px;
}

.aircraft-operational {
  background-color: #f5d0a9;
}

.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.event-card {
  box-sizing: border-box;
  border: 1px solid gray;
  border-radius: 3px;
  padding: 5px 15px;
  margin-bottom: 2px;
  color: black;
  cursor: pointer;
}

.private {
  border-left: 4px solid red;
}
</style>
