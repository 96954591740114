<template>
  <div>
    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <nav>
      <ul class="nav-wrapper">
        <li class="icon" @click="handleGoBack">
          <a href="#">
            <span class="tooltip">Go Back</span>
            <span><i class="fa-solid fa-arrow-left text-white ml-3"> </i></span>
          </a>
        </li>

        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white ml-3"> </i></span
          ></RouterLink>
        </li>

        <li>
          <a href="#">
            <div class="ml-3 text-base lg:text-2xl text-white">
              Security Group
            </div>
          </a>
        </li>

        <li>
          <RouterLink to="/"></RouterLink>
        </li>
      </ul>
    </nav>

    <r-spinner v-if="loading"> </r-spinner>
    <div class="p-5" v-if="securityGroup">
      <div class="mb-3">
        <p class="text-sm text-gray-500">Security Group Name</p>
        <input
          type="text"
          v-model="securityGroupName"
          placeholder="Security Group Name"
          maxlength="100"
          name="securityGroupName"
          id="securityGroupName"
          required
          class="min-h-9 width-block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
        />
      </div>

      <!-- Companies -->
      <div class="border border-gray-500 rounded-md p-5 mb-1">
        <div class="flex justify-between border-b border-gray-500 mb-2">
          <div class="text-xl text-blue-800 min-w-[80px]">Companies</div>
          <i
            v-if="showCompanies"
            class="fa-solid fa-chevron-up text-gray-400 cursor-pointer"
            @click="showCompanies = false"
          >
          </i>
          <i
            v-else
            class="fa-solid fa-chevron-down text-blue-500 cursor-pointer"
            @click="showCompanies = true"
          >
          </i>
        </div>
        <div class="text-sm text-red-500" v-if="!selectedCompanies.length">
          Please select at least one company
        </div>
        <div class="text-sm text-gray-500" v-else>
          {{ selectedCompanies.toString() }}
        </div>

        <div
          class="max-h-[300px] lg:max-h[500px] overflow-x-auto mt-3"
          v-if="showCompanies"
        >
          <div class="relative shadow-md">
            <table
              class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
            >
              <tbody>
                <tr
                  v-for="(company, index) in companies"
                  :key="index"
                  class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td class="w-4 p-4">
                    <div class="flex items-center">
                      <input
                        v-model="selectedCompanies"
                        :id="company"
                        :value="company"
                        type="checkbox"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                    </div>
                  </td>
                  <td
                    scope="row"
                    class="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {{ company }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="flex mt-2">
            <div
              v-if="selectedCompanies.length"
              @click="selectedCompanies = []"
              class="my-1 ml-1 cursor-pointer text-base underline text-red-500"
            >
              Clear Selected
            </div>

            <div
              v-if="selectedCompanies.length !== companies.length"
              @click="selectedCompanies = [...companies]"
              class="my-1 ml-3 cursor-pointer text-base underline text-blue-600"
            >
              Select All
            </div>
          </div>
        </div>
      </div>

      <!-- Job Titles -->
      <div class="border border-gray-500 rounded-md p-5 mb-1">
        <div class="flex justify-between border-b border-gray-500 mb-2">
          <div class="text-xl text-blue-800 min-w-[80px]">Job Titles</div>
          <i
            v-if="showJobTitles"
            class="fa-solid fa-chevron-up text-gray-400 cursor-pointer"
            @click="showJobTitles = false"
          >
          </i>

          <i
            v-else
            class="fa-solid fa-chevron-down text-blue-500 cursor-pointer"
            @click="showJobTitles = true"
          >
          </i>
        </div>

        <div class="text-sm text-red-500" v-if="!selectedJobTitles.length">
          Please select at least one job title
        </div>
        <div class="text-sm text-gray-500" v-else>
          {{ selectedJobTitles.toString() }}
        </div>

        <div
          class="max-h-[300px] lg:max-h[500px] overflow-x-auto mt-3"
          v-if="showJobTitles"
        >
          <div class="relative shadow-md">
            <table
              class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
            >
              <tbody>
                <tr
                  v-for="(jobTitle, index) in jobTitles"
                  :key="index"
                  class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td class="w-4 p-4">
                    <div class="flex items-center">
                      <input
                        v-model="selectedJobTitles"
                        :id="jobTitle"
                        :value="jobTitle"
                        type="checkbox"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                    </div>
                  </td>
                  <td
                    scope="row"
                    class="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {{ jobTitle }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="flex mt-2">
            <div
              v-if="selectedJobTitles.length"
              @click="selectedJobTitles = []"
              class="my-1 ml-1 cursor-pointer text-base underline text-red-500"
            >
              Clear Selected
            </div>

            <div
              v-if="selectedJobTitles.length !== jobTitles.length"
              @click="selectedJobTitles = [...jobTitles]"
              class="my-1 ml-3 cursor-pointer text-base underline text-blue-600"
            >
              Select All
            </div>
          </div>
        </div>
      </div>

      <!-- Aircraft -->
      <div class="border border-gray-500 rounded-md p-5 mb-1">
        <div class="text-xl text-blue-800 mb-5 border-b border-gray-500">
          Aircraft
        </div>
        <div class="flex justify-between">
          <!-- Create -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="createAircraft"
              disabled
              id="createAircraft"
              aria-describedby="create-aircraft"
              name="createAircraft"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-not-allowed"
            />

            <div class="ml-3 text-base leading-6">
              <label for="createAircraft" class="font-medium text-gray-900"
                >Create</label
              >
              {{ " " }}
            </div>
          </div>

          <!-- Read -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="readAircraft"
              id="readAircraft"
              aria-describedby="read-aircraft"
              name="readAircraft"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label for="readAircraft" class="font-medium text-gray-900"
                >Read</label
              >
              {{ " " }}
            </div>
          </div>

          <!-- Update -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="updateAircraft"
              id="updateAircraft"
              aria-describedby="update-aircraft"
              name="updateAircraft"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label for="updateAircraft" class="font-medium text-gray-900"
                >Update</label
              >
              {{ " " }}
            </div>
          </div>

          <!-- Delete -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="deleteAircraft"
              disabled
              id="deleteAircraft"
              aria-describedby="delete-aircraft"
              name="deleteAircraft"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-not-allowed"
            />

            <div class="ml-3 text-base leading-6">
              <label for="deleteAircraft" class="font-medium text-gray-900"
                >Delete</label
              >
              {{ " " }}
            </div>
          </div>
        </div>
      </div>

      <!-- Airports -->
      <div class="border border-gray-500 rounded-md p-5 mb-1">
        <div class="text-xl text-blue-800 mb-5 border-b border-gray-500">
          Airports
        </div>
        <div class="flex justify-between">
          <!-- Create -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="createAirports"
              disabled
              id="createAirports"
              aria-describedby="create-airports"
              name="createAirports"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-not-allowed"
            />

            <div class="ml-3 text-base leading-6">
              <label for="createAirports" class="font-medium text-gray-900"
                >Create</label
              >
              {{ " " }}
            </div>
          </div>

          <!-- Read -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="readAirports"
              id="readAirports"
              aria-describedby="read-airports"
              name="readAirports"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label for="readAirports" class="font-medium text-gray-900"
                >Read</label
              >
              {{ " " }}
            </div>
          </div>

          <!-- Update -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="updateAirports"
              id="updateAirports"
              aria-describedby="update-airports"
              name="updateAirports"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label for="updateAirports" class="font-medium text-gray-900"
                >Update</label
              >
              {{ " " }}
            </div>
          </div>

          <!-- Delete -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="deleteAirports"
              disabled
              id="deleteAirports"
              aria-describedby="delete-airports"
              name="deleteAirports"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-not-allowed"
            />

            <div class="ml-3 text-base leading-6">
              <label for="deleteAirports" class="font-medium text-gray-900"
                >Delete</label
              >
              {{ " " }}
            </div>
          </div>
        </div>
        <p class="text-sm text-gray-500" v-if="updateAirports">
          Enabling "Update" allows the user to update the airport's Hazard,
          COVID-19, and Minimum Loadmasters Exemptions.
        </p>
      </div>

      <!-- Bidlines -->
      <div class="border border-gray-500 rounded-md p-5 mb-1">
        <div class="text-xl text-blue-800 mb-5 border-b border-gray-500">
          Bidlines
        </div>
        <div class="flex justify-between">
          <!-- Create -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="createBidlines"
              id="createBidlines"
              aria-describedby="create-bidlines"
              name="createBidlines"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label for="createBidlines" class="font-medium text-gray-900"
                >Create</label
              >
              {{ " " }}
            </div>
          </div>

          <!-- Read -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="readBidlines"
              id="readBidlines"
              aria-describedby="read-bidlines"
              name="readBidlines"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label for="readBidlines" class="font-medium text-gray-900"
                >Read</label
              >
              {{ " " }}
            </div>
          </div>

          <!-- Update -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="updateBidlines"
              id="updateBidlines"
              aria-describedby="update-bidlines"
              name="updateBidlines"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label for="updateBidlines" class="font-medium text-gray-900"
                >Update</label
              >
              {{ " " }}
            </div>
          </div>

          <!-- Delete -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="deleteBidlines"
              id="deleteBidlines"
              aria-describedby="delete-bidlines"
              name="deleteBidlines"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label for="deleteBidlines" class="font-medium text-gray-900"
                >Delete</label
              >
              {{ " " }}
            </div>
          </div>
        </div>
      </div>

      <!-- Employees -->
      <div class="border border-gray-500 rounded-md p-5 mb-1">
        <div class="text-xl text-blue-800 mb-5 border-b border-gray-500">
          Employees
        </div>
        <div class="flex justify-between">
          <!-- Create -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="createEmployees"
              id="createEmployees"
              aria-describedby="create-employees"
              name="createEmployees"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label for="createEmployees" class="font-medium text-gray-900"
                >Create</label
              >
              {{ " " }}
            </div>
          </div>

          <!-- Read -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="readEmployees"
              id="readEmployees"
              aria-describedby="read-employees"
              name="readEmployees"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label for="readEmployees" class="font-medium text-gray-900"
                >Read</label
              >
              {{ " " }}
            </div>
          </div>

          <!-- Update -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="updateEmployees"
              id="updateEmployees"
              aria-describedby="update-employees"
              name="updateEmployees"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label for="updateEmployees" class="font-medium text-gray-900"
                >Update</label
              >
              {{ " " }}
            </div>
          </div>

          <!-- Delete -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="deleteEmployees"
              id="deleteEmployees"
              aria-describedby="delete-employees"
              name="deleteEmployees"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label for="deleteEmployees" class="font-medium text-gray-900"
                >Delete</label
              >
              {{ " " }}
            </div>
          </div>
        </div>

        <div class="flex">
          <div class="ml-3">
            <label class="block text-sm font-medium leading-6 text-gray-900"
              >Read level</label
            >

            <select
              class="block w-48 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="employeeReadLevel"
            >
              <option
                v-for="option in employeeReadLevelOptions"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>

          <div class="ml-3">
            <label class="block text-sm font-medium leading-6 text-gray-900"
              >Update level</label
            >

            <select
              class="block w-48 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="employeeUpdateLevel"
            >
              <option
                v-for="option in employeeUpdateLevelOptions"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
        </div>

        <div class="flex h-6 items-center my-3">
          <input
            v-model="filterEmployeesByGateway"
            id="filterEmployeesByGateway"
            aria-describedby="filter-employees-by-gateway"
            name="filterEmployeesByGateway"
            type="checkbox"
            class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
          />

          <div class="ml-10 text-base leading-6">
            <label
              for="filterEmployeesByGateway"
              class="font-medium text-gray-900"
              >Filter Employees By Gateway</label
            >
            {{ " " }}
          </div>
        </div>
      </div>

      <!-- Flights -->
      <div class="border border-gray-500 rounded-md p-5 mb-1">
        <div class="text-xl text-blue-800 mb-5 border-b border-gray-500">
          Flights
        </div>
        <div class="flex justify-between">
          <!-- Create -->
          <div class="flex h-6 items-center mb-2">
            <input
              disabled
              id="createFlights"
              aria-describedby="create-flights"
              name="createFlights"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-not-allowed"
            />

            <div class="ml-3 text-base leading-6">
              <label for="createFlights" class="font-medium text-gray-900"
                >Create</label
              >
              {{ " " }}
            </div>
          </div>

          <!-- Read -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="readFlights"
              id="readFlights"
              aria-describedby="read-flights"
              name="readFlights"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label for="readFlights" class="font-medium text-gray-900"
                >Read</label
              >
              {{ " " }}
            </div>
          </div>

          <!-- Update -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="updateFlights"
              id="updateFlights"
              aria-describedby="update-flights"
              name="updateFlights"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label for="updateFlights" class="font-medium text-gray-900"
                >Update</label
              >
              {{ " " }}
            </div>
          </div>

          <!-- Delete -->
          <div class="flex h-6 items-center mb-2">
            <input
              disabled
              id="deleteFlights"
              aria-describedby="delete-flights"
              name="deleteFlights"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-not-allowed"
            />

            <div class="ml-3 text-base leading-6">
              <label for="deleteFlights" class="font-medium text-gray-900"
                >Delete</label
              >
              {{ " " }}
            </div>
          </div>
        </div>

        <div class="flex mt-3">
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="filterFlightsByGateway"
              id="filterFlightsByGateway"
              aria-describedby="filter-flights-by-gateway"
              name="filterFlightsByGateway"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-10 text-base leading-6">
              <label
                for="filterFlightsByGateway"
                class="font-medium text-gray-900"
                >Filter Flights By Gateway</label
              >
              {{ " " }}
            </div>
          </div>

          <div class="ml-10 flex h-6 items-center mb-2">
            <input
              v-model="getAircraftScheduleConflicts"
              id="getAircraftScheduleConflicts"
              aria-describedby="get-aircraft-schedule-conflicts"
              name="getAircraftScheduleConflicts"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label
                for="getAircraftScheduleConflicts"
                class="font-medium text-gray-900"
                >Get Aircraft Schedule Conflicts</label
              >
              {{ " " }}
            </div>
          </div>
        </div>
      </div>

      <!-- Flight Assignments -->
      <div class="border border-gray-500 rounded-md p-5 mb-1">
        <div class="text-xl text-blue-800 mb-5 border-b border-gray-500">
          Flight Assignments
        </div>
        <div class="flex justify-between">
          <!-- Create -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="createFlightAssignments"
              id="createFlightAssignments"
              aria-describedby="create-flightAssignments"
              name="createFlightAssignments"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label
                for="createFlightAssignments"
                class="font-medium text-gray-900"
                >Create</label
              >
              {{ " " }}
            </div>
          </div>

          <!-- Read -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="readFlightAssignments"
              id="readFlightAssignments"
              aria-describedby="read-flightAssignments"
              name="readFlightAssignments"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label
                for="readFlightAssignments"
                class="font-medium text-gray-900"
                >Read</label
              >
              {{ " " }}
            </div>
          </div>

          <!-- Update -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="updateFlightAssignments"
              id="updateFlightAssignments"
              aria-describedby="update-flightAssignments"
              name="updateFlightAssignments"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label
                for="updateFlightAssignments"
                class="font-medium text-gray-900"
                >Update</label
              >
              {{ " " }}
            </div>
          </div>

          <!-- Delete -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="deleteFlightAssignments"
              id="deleteFlightAssignments"
              aria-describedby="delete-flightAssignments"
              name="deleteFlightAssignments"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label
                for="deleteFlightAssignments"
                class="font-medium text-gray-900"
                >Delete</label
              >
              {{ " " }}
            </div>
          </div>
        </div>

        <div class="ml-3">
          <label class="block text-sm font-medium leading-6 text-gray-900"
            >Read level</label
          >

          <select
            class="block w-48 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
            v-model="flightAssignmentReadLevel"
          >
            <option
              v-for="option in flightAssignmentReadLevelOptions"
              :key="option"
              :value="option"
            >
              {{ option }}
            </option>
          </select>
        </div>

        <!-- Cargo Roles -->
        <div class="border border-gray-500 rounded-md p-2 my-3">
          <div class="flex justify-between border-b border-gray-500 mb-2">
            <div class="text-xl text-blue-800 min-w-[80px]">Cargo Roles</div>
            <i
              v-if="showFlightAssignmentsCargoRoles"
              class="fa-solid fa-chevron-up text-gray-400 cursor-pointer"
              @click="showFlightAssignmentsCargoRoles = false"
            >
            </i>
            <i
              v-else
              class="fa-solid fa-chevron-down text-blue-500 cursor-pointer"
              @click="showFlightAssignmentsCargoRoles = true"
            >
            </i>
          </div>

          <div
            class="ml-2 text-sm text-red-500"
            v-if="!selectedFlightAssignmentCargoRoles.length"
          >
            Please select at least one Cargo Role
          </div>
          <div class="ml-2 text-sm text-gray-500" v-else>
            {{ selectedFlightAssignmentCargoRoles.toString() }}
          </div>

          <div
            class="max-h-[300px] lg:max-h[500px] overflow-x-auto"
            v-if="showFlightAssignmentsCargoRoles"
          >
            <div class="relativeshadow-md">
              <table
                class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
              >
                <tbody>
                  <tr
                    v-for="(role, index) in flightAssignmentCargoRoleOptions"
                    :key="index"
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td class="w-4 p-4">
                      <div class="flex items-center">
                        <input
                          v-model="selectedFlightAssignmentCargoRoles"
                          :id="role"
                          :value="role"
                          type="checkbox"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                      </div>
                    </td>
                    <td
                      scope="row"
                      class="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {{ role }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="flex mt-2">
              <div
                v-if="selectedFlightAssignmentCargoRoles.length"
                @click="selectedFlightAssignmentCargoRoles = []"
                class="my-1 ml-1 cursor-pointer text-base underline text-red-500"
              >
                Clear Selected
              </div>

              <div
                v-if="
                  selectedFlightAssignmentCargoRoles.length !==
                  flightAssignmentCargoRoleOptions.length
                "
                @click="
                  selectedFlightAssignmentCargoRoles = [
                    ...flightAssignmentCargoRoleOptions,
                  ]
                "
                class="my-1 ml-3 cursor-pointer text-base underline text-blue-600"
              >
                Select All
              </div>
            </div>
          </div>
        </div>

        <!-- Passenger Roles -->
        <div class="border border-gray-500 rounded-md p-2 my-3">
          <div class="flex justify-between border-b border-gray-500 mb-2">
            <div class="text-xl text-blue-800 min-w-[80px]">
              Passenger Roles
            </div>
            <i
              v-if="showFlightAssignmentsPassengerRoles"
              class="fa-solid fa-chevron-up text-gray-400 cursor-pointer"
              @click="showFlightAssignmentsPassengerRoles = false"
            >
            </i>
            <i
              v-else
              class="fa-solid fa-chevron-down text-blue-500 cursor-pointer"
              @click="showFlightAssignmentsPassengerRoles = true"
            >
            </i>
          </div>

          <div
            class="ml-2 text-sm text-red-500"
            v-if="!selectedFlightAssignmentPassengerRoles.length"
          >
            Please select at least one Passenger Role
          </div>
          <div class="ml-2 text-sm text-gray-500" v-else>
            {{ selectedFlightAssignmentPassengerRoles.toString() }}
          </div>

          <div
            class="max-h-[300px] lg:max-h[500px] overflow-x-auto"
            v-if="showFlightAssignmentsPassengerRoles"
          >
            <div class="relativeshadow-md">
              <table
                class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
              >
                <tbody>
                  <tr
                    v-for="(
                      role, index
                    ) in flightAssignmentPassengerRoleOptions"
                    :key="index"
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td class="w-4 p-4">
                      <div class="flex items-center">
                        <input
                          v-model="selectedFlightAssignmentPassengerRoles"
                          :id="role"
                          :value="role"
                          type="checkbox"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                      </div>
                    </td>
                    <td
                      scope="row"
                      class="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {{ role }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="flex mt-2">
              <div
                v-if="selectedFlightAssignmentPassengerRoles.length"
                @click="selectedFlightAssignmentPassengerRoles = []"
                class="my-1 ml-1 cursor-pointer text-base underline text-red-500"
              >
                Clear Selected
              </div>

              <div
                v-if="
                  selectedFlightAssignmentPassengerRoles.length !==
                  flightAssignmentPassengerRoleOptions.length
                "
                @click="
                  selectedFlightAssignmentPassengerRoles = [
                    ...flightAssignmentPassengerRoleOptions,
                  ]
                "
                class="my-1 ml-3 cursor-pointer text-base underline text-blue-600"
              >
                Select All
              </div>
            </div>
          </div>
        </div>

        <div class="flex h-6 items-center mb-2">
          <input
            v-model="flightAssignmentsOverrideHardWarnings"
            id="flightAssignmentsOverrideHardWarnings"
            aria-describedby="flight-assignments-override-hard-warnings"
            name="flightAssignmentsOverrideHardWarnings"
            type="checkbox"
            class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
          />

          <div class="ml-10 text-base leading-6">
            <label
              for="flightAssignmentsOverrideHardWarnings"
              class="font-medium text-gray-900"
              >Override Hard Level Warnings</label
            >
            {{ " " }}
          </div>
        </div>
      </div>

      <!-- Ground Assignmentss -->
      <div class="border border-gray-500 rounded-md p-5 mb-1">
        <div class="text-xl text-blue-800 mb-5 border-b border-gray-500">
          Ground Assignments
        </div>
        <div class="flex justify-between">
          <!-- Create -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="createGroundAssignments"
              id="createGroundAssignments"
              aria-describedby="create-groundAssignments"
              name="createGroundAssignments"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label
                for="createGroundAssignments"
                class="font-medium text-gray-900"
                >Create</label
              >
              {{ " " }}
            </div>
          </div>

          <!-- Read -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="readGroundAssignments"
              id="readGroundAssignments"
              aria-describedby="read-groundAssignments"
              name="readGroundAssignments"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label
                for="readGroundAssignments"
                class="font-medium text-gray-900"
                >Read</label
              >
              {{ " " }}
            </div>
          </div>

          <!-- Update -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="updateGroundAssignments"
              id="updateGroundAssignments"
              aria-describedby="update-groundAssignments"
              name="updateGroundAssignments"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label
                for="updateGroundAssignments"
                class="font-medium text-gray-900"
                >Update</label
              >
              {{ " " }}
            </div>
          </div>

          <!-- Delete -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="deleteGroundAssignments"
              id="deleteGroundAssignments"
              aria-describedby="delete-groundAssignments"
              name="deleteGroundAssignments"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label
                for="deleteGroundAssignments"
                class="font-medium text-gray-900"
                >Delete</label
              >
              {{ " " }}
            </div>
          </div>
        </div>

        <div class="ml-3">
          <label class="block text-sm font-medium leading-6 text-gray-900"
            >Read level</label
          >

          <select
            class="block w-48 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
            v-model="groundAssignmentReadLevel"
          >
            <option
              v-for="option in groundAssignmentReadLevelOptions"
              :key="option"
              :value="option"
            >
              {{ option }}
            </option>
          </select>
        </div>

        <!-- Cargo Roles -->
        <div class="border border-gray-500 rounded-md p-2 my-3">
          <div class="flex justify-between border-b border-gray-500 mb-2">
            <div class="text-xl text-blue-800 min-w-[80px]">Cargo Roles</div>
            <i
              v-if="showGroundAssignmentsCargoRoles"
              class="fa-solid fa-chevron-up text-gray-400 cursor-pointer"
              @click="showGroundAssignmentsCargoRoles = false"
            >
            </i>
            <i
              v-else
              class="fa-solid fa-chevron-down text-blue-500 cursor-pointer"
              @click="showGroundAssignmentsCargoRoles = true"
            >
            </i>
          </div>
          <div
            class="ml-2 text-sm text-red-500"
            v-if="!selectedGroundAssignmentCargoRoles.length"
          >
            Please select at least one Cargo Role
          </div>
          <div class="ml-2 text-sm text-gray-500" v-else>
            {{ selectedGroundAssignmentCargoRoles.toString() }}
          </div>

          <div
            class="max-h-[300px] lg:max-h[500px] overflow-x-auto"
            v-if="showGroundAssignmentsCargoRoles"
          >
            <div class="relativeshadow-md">
              <table
                class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
              >
                <tbody>
                  <tr
                    v-for="(role, index) in groundAssignmentCargoRoleOptions"
                    :key="index"
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td class="w-4 p-4">
                      <div class="flex items-center">
                        <input
                          v-model="selectedGroundAssignmentCargoRoles"
                          :id="role"
                          :value="role"
                          type="checkbox"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                      </div>
                    </td>
                    <td
                      scope="row"
                      class="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {{ role }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="flex mt-2">
              <div
                v-if="selectedGroundAssignmentCargoRoles.length"
                @click="selectedGroundAssignmentCargoRoles = []"
                class="my-1 ml-1 cursor-pointer text-base underline text-red-500"
              >
                Clear Selected
              </div>

              <div
                v-if="
                  selectedGroundAssignmentCargoRoles.length !==
                  groundAssignmentCargoRoleOptions.length
                "
                @click="
                  selectedGroundAssignmentCargoRoles = [
                    ...groundAssignmentCargoRoleOptions,
                  ]
                "
                class="my-1 ml-3 cursor-pointer text-base underline text-blue-600"
              >
                Select All
              </div>
            </div>
          </div>
        </div>

        <!-- Passenger Roles -->
        <div class="border border-gray-500 rounded-md p-2 my-3">
          <div class="flex justify-between border-b border-gray-500 mb-2">
            <div class="text-xl text-blue-800 min-w-[80px]">
              Passenger Roles
            </div>
            <i
              v-if="showGroundAssignmentsPassengerRoles"
              class="fa-solid fa-chevron-up text-gray-400 cursor-pointer"
              @click="showGroundAssignmentsPassengerRoles = false"
            >
            </i>
            <i
              v-else
              class="fa-solid fa-chevron-down text-blue-500 cursor-pointer"
              @click="showGroundAssignmentsPassengerRoles = true"
            >
            </i>
          </div>
          <div
            class="ml-2 text-sm text-red-500"
            v-if="!selectedGroundAssignmentPassengerRoles.length"
          >
            Please select at least one Passenger Role
          </div>
          <div class="ml-2 text-sm text-gray-500" v-else>
            {{ selectedGroundAssignmentPassengerRoles.toString() }}
          </div>

          <div
            class="max-h-[300px] lg:max-h[500px] overflow-x-auto"
            v-if="showGroundAssignmentsPassengerRoles"
          >
            <div class="relativeshadow-md">
              <table
                class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
              >
                <tbody>
                  <tr
                    v-for="(
                      role, index
                    ) in groundAssignmentPassengerRoleOptions"
                    :key="index"
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td class="w-4 p-4">
                      <div class="flex items-center">
                        <input
                          v-model="selectedGroundAssignmentPassengerRoles"
                          :id="role"
                          :value="role"
                          type="checkbox"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                      </div>
                    </td>
                    <td
                      scope="row"
                      class="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {{ role }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="flex mt-2">
              <div
                v-if="selectedGroundAssignmentPassengerRoles.length"
                @click="selectedGroundAssignmentPassengerRoles = []"
                class="my-1 ml-1 cursor-pointer text-base underline text-red-500"
              >
                Clear Selected
              </div>

              <div
                v-if="
                  selectedGroundAssignmentPassengerRoles.length !==
                  groundAssignmentPassengerRoleOptions.length
                "
                @click="
                  selectedGroundAssignmentPassengerRoles = [
                    ...groundAssignmentPassengerRoleOptions,
                  ]
                "
                class="my-1 ml-3 cursor-pointer text-base underline text-blue-600"
              >
                Select All
              </div>
            </div>
          </div>
        </div>

        <div class="flex h-6 items-center mb-2">
          <input
            v-model="groundAssignmentsOverrideHardWarnings"
            id="groundAssignmentsOverrideHardWarnings"
            aria-describedby="ground-assignments-override-hard-warnings"
            name="groundAssignmentsOverrideHardWarnings"
            type="checkbox"
            class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
          />

          <div class="ml-10 text-base leading-6">
            <label
              for="groundAssignmentsOverrideHardWarnings"
              class="font-medium text-gray-900"
              >Override Hard Level Warnings</label
            >
            {{ " " }}
          </div>
        </div>
      </div>

      <!-- Payroll Reports -->
      <div class="border border-gray-500 rounded-md p-5 mb-1">
        <div class="text-xl text-blue-800 mb-5 border-b border-gray-500">
          PayrollReports
        </div>
        <div class="flex justify-between">
          <!-- Create -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="createPayrollReports"
              id="createPayrollReports"
              aria-describedby="create-payrollReports"
              name="createPayrollReports"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label
                for="createPayrollReports"
                class="font-medium text-gray-900"
                >Create</label
              >
              {{ " " }}
            </div>
          </div>

          <!-- Read -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="readPayrollReports"
              id="readPayrollReports"
              aria-describedby="read-payrollReports"
              name="readPayrollReports"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label for="readPayrollReports" class="font-medium text-gray-900"
                >Read</label
              >
              {{ " " }}
            </div>
          </div>

          <!-- Update -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="updatePayrollReports"
              id="updatePayrollReports"
              aria-describedby="update-payrollReports"
              name="updatePayrollReports"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label
                for="updatePayrollReports"
                class="font-medium text-gray-900"
                >Update</label
              >
              {{ " " }}
            </div>
          </div>

          <!-- Delete -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="deletePayrollReports"
              id="deletePayrollReports"
              aria-describedby="delete-payrollReports"
              name="deletePayrollReports"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label
                for="deletePayrollReports"
                class="font-medium text-gray-900"
                >Delete</label
              >
              {{ " " }}
            </div>
          </div>
        </div>

        <div class="flex justify-between">
          <div class="ml-3">
            <label class="block text-sm font-medium leading-6 text-gray-900"
              >Create level</label
            >

            <select
              class="block w-48 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="payrollReportCreateLevel"
            >
              <option
                v-for="option in payrollReportCreateLevelOptions"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>

          <div class="ml-3">
            <label class="block text-sm font-medium leading-6 text-gray-900"
              >Read level</label
            >

            <select
              class="block w-48 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="payrollReportReadLevel"
            >
              <option
                v-for="option in payrollReportReadLevelOptions"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>

          <div class="ml-3">
            <label class="block text-sm font-medium leading-6 text-gray-900"
              >Update level</label
            >

            <select
              class="block w-48 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="payrollReportUpdateLevel"
            >
              <option
                v-for="option in payrollReportUpdateLevelOptions"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>

          <div class="ml-3">
            <label class="block text-sm font-medium leading-6 text-gray-900"
              >Delete level</label
            >

            <select
              class="block w-48 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="payrollReportDeleteLevel"
            >
              <option
                v-for="option in payrollReportDeleteLevelOptions"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <!-- Security Groups -->
      <div class="border border-gray-500 rounded-md p-5 my-1">
        <div class="text-xl text-blue-800 mb-5 border-b border-gray-500">
          Security Groups
        </div>
        <div class="flex justify-between">
          <!-- Create -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="createSecurityGroups"
              id="createSecurityGroups"
              aria-describedby="create-securityGroups"
              name="createSecurityGroups"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label
                for="createSecurityGroups"
                class="font-medium text-gray-900"
                >Create</label
              >
              {{ " " }}
            </div>
          </div>

          <!-- Read -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="readSecurityGroups"
              disabled
              id="readSecurityGroups"
              aria-describedby="read-securityGroups"
              name="readSecurityGroups"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-not-allowed"
            />

            <div class="ml-3 text-base leading-6">
              <label for="readSecurityGroups" class="font-medium text-gray-900"
                >Read</label
              >
              {{ " " }}
            </div>
          </div>

          <!-- Update -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="updateSecurityGroups"
              id="updateSecurityGroups"
              aria-describedby="update-securityGroups"
              name="updateSecurityGroups"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label
                for="updateSecurityGroups"
                class="font-medium text-gray-900"
                >Update</label
              >
              {{ " " }}
            </div>
          </div>

          <!-- Delete -->
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="deleteSecurityGroups"
              id="deleteSecurityGroups"
              aria-describedby="delete-securityGroups"
              name="deleteSecurityGroups"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label
                for="deleteSecurityGroups"
                class="font-medium text-gray-900"
                >Delete</label
              >
              {{ " " }}
            </div>
          </div>
        </div>
      </div>

      <!-- Footer -->
      <div class="flex justify-between">
        <div v-if="securityGroup._id">
          <button
            class="btn bg-red-500 text-white"
            @click="confirmDelete = true"
            v-if="!confirmDelete"
          >
            Delete Group
          </button>
          <button
            class="btn bg-red-500 text-white"
            @click="handleDeleteSecurityGroup"
            v-else
          >
            Confirm Delete
          </button>
        </div>
        <div v-else></div>

        <div class="flex justify-end">
          <button
            v-if="securityGroup_id"
            class="btn bg-purple-500 text-white"
            @click="handleSaveSecurityGroup(true)"
          >
            Save Group As
          </button>

          <button
            class="ml-1 btn bg-green-500 text-white"
            @click="handleSaveSecurityGroup(false)"
          >
            Save Group
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import { mixin } from "../mixins/mixin";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";

export default {
  name: "securityGroup",
  mixins: [mixin],
  components: { RSnackbar, RSpinner },
  data() {
    return {
      loading: false,

      securityGroup_id: null,
      securityGroupName: "",

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,

      flightUpdateFields: ["Remarks"],
      enableGroupToChangeEmployeeSecurityGroup: false,
      confirmDelete: false,

      //---------------------------Companies
      companies: [
        "World Atlantic",
        "AGS",
        "Aviablu",
        "Flightline",
        "FEAM",
        "Encompass Air",
        "Connexus Air",
        "WFS",
      ],
      selectedCompanies: [],
      showCompanies: false,

      //---------------------------Job Titles
      jobTitles: [
        "Loadmaster",
        "Check Loadmaster",
        "Senior Loadmaster",
        "Contract Loadmaster",
        "Loadmaster/PSR",
        "Loadmaster Trainee",
        "PSR",
        "Check PSR",
        "Lead PSR",
        "Senior PSR",
        "PSR/PAA Trainee",
        "PAA",
        "Special Loads/CLC",
        "Mechanic",
        "Contract Mechanic",
        "Supervisor",
        "Manager",
        "Administrator",
        "Scheduler",
        "Station Rep.",
        "Station Representative (Not W&B Qualified)",
        "Coordinator (Vendors)",
        "GCC",
        "Other",
      ],
      selectedJobTitles: [],
      showJobTitles: false,

      //---------------------------Aircraft
      createAircraft: false,
      readAircraft: false,
      updateAircraft: false,
      deleteAircraft: false,

      //---------------------------Airports
      createAirports: false,
      readAirports: false,
      updateAirports: false,
      deleteAirports: false,

      notificationReadLevel: "Employee",
      notificationReadLevelOptions: ["Employee", "Manager"],

      //---------------------------Bidlines
      createBidlines: false,
      readBidlines: false,
      updateBidlines: false,
      deleteBidlines: false,

      //---------------------------Employees
      createEmployees: false,
      readEmployees: false,
      updateEmployees: false,
      deleteEmployees: false,

      filterEmployeesByGateway: false,

      employeeReadLevel: "Employee",
      employeeReadLevelOptions: ["Employee", "Manager"],

      employeeUpdateLevel: "Employee",
      employeeUpdateLevelOptions: ["Employee", "Manager"],

      //---------------------------Flights
      createFlights: false,
      readFlights: false,
      updateFlights: false,
      deleteFlights: false,
      filterFlightsByGateway: false,
      getAircraftScheduleConflicts: true,

      //---------------------------Flight Assignments
      createFlightAssignments: false,
      readFlightAssignments: false,
      updateFlightAssignments: false,
      deleteFlightAssignments: false,
      flightAssignmentsOverrideHardWarnings: false,

      flightAssignmentReadLevel: "Employee",
      flightAssignmentReadLevelOptions: ["Employee", "Manager"],

      selectedFlightAssignmentCargoRoles: [],
      flightAssignmentCargoRoleOptions: [
        "Loadmaster",
        "Check Loadmaster",
        "Senior Loadmaster",
        "Loadmaster Training",
        "Mechanic",
        "Jumpseat",
      ],
      showFlightAssignmentsCargoRoles: false,

      selectedFlightAssignmentPassengerRoles: [],
      flightAssignmentPassengerRoleOptions: [
        "PSR",
        "Lead PSR",
        "Senior PSR",
        "Check PSR",
        "PSR Training",
        "PAA",
        "PAA Training",
        "POC",
        "GSC",
        "Mechanic",
        "Jumpseat",
      ],
      showFlightAssignmentsPassengerRoles: false,

      //---------------------------Ground Assignments
      createGroundAssignments: false,
      readGroundAssignments: false,
      updateGroundAssignments: false,
      deleteGroundAssignments: false,
      groundAssignmentsOverrideHardWarnings: false,

      groundAssignmentReadLevel: "Employee",
      groundAssignmentReadLevelOptions: ["Employee", "Manager"],

      selectedGroundAssignmentCargoRoles: [],
      groundAssignmentCargoRoleOptions: [
        "Loadmaster",
        "Check Loadmaster",
        "Senior Loadmaster",
        "Loadmaster Training",
        "Mechanic",
        "Station Rep.",
      ],
      showGroundAssignmentsCargoRoles: false,

      selectedGroundAssignmentPassengerRoles: [],
      groundAssignmentPassengerRoleOptions: [
        "PSR",
        "Lead PSR",
        "Senior PSR",
        "Check PSR",
        "PSR Training",
        "PAA",
        "PAA Training",
        "POC",
        "GSC",
        "Mechanic",
        "Station Rep.",
      ],
      showGroundAssignmentsPassengerRoles: false,

      //---------------------------Payroll Reports
      createPayrollReports: false,
      readPayrollReports: false,
      updatePayrollReports: false,
      deletePayrollReports: false,

      payrollReportCreateLevel: "Employee",
      payrollReportReadLevel: "Employee",
      payrollReportUpdateLevel: "Employee",
      payrollReportDeleteLevel: "Employee",

      payrollReportCreateLevelOptions: ["Employee", "Manager", "Administrator"],
      payrollReportReadLevelOptions: ["Employee", "Manager", "Administrator"],
      payrollReportUpdateLevelOptions: ["Employee", "Manager", "Administrator"],
      payrollReportDeleteLevelOptions: ["Employee", "Manager", "Administrator"],

      //---------------------------Security Groups
      createSecurityGroups: false,
      readSecurityGroups: true,
      updateSecurityGroups: false,
      deleteSecurityGroups: false,
    };
  },

  computed: {
    securityGroup() {
      const securityGroup = this.$store.getters.securityGroup;

      if (securityGroup) {
        return securityGroup;
      } else {
        const newSecurityGroup = {
          name: "New Group",
          companies: [],
          jobTitles: [],
          flightAssignmentCargoRoles: [],
          flightAssignmentPassengerRoles: [],
          groundAssignmentCargoRoles: [],
          groundAssignmentPassengerRoles: [],
          authorizations: {
            employee: {
              create: {
                enabled: false,
              },
              read: {
                enabled: true,
                level: "Employee",
                filterByGateway: true,
              },
              update: {
                enabled: false,
                level: "Employee",
                securityGroup: false,
              },
              delete: {
                enabled: false,
              },
            },
            flight: {
              create: {
                enabled: false,
              },
              read: {
                enabled: false,
                filterByGateway: true,
              },
              update: {
                enabled: false,
              },
              delete: {
                enabled: false,
              },
            },
            securityGroup: {
              create: {
                enabled: false,
              },
              read: {
                enabled: true,
              },
              update: {
                enabled: false,
              },
              delete: {
                enabled: false,
              },
            },
            airport: {
              create: {
                enabled: false,
              },
              read: {
                enabled: false,
              },
              update: {
                enabled: false,
              },
              delete: {
                enabled: false,
              },
            },
            aircraft: {
              create: {
                enabled: false,
              },
              read: {
                enabled: false,
              },
              update: {
                enabled: false,
              },
              delete: {
                enabled: false,
              },
            },
            flightAssignment: {
              create: {
                enabled: false,
              },
              read: {
                enabled: false,
                level: "Employee",
              },
              update: {
                enabled: false,
              },
              delete: {
                enabled: false,
              },
            },
            groundAssignment: {
              create: {
                enabled: false,
              },
              read: {
                enabled: false,
                level: "Employee",
              },
              update: {
                enabled: false,
              },
              delete: {
                enabled: false,
              },
            },
            assignmentNotification: {
              create: {
                enabled: false,
              },
              read: {
                enabled: false,
                level: "Employee",
              },
              update: {
                enabled: false,
              },
              delete: {
                enabled: false,
              },
            },
            bidline: {
              create: {
                enabled: false,
              },
              read: {
                enabled: false,
              },
              update: {
                enabled: false,
              },
              delete: {
                enabled: false,
              },
            },
            payrollReport: {
              create: {
                enabled: false,
                level: "Employee",
              },
              read: {
                enabled: false,
                level: "Employee",
              },
              update: {
                enabled: false,
                level: "Employee",
              },
              delete: {
                enabled: false,
                level: "Employee",
              },
            },
          },
        };

        return newSecurityGroup;
      }
    },
  },

  mounted() {
    this.$nextTick(function () {
      this.securityGroup_id = this.securityGroup._id
        ? this.securityGroup._id.slice()
        : null;

      this.securityGroupName = this.securityGroup.name.slice();

      this.selectedCompanies = [...this.securityGroup.companies];
      this.selectedJobTitles = [...this.securityGroup.jobTitles];

      //--------------------Aircraft
      this.createAircraft = false;
      this.readAircraft = this.securityGroup.authorizations.aircraft.read
        .enabled
        ? true
        : false;
      this.updateAircraft = this.securityGroup.authorizations.aircraft.update
        .enabled
        ? true
        : false;
      this.deleteAircraft = false;

      //------------------Airports
      this.createAirports = false;
      this.readAirports = this.securityGroup.authorizations.airport.read.enabled
        ? true
        : false;
      this.updateAirports = this.securityGroup.authorizations.airport.update
        .enabled
        ? true
        : false;
      this.deleteAirports = false;

      //-----------------Bidlines
      this.createBidlines = this.securityGroup.authorizations.bidline.create
        .enabled
        ? true
        : false;
      this.readBidlines = this.securityGroup.authorizations.bidline.read.enabled
        ? true
        : false;
      this.updateBidlines = this.securityGroup.authorizations.bidline.update
        .enabled
        ? true
        : false;
      this.deleteBidlines = this.securityGroup.authorizations.bidline.delete
        .enabled
        ? true
        : false;

      //-----------------Employees
      this.createEmployees = this.securityGroup.authorizations.employee.create
        .enabled
        ? true
        : false;
      this.readEmployees = this.securityGroup.authorizations.employee.read
        .enabled
        ? true
        : false;
      this.updateEmployees = this.securityGroup.authorizations.employee.update
        .enabled
        ? true
        : false;
      this.deleteEmployees = this.securityGroup.authorizations.employee.delete
        .enabled
        ? true
        : false;

      this.allowToChangeEmployeesSecurityGroup = this.securityGroup
        .authorizations.employee.update.securityGroup
        ? true
        : false;

      this.filterEmployeesByGateway = this.securityGroup.authorizations.employee
        .read.filterByGateway
        ? true
        : false;

      this.employeeReadLevel =
        this.securityGroup.authorizations.employee.read.level;

      this.employeeUpdateLevel =
        this.securityGroup.authorizations.employee.update.level;

      //--------------------Flights
      this.createFlights = false;
      this.readFlights = this.securityGroup.authorizations.flight.read.enabled
        ? true
        : false;
      this.updateFlights = this.securityGroup.authorizations.flight.update
        .enabled
        ? true
        : false;
      this.deleteFlights = false;

      this.filterFlightsByGateway = this.securityGroup.authorizations.flight
        .read.filterByGateway
        ? true
        : false;
      this.getAircraftScheduleConflicts = this.securityGroup.authorizations
        .flight.read.getAircraftScheduleConflicts
        ? true
        : false;

      //--------------------Flight Assignments
      this.createFlightAssignments = this.securityGroup.authorizations
        .flightAssignment.create.enabled
        ? true
        : false;
      this.readFlightAssignments = this.securityGroup.authorizations
        .flightAssignment.read.enabled
        ? true
        : false;
      this.updateFlightAssignments = this.securityGroup.authorizations
        .flightAssignment.update.enabled
        ? true
        : false;
      this.deleteFlightAssignments = this.securityGroup.authorizations
        .flightAssignment.delete.enabled
        ? true
        : false;

      this.flightAssignmentsOverrideHardWarnings =
        this.securityGroup.authorizations.flightAssignment.overrideWarnings;

      this.flightAssignmentReadLevel =
        this.securityGroup.authorizations.flightAssignment.read.level;

      this.selectedFlightAssignmentCargoRoles = [
        ...this.securityGroup.flightAssignmentCargoRoles,
      ];
      this.selectedFlightAssignmentPassengerRoles = [
        ...this.securityGroup.flightAssignmentPassengerRoles,
      ];

      //--------------------Ground Assignments
      this.createGroundAssignments = this.securityGroup.authorizations
        .groundAssignment.create.enabled
        ? true
        : false;
      this.readGroundAssignments = this.securityGroup.authorizations
        .groundAssignment.read.enabled
        ? true
        : false;
      this.updateGroundAssignments = this.securityGroup.authorizations
        .groundAssignment.update.enabled
        ? true
        : false;
      this.deleteGroundAssignments = this.securityGroup.authorizations
        .groundAssignment.delete.enabled
        ? true
        : false;
      this.groundAssignmentsOverrideHardWarnings =
        this.securityGroup.authorizations.groundAssignment.overrideWarnings;

      this.groundAssignmentReadLevel =
        this.securityGroup.authorizations.groundAssignment.read.level;

      this.selectedGroundAssignmentCargoRoles = [
        ...this.securityGroup.groundAssignmentCargoRoles,
      ];
      this.selectedGroundAssignmentPassengerRoles = [
        ...this.securityGroup.groundAssignmentPassengerRoles,
      ];

      //-----------------------Payroll Reports
      this.createPayrollReports = this.securityGroup.authorizations
        .payrollReport.create.enabled
        ? true
        : false;
      this.readPayrollReports = this.securityGroup.authorizations.payrollReport
        .read.enabled
        ? true
        : false;
      this.updatePayrollReports = this.securityGroup.authorizations
        .payrollReport.update.enabled
        ? true
        : false;
      this.deletePayrollReports = this.securityGroup.authorizations
        .payrollReport.delete.enabled
        ? true
        : false;

      this.payrollReportCreateLevel =
        this.securityGroup.authorizations.payrollReport.create.level;
      this.payrollReportReadLevel =
        this.securityGroup.authorizations.payrollReport.read.level;
      this.payrollReportUpdateLevel =
        this.securityGroup.authorizations.payrollReport.update.level;
      this.payrollReportDeleteLevel =
        this.securityGroup.authorizations.payrollReport.delete.level;

      //--------------------Security Groups
      this.createSecurityGroups = this.securityGroup.authorizations
        .securityGroup.create.enabled
        ? true
        : false;
      this.readSecurityGroups = true;
      this.updateSecurityGroups = this.securityGroup.authorizations
        .securityGroup.update.enabled
        ? true
        : false;
      this.deleteSecurityGroups = this.securityGroup.authorizations
        .securityGroup.delete.enabled
        ? true
        : false;
    });
  },

  methods: {
    handleGoBack() {
      this.$router.go(-1);
    },

    async handleSaveSecurityGroup(isClone) {
      if (
        isClone &&
        this.securityGroup.name.toUpperCase() ===
          this.securityGroupName.toUpperCase()
      ) {
        this.snackbarColor = "red";
        this.snackbarText =
          "Security Group name must be unique. Please enter a different name for this group.";
        this.snackbarVisible = true;
        return;
      }

      this.loading = true;

      const securityGroup = {
        name: this.securityGroupName.slice(),
        companies: [...this.selectedCompanies],
        flightAssignmentCargoRoles: [
          ...this.selectedFlightAssignmentCargoRoles,
        ],
        flightAssignmentPassengerRoles: [
          ...this.selectedFlightAssignmentPassengerRoles,
        ],
        groundAssignmentCargoRoles: [
          ...this.selectedGroundAssignmentCargoRoles,
        ],
        groundAssignmentPassengerRoles: [
          ...this.selectedGroundAssignmentPassengerRoles,
        ],
        jobTitles: [...this.selectedJobTitles],
        authorizations: {
          aircraft: {
            create: {
              enabled: false,
            },
            read: {
              enabled: this.readAircraft ? true : false,
            },
            update: {
              enabled: this.updateAircraft ? true : false,
            },
            delete: {
              enabled: false,
            },
          },
          airport: {
            create: {
              enabled: false,
            },
            read: {
              enabled: this.readAirports ? true : false,
            },
            update: {
              enabled: this.updateAirports ? true : false,
            },
            delete: {
              enabled: false,
            },
          },
          employee: {
            create: {
              enabled: this.createEmployees ? true : false,
            },
            read: {
              enabled: this.readEmployees ? true : false,
              level: this.employeeReadLevel.slice(),
              filterByGateway: this.filterEmployeesByGateway ? true : false,
            },
            update: {
              enabled: this.updateEmployees ? true : false,
              level: this.employeeUpdateLevel.slice(),
              securityGroup: this.allowToChangeEmployeesSecurityGroup
                ? true
                : false,
            },
            delete: {
              enabled: this.deleteEmployees ? true : false,
            },
          },
          flight: {
            create: { enabled: false },
            read: {
              enabled: this.readFlights ? true : false,
              filterByGateway: this.filterFlightsByGateway ? true : false,
              getAircraftScheduleConflicts: this.getAircraftScheduleConflicts
                ? true
                : false,
            },
            update: {
              enabled: this.updateFlights ? true : false,
            },
            delete: { enabled: false },
          },
          securityGroup: {
            create: {
              enabled: this.createSecurityGroups ? true : false,
            },
            read: {
              enabled: true,
            },
            update: {
              enabled: this.updateSecurityGroups ? true : false,
            },
            delete: {
              enabled: this.deleteSecurityGroups ? true : false,
            },
          },
          flightAssignment: {
            create: {
              enabled: this.createFlightAssignments ? true : false,
            },
            read: {
              enabled: this.readFlightAssignments ? true : false,
              level: this.flightAssignmentReadLevel.slice(),
            },
            update: {
              enabled: this.updateFlightAssignments ? true : false,
            },
            delete: {
              enabled: this.deleteFlightAssignments ? true : false,
            },
            overrideWarnings: this.flightAssignmentsOverrideHardWarnings
              ? true
              : false,
          },
          groundAssignment: {
            create: {
              enabled: this.createGroundAssignments ? true : false,
            },
            read: {
              enabled: this.readGroundAssignments ? true : false,
              level: this.groundAssignmentReadLevel.slice(),
            },
            update: {
              enabled: this.updateGroundAssignments ? true : false,
            },
            delete: {
              enabled: this.deleteGroundAssignments ? true : false,
            },
            overrideWarnings: this.groundAssignmentsOverrideHardWarnings
              ? true
              : false,
          },
          assignmentNotification: {
            create: {
              enabled: this.createNotifications ? true : false,
            },
            read: {
              enabled: this.readNotifications ? true : false,
              level: this.notificationReadLevel.slice(),
            },
            update: {
              enabled: this.updateNotifications ? true : false,
            },
            delete: {
              enabled: this.deleteNotifications ? true : false,
            },
          },
          bidline: {
            create: {
              enabled: this.createBidlines ? true : false,
            },
            read: {
              enabled: this.readBidlines ? true : false,
            },
            update: {
              enabled: this.updateBidlines ? true : false,
            },
            delete: {
              enabled: this.deleteBidlines ? true : false,
            },
          },
          payrollReport: {
            create: {
              enabled: this.createPayrollReports ? true : false,
              level: this.payrollReportCreateLevel.slice(),
            },
            read: {
              enabled: this.readPayrollReports ? true : false,
              level: this.payrollReportReadLevel.slice(),
            },
            update: {
              enabled: this.updatePayrollReports ? true : false,
              level: this.payrollReportUpdateLevel.slice(),
            },
            delete: {
              enabled: this.deletePayrollReports ? true : false,
              level: this.payrollReportDeleteLevel.slice(),
            },
          },
        },
      };

      try {
        const token = await aad.getIdTokenSilent();
        let res;

        if (isClone) {
          res = await api.post(
            `/security-groups`,
            {
              securityGroup,
            },
            {
              headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
            }
          );
        } else {
          if (this.securityGroup_id) {
            res = await api.put(
              `/security-groups/${this.securityGroup_id}`,
              {
                securityGroup,
              },
              {
                headers: {
                  Authorization: "Bearer " + token.idToken.rawIdToken,
                },
              }
            );
          } else {
            res = await api.post(
              `/security-groups`,
              {
                securityGroup,
              },
              {
                headers: {
                  Authorization: "Bearer " + token.idToken.rawIdToken,
                },
              }
            );
          }
        }

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";

          if (res.message.error.code === 11000) {
            this.snackbarText =
              "Security Group name must be unique. Please enter a different name for this group.";
          } else {
            this.snackbarText = res.message;
          }

          this.snackbarVisible = true;

          return;
        }

        this.handleGoBack();
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async handleCloneSecurityGroup() {
      this.loading = true;

      const securityGroup = {
        name: this.securityGroupName.slice(),
        companies: [...this.selectedCompanies],
        flightAssignmentCargoRoles: [
          ...this.selectedFlightAssignmentCargoRoles,
        ],
        flightAssignmentPassengerRoles: [
          ...this.selectedFlightAssignmentPassengerRoles,
        ],
        groundAssignmentCargoRoles: [
          ...this.selectedGroundAssignmentCargoRoles,
        ],
        groundAssignmentPassengerRoles: [
          ...this.selectedGroundAssignmentPassengerRoles,
        ],
        jobTitles: [...this.selectedJobTitles],
        authorizations: {
          aircraft: {
            create: {
              enabled: false,
            },
            read: {
              enabled: this.readAircraft ? true : false,
            },
            update: {
              enabled: this.updateAircraft ? true : false,
            },
            delete: {
              enabled: false,
            },
          },
          airport: {
            create: {
              enabled: false,
            },
            read: {
              enabled: this.readAirports ? true : false,
            },
            update: {
              enabled: this.updateAirports ? true : false,
            },
            delete: {
              enabled: false,
            },
          },
          employee: {
            create: {
              enabled: this.createEmployees ? true : false,
            },
            read: {
              enabled: this.readEmployees ? true : false,
              level: this.employeeReadLevel.slice(),
              filterByGateway: this.filterEmployeesByGateway ? true : false,
            },
            update: {
              enabled: this.updateEmployees ? true : false,
              level: this.employeeUpdateLevel.slice(),
            },
            delete: {
              enabled: this.deleteEmployees ? true : false,
            },
          },
          flight: {
            create: { enabled: false },
            read: {
              enabled: this.readFlights ? true : false,
              filterByGateway: this.filterFlightsByGateway ? true : false,
              getAircraftScheduleConflicts: this.getAircraftScheduleConflicts
                ? true
                : false,
            },
            update: {
              enabled: this.updateFlights ? true : false,
            },
            delete: { enabled: false },
          },
          securityGroup: {
            create: {
              enabled: this.createSecurityGroups ? true : false,
            },
            read: {
              enabled: this.readSecurityGroups ? true : false,
            },
            update: {
              enabled: this.updateSecurityGroups ? true : false,
            },
            delete: {
              enabled: this.deleteSecurityGroups ? true : false,
            },
          },
          flightAssignment: {
            create: {
              enabled: this.createFlightAssignments ? true : false,
            },
            read: {
              enabled: this.readFlightAssignments ? true : false,
              level: this.flightAssignmentReadLevel.slice(),
            },
            update: {
              enabled: this.updateFlightAssignments ? true : false,
            },
            delete: {
              enabled: this.deleteFlightAssignments ? true : false,
            },
            overrideWarnings: this.flightAssignmentsOverrideHardWarnings
              ? true
              : false,
          },
          groundAssignment: {
            create: {
              enabled: this.createGroundAssignments ? true : false,
            },
            read: {
              enabled: this.readGroundAssignments ? true : false,
              level: this.groundAssignmentReadLevel.slice(),
            },
            update: {
              enabled: this.updateGroundAssignments ? true : false,
            },
            delete: {
              enabled: this.deleteGroundAssignments ? true : false,
            },
            overrideWarnings: this.groundAssignmentsOverrideHardWarnings
              ? true
              : false,
          },
          assignmentNotification: {
            create: {
              enabled: this.createNotifications ? true : false,
            },
            read: {
              enabled: this.readNotifications ? true : false,
              level: this.notificationReadLevel.slice(),
            },
            update: {
              enabled: this.updateNotifications ? true : false,
            },
            delete: {
              enabled: this.deleteNotifications ? true : false,
            },
          },
          bidline: {
            create: {
              enabled: this.createBidlines ? true : false,
            },
            read: {
              enabled: this.readBidlines ? true : false,
            },
            update: {
              enabled: this.updateBidlines ? true : false,
            },
            delete: {
              enabled: this.deleteBidlines ? true : false,
            },
          },
          payrollReport: {
            create: {
              enabled: this.createPayrollReports ? true : false,
              level: this.payrollReportCreateLevel.slice(),
            },
            read: {
              enabled: this.readPayrollReports ? true : false,
              level: this.payrollReportReadLevel.slice(),
            },
            update: {
              enabled: this.updatePayrollReports ? true : false,
              level: this.payrollReportUpdateLevel.slice(),
            },
            delete: {
              enabled: this.deletePayrollReports ? true : false,
              level: this.payrollReportDeleteLevel.slice(),
            },
          },
        },
      };

      try {
        const token = await aad.getIdTokenSilent();
        const res = await api.post(
          `/security-groups`,
          {
            securityGroup,
          },
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.handleGoBack();
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async handleDeleteSecurityGroup() {
      this.loading = true;

      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.delete(
          `/security-groups/${this.securityGroup_id}`,
          {
            headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
          }
        );
        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.handleGoBack();
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      } finally {
        this.editing = false;
        this.confirmDelete = false;
      }
    },
  },
};
</script>

<style scoped></style>
