<template>
  <div class="main-frame">
    <div
      class="top-frame"
      :class="{
        'unscheduled-event': flight.unscheduledEvent !== 'None',
      }"
    >
      {{ flight.flightNumber }}
    </div>

    <div
      :style="cLabelStyle"
      :class="{
        'unscheduled-event': flight.unscheduledEvent !== 'None',
      }"
    >
      <div :class="{ 'missing-crew': missingCrew }"></div>

      <div class="text-sm text-center overflow-x-hidden whitespace-nowrap py-1">
        {{ `${flight.origin.iata}-${flight.destination.iata}` }}
      </div>
    </div>
    <div class="bottom-frame" :class="{ 'local-time': localTime }">
      <div>
        {{ formatTime(flight._start, flight.origin.timeZone).substring(0, 5) }}
      </div>
      <div>
        {{
          formatTime(flight._end, flight.destination.timeZone).substring(0, 5)
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { mixin } from "../mixins/mixin";
export default {
  name: "flightTile",
  props: ["flight"],
  mixins: [mixin],
  computed: {
    localTime() {
      return this.$store.state.localTime;
    },

    cLabelStyle() {
      let backgroundColor = "var(--scheduled)";
      let borderTop = "1px solid var(--gray)";
      let borderRight = "1px solid var(--gray)";
      let borderBottom = "1px solid var(--gray)";
      let borderLeft = "1px solid var(--gray)";

      //--scheduled: #ffe4c4;
      //--delayed: #fcfad4;
      //--blocked-out: #5cfa5c;
      //--airborne: #b0e9fc;
      //--blocked-in: #93c3c7;

      switch (this.flight.status) {
        case "BLOCKED-OUT":
          backgroundColor = "var(--blocked-out)";
          if (this.wasLate) {
            borderLeft = "4px solid #FF0000";
          }
          break;
        case "AIRBORNE":
          backgroundColor = "var(--airborne)";
          if (this.wasLate) {
            borderLeft = "4px solid #FF0000";
          }
          break;
        case "BLOCKED-IN":
          backgroundColor = "var(--blocked-in)";
          if (this.wasLate) {
            borderLeft = "4px solid #FF0000";
          }
          break;
        default: //SCHEDULED
          if (this.isDelayed) {
            backgroundColor = "var(--delayed)";
          }

          break;
      }

      switch (this.flight.operatingRules) {
        case "Part 91":
          borderBottom = "4px solid #0000FF";
          break;
        case "Part 121":
          borderBottom = "4px solid #0000FF";
          break;
        case "Ferry":
          borderBottom = "4px solid #000";
          break;
        case "MX Ferry":
          borderBottom = "4px solid #FF0000";
          break;
        default:
          break;
      }

      return {
        backgroundColor,
        borderTop,
        borderRight,
        borderBottom,
        borderLeft,
      };
    },

    missingCrew() {
      let m = 0;
      this.flight.crewmembers.forEach((item) => {
        if (item.required && !item.employee) {
          m++;
        }
      });

      return m > 0;
    },

    isDelayed() {
      const t = new Date().getTime() - new Date(this.flight._std).getTime();
      return t > 15 * 60 * 1000;
    },

    wasLate() {
      const t =
        new Date(this.flight._start).getTime() -
        new Date(this.flight._std).getTime();
      return t > 15 * 60 * 1000;
    },
  },
};
</script>

<style scoped>
.main-frame {
  position: absolute;
  width: 100%;
  top: 0;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
}

.top-frame {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px;
  text-align: start;
}

.bottom-frame {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bottom-frame div {
  font-size: 10px;
}

.missing-crew {
  position: absolute;
  border-top: 6px solid red;
  border-right: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid red;
}

/* .bottom-frame-out {
  position: absolute;
  left: 0;
  font-size: 10px;
  background-color: #fff;
}

.bottom-frame-in {
  position: absolute;
  right: 0;
  font-size: 10px;
  background-color: #fff;
} */

.unscheduled-event {
  color: var(--red);
}

.local-times {
  color: var(--red);
}

/* Tooltip container */

/* Tooltip text */
.main-frame .tooltiptext {
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.65);
  width: 250px;
  color: #fff;
  text-align: start;

  padding: 5px;
  border-radius: 2px;

  /* Position the tooltip text */
  position: absolute;
  top: 52px;
  left: 5px;
  z-index: 2;
}

.main-frame .tooltiptext div {
  color: #fff;
  font-size: 0.85rem;
}

.main-frame .tooltiptext h3 {
  color: #fff;
  font-size: 0.85rem;
}

/* Show the tooltip text when you mouse over the tooltip container */
.main-frame:hover .tooltiptext {
  visibility: visible;
}
</style>
