<template>
  <div>
    <!-- #region NAVBAR -->
    <nav>
      <ul class="nav-wrapper">
        <li class="icon" @click="handleGoBack">
          <a href="#">
            <span class="tooltip">Go Back</span>
            <span><i class="ml-3 fa-solid fa-arrow-left text-white"> </i></span>
          </a>
        </li>

        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white ml-3"> </i></span
          ></RouterLink>
        </li>
        <li>
          <a href="#">
            <div class="ml-5 text-base lg:text-2xl text-white">
              {{ `${flightNumber} ${originIata} - ${destinationIata}` }}
            </div>
          </a>
        </li>

        <li>
          <a href="#"> </a>
        </li>
      </ul>
    </nav>
    <!-- #endregion -->
    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <!-- LOADING -->

    <r-spinner v-if="loading"> </r-spinner>

    <!-- COMPUTED TIMES ERRORS -->
    <r-modal
      v-if="showComputedTimesErrorsDialog"
      @close="showComputedTimesErrorsDialog = false"
    >
      <div v-if="showComputedTimesErrorsDialog" class="p-5">
        <div class="flex justify-between mb-2">
          <div class="text-2xl font-bold">Fix the folowing errors</div>
          <button
            @click="showComputedTimesErrorsDialog = false"
            class="btn-icon"
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div v-if="!computedOrigin">
          Enter a valid IATA code for the departure airport.
        </div>
        <div v-if="!computedDestination">
          Enter a valid IATA code for the arrival airport.
        </div>

        <div v-for="(error, index) in computedTimesErrors" :key="index">
          {{ error }}
        </div>

        <div class="flex justify-end mt-1">
          <button
            @click="showComputedTimesErrorsDialog = false"
            class="btn bg-gray-500 text-white"
          >
            Close
          </button>
        </div>
      </div>
    </r-modal>

    <!-- FUEL -->
    <r-modal v-if="showFuelDialog" @close="showFuelDialog = false">
      <div v-if="showFuelDialog" class="p-5">
        <div class="flex justify-between mb-2">
          <div class="text-2xl font-bold">Fuel</div>
          <button @click="showFuelDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="mb-2">
          <p class="ml-2 text-sm text-gray-500">Vendor</p>

          <input
            class="block rounded-md border-0 w-full py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm"
            v-model="fuelTicketVendor"
            type="text"
            maxlength="40"
            name="fuelVendorName"
            id="fuelVendorName"
            placeholder="Fuel Vendor Name"
          />
        </div>

        <div class="mb-2">
          <p class="ml-2 text-sm text-gray-500">Invoice Number</p>

          <input
            class="block rounded-md border-0 w-full py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm uppercase"
            v-model="fuelTicketInvoiceNumber"
            type="text"
            maxlength="40"
            name="fuelTicketNumber"
            id="fuelTicketNumber"
            placeholder="Invoice Number"
          />
        </div>

        <div class="flex justify-end items-center mb-5">
          <div class="text-2xl text-gray-500 mr-3">US Gallons</div>
          <input
            class="block py-1.5 w-[150px] rounded-md border-0 px-3 text-center text-2xl text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600"
            type="number"
            step="100"
            min="0"
            max="100000"
            v-model="fuelTicketQuantity"
          />

          <button
            @click="handleAddFuelTicket"
            class="ml-2 btn bg-blue-500 text-white"
            :class="{ disabled: !isValidFuelTicket }"
          >
            Add
          </button>
        </div>

        <div v-if="fuelTicketList.length" class="border border-gray-600">
          <!-- Data Table -->

          <table
            class="table-auto w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
          >
            <thead
              class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
            >
              <tr>
                <th scope="col" class="px-6 w-1/2 py-2 cursor-pointer">
                  Vendor
                </th>
                <th scope="col" class="px-6 cursor-pointer">Invoice Number</th>
                <th scope="col" class="px-6 w-[150px] py-2 cursor-pointer">
                  US Gallons
                </th>
                <th scope="col" class="px-6 w-[10px] py-2 cursor-pointer">
                  Remove
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in fuelTicketList"
                :key="item.invoiceNumber"
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <td
                  scope="row"
                  class="px-6 py-1.5 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {{ item.vendor }}
                </td>
                <td>{{ item.invoiceNumber }}</td>
                <td class="px-6 py-1.5">
                  {{ `${formatQuantity(item.quantity)}` }}
                </td>
                <td
                  class="px-6 py-1.5"
                  @click="handleRemoveFuelTicket(item.invoiceNumber)"
                >
                  <i class="fa-solid fa-trash-can"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-else
          class="text-gray-500 border border-gray-400 rounded-md text-base p-2"
        >
          No fuel tickets.
        </div>

        <div class="flex justify-end p-2">
          <button
            @click="showFuelDialog = false"
            class="btn bg-gray-500 text-white"
          >
            Cancel
          </button>
          <button
            @click="handleUpdateFuel"
            class="ml-1 btn bg-green-500 text-white"
            :class="{ disabled: !fuelTicketList.length }"
          >
            Save
          </button>
        </div>
      </div>
    </r-modal>

    <!-- FLIGHT INFORMATION -->
    <r-modal
      v-if="showFlightInformationDialog"
      @close="showFlightInformationDialog = false"
    >
      <div v-if="showFlightInformationDialog" class="p-5">
        <div class="flex justify-between mb-2">
          <div class="text-2xl font-bold">Flight Information</div>
          <button @click="showFlightInformationDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div>
          <span>Sched. Departure:</span
          ><span class="font-bold ml-1 text-blue-700">{{
            `${formatDateTime(this._std, "UTC")}`
          }}</span>
        </div>
        <div>
          <span>Sched. Arrival:</span
          ><span class="font-bold ml-1 text-blue-700">{{
            `${formatDateTime(this._sta, "UTC")}`
          }}</span>
        </div>
        <div>
          <span>Last update:</span
          ><span class="font-bold ml-1 text-blue-700">{{
            `${formatDateTime(this.updatedAt)}`
          }}</span>
        </div>

        <div class="flex justify-end p-2">
          <button
            @click="showFlightInformationDialog = false"
            class="btn bg-gray-500 text-white"
          >
            Close
          </button>
        </div>
      </div>
    </r-modal>

    <!-- PAXS -->
    <r-modal v-if="showPaxsDialog" @close="showPaxsDialog = false">
      <div v-if="showPaxsDialog" class="p-5">
        <div class="flex justify-between mb-2">
          <div class="text-2xl font-bold">Passengers</div>
          <button @click="showPaxsDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="flex justify-between px-10 mb-5">
          <!-- BOOKED -->
          <div class="flex gap-5">
            <div>
              <p class="ml-2 text-gray-500">Booked Adults</p>
              <input
                class="block py-1.5 w-[150px] rounded-md border-0 px-3 text-center text-2xl text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600"
                type="number"
                step="1"
                min="0"
                max="200"
                v-model="bookedPaxAdultsInput"
                :class="{
                  'is-invalid': !isValidBookedPaxCount,
                }"
              />
            </div>

            <div>
              <p class="ml-2 text-gray-500">Booked Infants</p>
              <input
                class="block py-1.5 w-[150px] rounded-md border-0 px-3 text-center text-2xl text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600"
                type="number"
                step="1"
                min="0"
                max="200"
                v-model="bookedPaxInfantsInput"
              />
            </div>
          </div>

          <!-- ACTUAL -->
          <div class="flex gap-5">
            <div>
              <p class="ml-2 text-gray-500">Actual Adults</p>
              <input
                class="block py-1.5 w-[150px] rounded-md border-0 px-3 text-center text-2xl text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600"
                type="number"
                step="1"
                min="0"
                max="200"
                v-model="actualPaxAdultsInput"
                :class="{
                  'is-invalid': !isValidActualPaxCount,
                }"
              />
            </div>

            <div>
              <p class="ml-2 text-gray-500">Actual Infants</p>
              <input
                class="block py-1.5 w-[150px] rounded-md border-0 px-3 text-center text-2xl text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600"
                type="number"
                step="1"
                min="0"
                max="200"
                v-model="actualPaxInfantsInput"
              />
            </div>
          </div>
        </div>

        <div class="flex justify-end p-2">
          <button
            @click="showPaxsDialog = false"
            class="btn bg-gray-500 text-white"
          >
            Cancel
          </button>
          <button
            @click="handleUpdatePaxs"
            class="ml-1 btn bg-green-500 text-white"
            :class="{
              disabled: !isValidBookedPaxCount || !isValidActualPaxCount,
            }"
          >
            Save
          </button>
        </div>
      </div>
    </r-modal>

    <!-- DELAYS -->
    <r-modal v-if="showDelaysDialog" @close="showDelaysDialog = false">
      <div v-if="showDelaysDialog" class="p-5">
        <div class="flex justify-between mb-2">
          <div class="text-2xl font-bold">Delays</div>
          <button @click="showDelaysDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="flex items-center">
          <p class="mr-2 text-sm text-gray-500">Select</p>
          <select
            class="block py-1.5 w-full rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
            v-model="selectedDelay"
          >
            <option v-for="item in delayOptions" :key="item.code" :value="item">
              {{ item.descriptionShort }}
            </option>
          </select>

          <button
            @click="handleAddDelay"
            class="ml-1 btn bg-blue-500 text-white"
          >
            Add
          </button>
        </div>

        <div v-if="delayList.length" class="border border-gray-600 my-3">
          <!-- Data Table -->

          <table
            class="table-auto w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
          >
            <thead
              class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
            >
              <tr>
                <th scope="col" class="px-6 w-[50px] py-2 cursor-pointer">
                  Code
                </th>
                <th scope="col" class="px-6 w-[200px] cursor-pointer">
                  Description
                </th>
                <th scope="col" class="px-6 py-2 cursor-pointer">Details</th>
                <th scope="col" class="px-6 w-[10px] py-2 cursor-pointer">
                  Remove
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in delayList"
                :key="item.code"
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <td
                  scope="row"
                  class="px-6 py-1.5 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {{ item.code }}
                </td>
                <td>{{ item.descriptionShort }}</td>
                <td class="px-6 py-1.5">
                  {{ item.descriptionLong }}
                </td>
                <td class="px-6 py-1.5" @click="handleRemoveDelay(item.code)">
                  <i class="fa-solid fa-trash-can"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-else
          class="text-gray-500 border border-gray-400 rounded-md text-base p-2"
        >
          No delays.
        </div>

        <div class="flex justify-end p-2">
          <button
            @click="showDelaysDialog = false"
            class="btn bg-gray-500 text-white"
          >
            Cancel
          </button>
          <button
            @click="handleUpdateDelays"
            class="ml-1 btn bg-green-500 text-white"
          >
            Save
          </button>
        </div>
      </div>
    </r-modal>

    <div v-else>
      <div class="p-5">
        <div class="flex gap-2 flex-wrap">
          <div>
            <p class="ml-2 text-sm text-gray-500">Customer</p>
            <select
              class="block py-1.5 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="selectedCustomer"
            >
              <option
                v-for="item in customerOptions"
                :key="item._id"
                :value="item"
              >
                {{ item.name }}
              </option>
            </select>
          </div>

          <div>
            <p class="ml-2 text-sm text-gray-500">Aircraft</p>
            <select
              class="block py-1.5 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="selectedAircraft"
            >
              <option
                v-for="item in aircraftOptions"
                :key="item._id"
                :value="item"
              >
                {{ item.registration }}
              </option>
            </select>
          </div>

          <div>
            <p class="ml-2 text-sm text-gray-500">Status</p>
            <select
              class="block py-1.5 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="selectedStatus"
              @change="handleUpdateStatus"
            >
              <option
                v-for="(item, index) in statusOptions"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>

          <div>
            <p class="ml-2 text-sm text-gray-500">Flight Rules</p>
            <select
              class="block py-1.5 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="selectedFlightRules"
            >
              <option
                v-for="item in flightRulesOptions"
                :key="item._id"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>

          <div>
            <p class="ml-2 text-sm text-gray-500">Log Page #</p>

            <input
              class="block rounded-md border-0 max-w-[120px] py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm uppercase"
              v-model="logpage"
              type="text"
              maxlength="10"
              name="logpage"
              id="logpage"
              placeholder="Log Page #"
            />
          </div>

          <div
            class="ml-5 cursor-pointer"
            @click="showFlightInformationDialog = true"
          >
            <i class="fa-solid fa-circle-info text-blue-500"></i>
          </div>
        </div>

        <div class="flex gap-2 flex-wrap">
          <div>
            <p class="ml-2 text-sm text-gray-500">Date</p>
            <input
              class="block rounded-md border-0 max-w-[150px] py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm uppercase"
              v-model="outDateString"
              type="date"
            />
          </div>

          <div>
            <p class="ml-2 text-sm text-gray-500">Flight Number</p>

            <input
              class="block rounded-md border-0 max-w-[120px] py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm uppercase"
              :class="{ 'is-invalid': !isValidCallsign }"
              v-model="flightNumber"
              type="text"
              maxlength="10"
              name="flightNumber"
              id="flightNumber"
              placeholder="Flight No."
            />
          </div>

          <div>
            <p class="ml-2 text-sm text-gray-500">From</p>

            <input
              class="block rounded-md border-0 max-w-[80px] py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm uppercase"
              :class="{ 'is-invalid': !computedOrigin }"
              disabled="true"
              v-model="originIata"
              type="text"
              maxlength="3"
              name="originIata"
              id="originIata"
              placeholder="IATA"
            />
          </div>

          <div>
            <p class="ml-2 text-sm text-gray-500">To</p>
            <input
              class="block rounded-md border-0 max-w-[80px] py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm uppercase"
              :class="{ 'is-invalid': !computedDestination }"
              v-model="destinationIata"
              type="text"
              maxlength="3"
              name="destinationIata"
              id="destinationIata"
              placeholder="IATA"
            />
          </div>

          <div>
            <p class="ml-5 text-sm text-gray-500">{{ outDynamicLabel }}</p>
            <input
              class="block rounded-md border-0 py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm"
              v-model="outTimeString"
              type="time"
            />
          </div>

          <div>
            <p class="ml-5 text-sm text-gray-500">{{ offDynamicLabel }}</p>
            <input
              class="block rounded-md border-0 py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm"
              v-model="offTimeString"
              :disabled="!enableOffTime"
              type="time"
            />
          </div>

          <div>
            <p class="ml-5 text-sm text-gray-500">{{ onDynamicLabel }}</p>
            <input
              class="block rounded-md border-0 py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm"
              v-model="onTimeString"
              :disabled="!enableOnTime"
              type="time"
            />
          </div>

          <div>
            <p class="ml-5 text-sm text-gray-500">{{ inDynamicLabel }}</p>
            <input
              class="block rounded-md border-0 py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm"
              v-model="inTimeString"
              type="time"
            />
          </div>

          <div>
            <p class="ml-5 text-sm text-gray-500">Flight</p>
            <div
              class="block rounded-md border-0 py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 uppercase"
            >
              <div v-if="computedTimes.isValid">
                {{ computedTimes.flight.text }}
              </div>
              <div v-else class="text-red-500">INVALID</div>
            </div>
          </div>

          <!-- Block Time -->
          <div>
            <p class="ml-5 text-sm text-gray-500">Block</p>
            <div
              class="block rounded-md border-0 py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 uppercase"
            >
              <div v-if="computedTimes.isValid">
                {{ computedTimes.block.text }}
              </div>
              <div v-else class="text-red-500">INVALID</div>
            </div>
          </div>

          <!-- Departure Fuel -->
          <div>
            <p class="ml-2 text-sm text-gray-500">Dep. Fuel</p>
            <input
              class="block py-1.5 w-[100px] rounded-md border-0 text-center text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              :class="{ 'is-invalid': !isValidDepartureFuel }"
              type="number"
              step="1000"
              min="0"
              max="100000"
              v-model="departureFuel"
            />
          </div>

          <!-- Arrival Fuel -->
          <div>
            <p class="ml-2 text-sm text-gray-500">Arr. Fuel</p>
            <input
              class="block py-1.5 w-[100px] rounded-md border-0 text-center text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              :class="{ 'is-invalid': !isValidArrivalFuel }"
              :disabled="!enableFuelRemaining"
              type="number"
              step="1000"
              min="0"
              max="100000"
              v-model="arrivalFuel"
            />
          </div>

          <!-- Uplift -->
          <div>
            <p class="ml-2 text-sm text-gray-500">Uplift (Gals)</p>
            <div
              class="block rounded-md border-0 w-[100px] py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 uppercase cursor-pointer"
              :class="{ disabled: !enableFuelUplift }"
              @click="handleShowFuelDialog"
            >
              <div>
                {{ formatQuantity(fuelUplift) }}
              </div>
            </div>
          </div>

          <!-- Pax. Booked -->
          <div>
            <p class="ml-2 text-sm text-gray-500">Pax. Booked</p>
            <div
              class="block rounded-md border-0 w-[100px] py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 uppercase cursor-pointer"
              @click="handleShowPaxsDialog"
            >
              <div>
                {{ formatPaxsBooked() }}
              </div>
            </div>
          </div>

          <!-- Pax. Actual -->
          <div>
            <p class="ml-2 text-sm text-gray-500">Pax. Actual</p>
            <div
              class="block rounded-md border-0 w-[100px] py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 uppercase cursor-pointer"
              @click="handleShowPaxsDialog"
            >
              <div>
                {{ formatPaxsActual() }}
              </div>
            </div>
          </div>
        </div>

        <div class="flex gap-5 flex-wrap items-center">
          <!-- Delay Codes -->
          <div>
            <p class="ml-2 text-sm text-gray-500">Delay Code(s)</p>
            <div
              class="block rounded-md border-0 w-[300px] py-1.5 pl-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
              @click="handleShowDelaysDialog"
            >
              <div>
                {{ formatDelayCodes() }}
              </div>
            </div>
          </div>

          <!-- Parking Departure -->
          <div class="mb-2">
            <p class="ml-2 text-sm text-gray-500">Gate Dep.</p>

            <input
              class="block rounded-md border-0 w-[80px] py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm uppercase"
              v-model="parkingDeparture"
              type="text"
              maxlength="40"
              name="parkingDeparture"
              id="parkingDeparture"
              placeholder="Gate"
            />
          </div>

          <!-- Parking Arrival -->
          <div class="mb-2">
            <p class="ml-2 text-sm text-gray-500">Gate Arr.</p>

            <input
              class="block rounded-md border-0 w-[80px] py-1.5 pl-5 pr-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm uppercase"
              v-model="parkingArrival"
              type="text"
              maxlength="40"
              name="parkingArrival"
              id="parkingArrival"
              placeholder="Gate"
            />
          </div>
        </div>

        <!-- Remarks -->
        <div>
          <p class="ml-2 text-sm text-gray-500">Remarks</p>
          <div>
            <textarea
              v-model="remarks"
              rows="2"
              maxlength="400"
              siz
              name="remarks"
              id="remarks"
              class="block w-full rounded-md border-0 py-0.5 px-1.5 text-base text-blue-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:leading-6"
            />
          </div>
        </div>

        <div class="flex gap-5 flex-wrap items-center">
          <div v-if="selectedStatus === 'BLOCKED-IN'">
            <div class="flex my-2 items-center py-1">
              <SwitchGroup as="div" class="flex items-center">
                <Switch
                  v-model="noTakeoff"
                  :class="[
                    noTakeoff ? 'bg-red-500' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                  ]"
                >
                  <span
                    aria-hidden="true"
                    :class="[
                      noTakeoff ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                    ]"
                  />
                </Switch>
              </SwitchGroup>

              <div class="text-sm text-gray-400 ml-2">No Takeoff</div>
            </div>
          </div>

          <div>
            <div class="flex my-2 items-center py-1">
              <SwitchGroup as="div" class="flex items-center">
                <Switch
                  v-model="deiced"
                  :class="[
                    deiced ? 'bg-blue-500' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                  ]"
                >
                  <span
                    aria-hidden="true"
                    :class="[
                      deiced ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                    ]"
                  />
                </Switch>
              </SwitchGroup>

              <div class="text-sm text-gray-400 ml-2">De-Iced</div>
            </div>
          </div>

          <div>
            <div class="flex my-2 items-center py-1">
              <SwitchGroup as="div" class="flex items-center">
                <Switch
                  v-model="needsUsCrew"
                  :class="[
                    needsUsCrew ? 'bg-blue-500' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                  ]"
                >
                  <span
                    aria-hidden="true"
                    :class="[
                      needsUsCrew ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                    ]"
                  />
                </Switch>
              </SwitchGroup>

              <div class="text-sm text-gray-400 ml-2">US Crew</div>
            </div>
          </div>

          <div>
            <div class="flex my-2 items-center py-1">
              <SwitchGroup as="div" class="flex items-center">
                <Switch
                  v-model="hidden"
                  :class="[
                    hidden ? 'bg-red-500' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                  ]"
                >
                  <span
                    aria-hidden="true"
                    :class="[
                      hidden ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                    ]"
                  />
                </Switch>
              </SwitchGroup>

              <div class="text-sm text-gray-400 ml-2">
                Hide on public searches.
              </div>
            </div>
          </div>
        </div>

        <!-- CREWMEMBERS -->
        <p class="text-lg pt-1.5 pl-3">CREW</p>
        <div class="crewmembers-container">
          <!-- Captain -->
          <div
            class="seat capt-seat"
            @click="handleSeatAction(captain)"
            :style="getSeatStyle(captain)"
          >
            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
            >
              {{ captain.seat }}
            </div>

            <i :class="getSeatIcon(captain)"> </i>

            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
            >
              {{ getSeatFooterText(captain) }}
            </div>
          </div>

          <!-- First Officer -->
          <div
            class="seat fo-seat"
            @click="handleSeatAction(firstOfficer)"
            :style="getSeatStyle(firstOfficer)"
          >
            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
            >
              {{ firstOfficer.seat }}
            </div>

            <i :class="getSeatIcon(firstOfficer)"> </i>

            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
            >
              {{ getSeatFooterText(firstOfficer) }}
            </div>
          </div>

          <!-- ACM-1 -->
          <div
            class="seat acm1-seat"
            @click="handleSeatAction(acm1)"
            :style="getSeatStyle(acm1)"
          >
            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
            >
              {{ acm1.seat }}
            </div>

            <i :class="getSeatIcon(acm1)"> </i>

            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
            >
              {{ getSeatFooterText(acm1) }}
            </div>
          </div>

          <!-- FA-A -->
          <div
            class="seat faa-seat"
            @click="handleSeatAction(faa)"
            :style="getSeatStyle(faa)"
          >
            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
            >
              {{ faa.seat }}
            </div>

            <i :class="getSeatIcon(faa)"> </i>

            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
            >
              {{ getSeatFooterText(faa) }}
            </div>
          </div>

          <!-- FA-B -->
          <div
            class="seat fab-seat"
            @click="handleSeatAction(fab)"
            :style="getSeatStyle(fab)"
          >
            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
            >
              {{ fab.seat }}
            </div>

            <i :class="getSeatIcon(fab)"> </i>

            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
            >
              {{ getSeatFooterText(fab) }}
            </div>
          </div>

          <!-- MX -->
          <div
            class="seat mx-seat"
            @click="handleSeatAction(mx)"
            :style="getSeatStyle(mx)"
          >
            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
            >
              {{ mx.seat }}
            </div>

            <i :class="getSeatIcon(mx)"> </i>

            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
            >
              {{ getSeatFooterText(mx) }}
            </div>
          </div>

          <!-- GSC -->
          <div
            class="seat gsc-seat"
            @click="handleSeatAction(gsc)"
            :style="getSeatStyle(gsc)"
          >
            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
            >
              {{ gsc.seat }}
            </div>

            <i :class="getSeatIcon(gsc)"> </i>

            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
            >
              {{ getSeatFooterText(gsc) }}
            </div>
          </div>

          <!-- DH-1 -->
          <div
            class="seat dh1-seat"
            @click="handleSeatAction(dh1)"
            :style="getSeatStyle(dh1)"
          >
            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
            >
              {{ dh1.seat }}
            </div>

            <i :class="getSeatIcon(dh1)"> </i>

            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
            >
              {{ getSeatFooterText(dh1) }}
            </div>
          </div>

          <!-- DH-2 -->
          <div
            class="seat dh2-seat"
            @click="handleSeatAction(dh2)"
            :style="getSeatStyle(dh2)"
          >
            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
            >
              {{ dh2.seat }}
            </div>

            <i :class="getSeatIcon(dh2)"> </i>

            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
            >
              {{ getSeatFooterText(dh2) }}
            </div>
          </div>

          <!-- DH-3 -->
          <div
            class="seat dh3-seat"
            @click="handleSeatAction(dh3)"
            :style="getSeatStyle(dh3)"
          >
            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
            >
              {{ dh3.seat }}
            </div>

            <i :class="getSeatIcon(dh3)"> </i>

            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
            >
              {{ getSeatFooterText(dh3) }}
            </div>
          </div>

          <!-- DH-4 -->
          <div
            class="seat dh4-seat"
            @click="handleSeatAction(dh4)"
            :style="getSeatStyle(dh4)"
          >
            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
            >
              {{ dh4.seat }}
            </div>

            <i :class="getSeatIcon(dh4)"> </i>

            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
            >
              {{ getSeatFooterText(dh4) }}
            </div>
          </div>

          <!-- DH-5 -->
          <div
            class="seat dh5-seat"
            @click="handleSeatAction(dh5)"
            :style="getSeatStyle(dh5)"
          >
            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
            >
              {{ dh5.seat }}
            </div>

            <i :class="getSeatIcon(dh5)"> </i>

            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
            >
              {{ getSeatFooterText(dh5) }}
            </div>
          </div>

          <!-- DH-6 -->
          <div
            class="seat dh6-seat"
            @click="handleSeatAction(dh6)"
            :style="getSeatStyle(dh6)"
          >
            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
            >
              {{ dh6.seat }}
            </div>

            <i :class="getSeatIcon(dh6)"> </i>

            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
            >
              {{ getSeatFooterText(dh6) }}
            </div>
          </div>

          <!-- ACM-2 -->
          <div
            class="seat acm2-seat"
            @click="handleSeatAction(acm2)"
            :style="getSeatStyle(acm2)"
          >
            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
            >
              {{ acm2.seat }}
            </div>

            <i :class="getSeatIcon(acm2)"> </i>

            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
            >
              {{ getSeatFooterText(acm2) }}
            </div>
          </div>

          <!-- ACM-3 -->
          <div
            class="seat acm3-seat"
            @click="handleSeatAction(acm3)"
            :style="getSeatStyle(acm3)"
          >
            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
            >
              {{ acm3.seat }}
            </div>

            <i :class="getSeatIcon(acm3)"> </i>

            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
            >
              {{ getSeatFooterText(acm3) }}
            </div>
          </div>

          <!-- ACM-4 -->
          <div
            class="seat acm4-seat"
            @click="handleSeatAction(acm4)"
            :style="getSeatStyle(acm4)"
          >
            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
            >
              {{ acm4.seat }}
            </div>

            <i :class="getSeatIcon(acm4)"> </i>

            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
            >
              {{ getSeatFooterText(acm4) }}
            </div>
          </div>

          <!-- ACM-5 -->
          <div
            class="seat acm5-seat"
            @click="handleSeatAction(acm5)"
            :style="getSeatStyle(acm5)"
          >
            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
            >
              {{ acm5.seat }}
            </div>

            <i :class="getSeatIcon(acm5)"> </i>

            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
            >
              {{ getSeatFooterText(acm5) }}
            </div>
          </div>

          <!-- ACM-6 -->

          <div
            class="seat acm6-seat"
            @click="handleSeatAction(acm6)"
            :style="getSeatStyle(acm6)"
          >
            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
            >
              {{ acm6.seat }}
            </div>

            <i :class="getSeatIcon(acm6)"> </i>

            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
            >
              {{ getSeatFooterText(acm6) }}
            </div>
          </div>

          <!-- ACM-7 -->

          <div
            class="seat acm7-seat"
            @click="handleSeatAction(acm7)"
            :style="getSeatStyle(acm7)"
          >
            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
            >
              {{ acm7.seat }}
            </div>

            <i :class="getSeatIcon(acm7)"> </i>

            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
            >
              {{ getSeatFooterText(acm7) }}
            </div>
          </div>

          <!-- FA-C -->
          <div
            class="seat fac-seat"
            @click="handleSeatAction(fac)"
            :style="getSeatStyle(fac)"
          >
            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
            >
              {{ fac.seat }}
            </div>

            <i :class="getSeatIcon(fac)"> </i>

            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
            >
              {{ getSeatFooterText(fac) }}
            </div>
          </div>

          <!-- FA-D -->
          <div
            class="seat fad-seat"
            @click="handleSeatAction(fad)"
            :style="getSeatStyle(fad)"
          >
            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
            >
              {{ fad.seat }}
            </div>

            <i :class="getSeatIcon(fad)"> </i>

            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
            >
              {{ getSeatFooterText(fad) }}
            </div>
          </div>

          <!-- FA-E -->
          <div
            class="seat fae-seat"
            @click="handleSeatAction(fae)"
            :style="getSeatStyle(fae)"
          >
            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
            >
              {{ fae.seat }}
            </div>

            <i :class="getSeatIcon(fae)"> </i>

            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
            >
              {{ getSeatFooterText(fae) }}
            </div>
          </div>

          <!-- FA-F -->
          <div
            class="seat faf-seat"
            @click="handleSeatAction(faf)"
            :style="getSeatStyle(faf)"
          >
            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-sm"
            >
              {{ faf.seat }}
            </div>

            <i :class="getSeatIcon(faf)"> </i>

            <div
              class="text-center whitespace-nowrap overflow-x-hidden text-ellipsis text-xs px-1"
            >
              {{ getSeatFooterText(faf) }}
            </div>
          </div>
        </div>

        <div class="flex gap-5">
          <div v-if="showTakeoffBy" class="flex gap-1 items-center">
            <p class="ml-2 text-sm text-gray-500">Takeoff By:</p>
            <button @click="handleToggleTakeoffBySeat" class="ml-1 btn-text">
              {{ takeoffBySeat }}
            </button>
          </div>
          <div v-if="showLandingBy" class="flex gap-1 items-center">
            <p class="ml-2 text-sm text-gray-500">Landing By:</p>
            <button @click="handleToggleLandingBySeat" class="ml-1 btn-text">
              {{ landingBySeat }}
            </button>
          </div>
        </div>

        <div class="flex justify-end items-center">
          <button
            @click="handleGoBack"
            class="btn-small bg-gray-500 text-white"
          >
            Cancel
          </button>
          <button
            @click="handleUpdateFlight"
            class="ml-1 btn-small bg-green-500 text-white"
            :class="{ disabled: !isValid }"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin } from "../mixins/mixin";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";
import RModal from "../components/RModal.vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
  Switch,
  SwitchGroup,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";
import { h } from "vue";

export default {
  name: "administration",
  mixins: [mixin],
  components: {
    RSnackbar,
    RSpinner,
    RModal,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronUpDownIcon,
    Switch,
    SwitchGroup,
  },

  data() {
    return {
      loading: false,

      selectedDelay: null,
      confirmText: "",

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,

      //------------------------Dynamic Labels
      outDynamicLabel: "OUT",
      offDynamicLabel: "OFF",
      onDynamicLabel: "ON",
      inDynamicLabel: "IN",

      //-------------------------Dialogs
      showComputedTimesErrorsDialog: false,
      showFuelDialog: false,
      showFlightInformationDialog: false,
      showPaxsDialog: false,
      showDelaysDialog: false,
      showCrewRequirementsDialog: false,

      //----------------------Options
      statusOptions: ["SCHEDULED", "BLOCKED-OUT", "AIRBORNE", "BLOCKED-IN"],
      flightRulesOptions: [
        "Part 117",
        "Part 121",
        "Part 91",
        "Ferry",
        "MX Ferry",
      ],

      //----------------------Enable/Disable
      enableOffTime: false,
      enableOnTime: false,
      enableFuelRemaining: false,
      enableFuelUplift: false,

      //--------------------Fuel Tickets
      fuelTicketList: [],
      fuelTicketVendor: "",
      fuelTicketInvoiceNumber: "",
      fuelTicketQuantity: "",

      //----------------------Errors
      computedTimesErrors: [],

      //---------------------------------------------Flight
      selectedStatus: null,
      selectedCustomer: null,
      selectedAircraft: null,
      flightNumber: "",
      logpage: "",
      hidden: false,
      originIata: "",
      destinationIata: "",
      plannedDestinationIata: "",
      selectedFlightRules: "",
      cabinSeats: 0,
      parkingDeparture: "",
      parkingArrival: "",
      crewmembers: [],
      takeoffBySeat: "CAPT",
      landingBySeat: "CAPT",
      updatedById: null,

      //----------------Delays
      delays: [],
      delayList: [],

      //------------------Fuel
      fuelTickets: [],
      fuelUplift: 0,
      departureFuel: 0,
      arrivalFuel: 0,

      //------------------Times
      _start: null,
      _end: null,
      outDateString: new Date().toISOString().substring(0, 10),
      outTimeString: new Date().toISOString().substring(11, 16),
      offTimeString: new Date().toISOString().substring(11, 16),
      onTimeString: new Date().toISOString().substring(11, 16),
      inTimeString: new Date().toISOString().substring(11, 16),

      //-------------------Paxs
      actualPaxAdults: 0,
      actualPaxInfants: 0,
      bookedPaxAdults: 0,
      bookedPaxInfants: 0,

      actualPaxAdultsInput: 0,
      actualPaxInfantsInput: 0,
      bookedPaxAdultsInput: 0,
      bookedPaxInfantsInput: 0,

      deiced: false,
      needsUsCrew: false,
      remarks: "",
      unscheduledEvent: "",
      minimumBlock: 20,
      minimumFlight: 10,
      noTakeoff: false,
      updatedAt: null,
    };
  },

  created() {
    const flight = { ...this.flight };

    const {
      _id,
      actualPaxAdults,
      //actualPaxChildren,
      actualPaxInfants,
      aircraft,
      bookedPaxAdults,
      //bookedPaxChildren,
      bookedPaxInfants,
      crewmembers,
      customer,
      deiced,
      needsUsCrew,
      delays,
      destination,
      flightNumber,
      flightRules,
      fuelTickets,
      departureFuel,
      arrivalFuel,
      hidden,
      logpage,
      noTakeoff,
      origin,
      plannedDestination,
      parkingDeparture,
      parkingArrival,
      remarks,
      status,
      _sta,
      _std,
      _start,
      _off,
      _on,
      _end,
      updatedAt,
      updatedById,
    } = flight;

    let i = this.customerOptions.findIndex((c) => {
      return c._id === customer._id;
    });

    if (i >= 0) {
      this.selectedCustomer = { ...this.customerOptions[i] };
    } else {
      this.selectedCustomer = { ...this.customerOptions[0] };
    }

    i = this.aircraftOptions.findIndex((a) => {
      return a._id === aircraft._id;
    });

    if (i >= 0) {
      this.selectedAircraft = { ...this.aircraftOptions[i] };
    } else {
      this.selectedAircraft = { ...this.aircraftOptions[0] };
    }

    this._id = _id;
    this.flightNumber = flightNumber;
    this.selectedStatus = status;
    this.selectedFlightRules = flightRules;
    this.logpage = logpage ? logpage : "";
    this.noTakeoff = noTakeoff ? true : false;
    this.deiced = deiced ? true : false;
    this.needsUsCrew = needsUsCrew ? true : false;

    this._start = _start;
    this._std = _std;
    this._sta = _sta;
    this.updatedAt = updatedAt;

    this.delays = delays;
    this.crewmembers = crewmembers;
    this.takeoffBySeat = flight.takeoffBy.seat;
    this.landingBySeat = flight.landingBy.seat;
    this.updatedById = updatedById;

    this.parkingDeparture = parkingDeparture;
    this.parkingArrival = parkingArrival;

    //------------------------------------------Passengers
    this.actualPaxAdults = actualPaxAdults;
    //this.actualPaxChildren = actualPaxChildren;
    this.actualPaxInfants = actualPaxInfants;

    this.bookedPaxAdults = bookedPaxAdults;
    //this.bookedPaxChildren = bookedPaxChildren;
    this.bookedPaxInfants = bookedPaxInfants;

    let seats = 0;

    aircraft.cabinConfig.forEach((item) => {
      seats += item.seats;
    });

    this.cabinSeats = seats;

    //--------------------------------------------Fuel
    this.fuelTickets = fuelTickets;
    this.departureFuel = departureFuel;
    this.arrivalFuel = arrivalFuel;

    let uplift = 0;
    if (fuelTickets.length) {
      fuelTickets.forEach((item) => {
        uplift += item.quantity;
      });
    }

    this.fuelUplift = uplift;

    //--------------------------------------------OUT Time
    this.outDateString = new Date(_start).toISOString().substring(0, 10);
    this.outTimeString = new Date(_start).toISOString().substring(11, 16);

    //--------------------------------------------IN Time
    this.inTimeString = new Date(_end).toISOString().substring(11, 16);

    switch (status) {
      case "BLOCKED-OUT":
        this.offTimeString = null;
        this.onTimeString = null;

        this.enableOffTime = false;
        this.enableOnTime = false;

        this.outDynamicLabel = "OUT";
        this.offDynamicLabel = "OFF";
        this.onDynamicLabel = "ON";
        this.inDynamicLabel = "ETA";

        this.enableFuelRemaining = false;
        this.enableFuelUplift = false;
        break;

      case "AIRBORNE":
        this.offTimeString = new Date(_off).toISOString().substring(11, 16);
        this.onTimeString = "";

        this.onTimeString = null;

        this.enableOffTime = true;
        this.enableOnTime = false;

        this.outDynamicLabel = "OUT";
        this.offDynamicLabel = "OFF";
        this.onDynamicLabel = "ON";
        this.inDynamicLabel = "ETA";

        this.enableFuelRemaining = false;
        this.enableFuelUplift = false;
        break;

      case "BLOCKED-IN":
        if (!noTakeoff) {
          this.offTimeString = new Date(_off).toISOString().substring(11, 16);
          this.onTimeString = new Date(_on).toISOString().substring(11, 16);
        }

        this.enableOffTime = true;
        this.enableOnTime = true;

        this.outDynamicLabel = "OUT";
        this.offDynamicLabel = "OFF";
        this.onDynamicLabel = "ON";
        this.inDynamicLabel = "IN";

        this.enableFuelRemaining = true;
        this.enableFuelUplift = false;
        break;

      default: //"SCHEDULED"
        this.offTimeString = null;
        this.onTimeString = null;

        this.enableOffTime = false;
        this.enableOnTime = false;

        this.outDynamicLabel = "ETD";
        this.offDynamicLabel = "OFF";
        this.onDynamicLabel = "ON";
        this.inDynamicLabel = "ETA";

        this.enableFuelRemaining = false;
        this.enableFuelUplift = true;
        break;
    }

    this.originIata = origin.iata;
    this.destinationIata = destination.iata;
    this.plannedDestination = plannedDestination;

    this.hidden = hidden ? true : false;
    this.remarks = remarks ? remarks : "";
  },

  computed: {
    auth() {
      return this.$store.state.auth;
    },

    flight() {
      return this.$store.getters.flight;
    },

    captain() {
      return this.crewmembers.find((item) => {
        return item.seat === "CAPT";
      });
    },

    firstOfficer() {
      return this.crewmembers.find((item) => {
        return item.seat === "FO";
      });
    },

    acm1() {
      return this.crewmembers.find((item) => {
        return item.seat === "ACM-1";
      });
    },

    acm2() {
      return this.crewmembers.find((item) => {
        return item.seat === "ACM-2";
      });
    },

    acm3() {
      return this.crewmembers.find((item) => {
        return item.seat === "ACM-3";
      });
    },

    acm4() {
      return this.crewmembers.find((item) => {
        return item.seat === "ACM-4";
      });
    },

    acm5() {
      return this.crewmembers.find((item) => {
        return item.seat === "ACM-5";
      });
    },

    acm6() {
      return this.crewmembers.find((item) => {
        return item.seat === "ACM-6";
      });
    },

    acm7() {
      return this.crewmembers.find((item) => {
        return item.seat === "ACM-7";
      });
    },

    faa() {
      return this.crewmembers.find((item) => {
        return item.seat === "FA-A";
      });
    },

    fab() {
      return this.crewmembers.find((item) => {
        return item.seat === "FA-B";
      });
    },
    fac() {
      return this.crewmembers.find((item) => {
        return item.seat === "FA-C";
      });
    },

    fad() {
      return this.crewmembers.find((item) => {
        return item.seat === "FA-D";
      });
    },

    fae() {
      return this.crewmembers.find((item) => {
        return item.seat === "FA-E";
      });
    },

    faf() {
      return this.crewmembers.find((item) => {
        return item.seat === "FA-F";
      });
    },

    dh1() {
      return this.crewmembers.find((item) => {
        return item.seat === "DH-1";
      });
    },

    dh2() {
      return this.crewmembers.find((item) => {
        return item.seat === "DH-2";
      });
    },

    dh3() {
      return this.crewmembers.find((item) => {
        return item.seat === "DH-3";
      });
    },

    dh4() {
      return this.crewmembers.find((item) => {
        return item.seat === "DH-4";
      });
    },

    dh5() {
      return this.crewmembers.find((item) => {
        return item.seat === "DH-5";
      });
    },

    dh6() {
      return this.crewmembers.find((item) => {
        return item.seat === "DH-6";
      });
    },

    mx() {
      return this.crewmembers.find((item) => {
        return item.seat === "MX";
      });
    },

    gsc() {
      return this.crewmembers.find((item) => {
        return item.seat === "GSC";
      });
    },

    isValidDelete() {
      return (
        this.confirmText.toUpperCase().trim() ===
        this.flightNumber.toUpperCase().trim()
      );
    },

    customerOptions() {
      return this.$store.state.customers;
    },

    aircraftOptions() {
      return this.$store.state.aircraft;
    },

    airports() {
      return this.$store.state.airports;
    },

    computedOrigin() {
      if (this.originIata.length === 3) {
        const airport = this.airports.find((a) => {
          return a.iata === this.originIata.toUpperCase();
        });

        return airport;
      } else {
        return null;
      }
    },

    computedDestination() {
      if (this.destinationIata.length === 3) {
        const airport = this.airports.find((a) => {
          return a.iata === this.destinationIata.toUpperCase();
        });

        return airport;
      } else {
        return null;
      }
    },

    totalGallons() {
      if (this.fuelTicketList.length > 0) {
        return this.fuelTicketList.reduce((acc, item) => {
          return acc + item.quantity;
        }, 0);
      } else {
        return 0;
      }
    },

    isValidBookedPaxCount() {
      return this.bookedPaxAdultsInput <= this.cabinSeats;
    },

    isValidActualPaxCount() {
      return this.actualPaxAdultsInput <= this.cabinSeats;
    },

    isValidDepartureFuel() {
      let isValid = false;

      switch (this.selectedStatus) {
        case "AIRBORNE":
          isValid = this.departureFuel > 0 && this.departureFuel < 100000;
          break;

        case "BLOCKED-IN":
          isValid =
            this.departureFuel > 0 && this.arrivalFuel < this.departureFuel;
          break;

        case "BLOCKED-OUT":
          isValid = this.departureFuel > 0;
          break;

        default: //"SCHEDULED"
          if (this.departureFuel === 0) {
            isValid = this.arrivalFuel === 0;
          } else {
            isValid = this.arrivalFuel < this.departureFuel;
          }

          break;
      }

      return isValid;
    },

    isValidArrivalFuel() {
      if (this.selectedStatus === "BLOCKED-IN") {
        return this.arrivalFuel < this.departureFuel;
      } else {
        return this.arrivalFuel <= this.departureFuel;
      }
    },

    isValidFuelTicket() {
      if (
        this.fuelTicketVendor.length &&
        this.fuelTicketInvoiceNumber.length &&
        this.fuelTicketQuantity > 0
      ) {
        return true;
      } else {
        return false;
      }
    },

    isValidCallsign() {
      return this.flightNumber.length > 3;
    },

    isValidCrew() {
      if (this.selectedStatus === "SCHEDULED") {
        return true;
      }

      let m = 0;
      this.crewmembers.forEach((item) => {
        if (item.required && !item.employee) {
          m++;
        }
      });

      return m === 0;
    },

    computedTimes() {
      this.computedTimesErrors = [];

      if (!this.outDateString) {
        this.computedTimesErrors.push("Date is invalid");
        return {
          isValid: false,
        };
      }

      if (!this.outTimeString) {
        this.computedTimesErrors.push("OUT time is invalid");
        return {
          isValid: false,
        };
      }

      if (!this.inTimeString) {
        this.computedTimesErrors.push("IN TIME is invalid");
        return {
          isValid: false,
        };
      }

      if (this.noTakeoff) {
        this.offTimeString = null;
        this.onTimeString = null;
      }

      let millis,
        diff,
        _out,
        _off,
        dateString,
        _on,
        _in,
        totalMinutes,
        flight,
        block;

      //----------------------------------------------------------  OUT
      // OUT time must always be calculated
      millis = new Date(
        `${this.outDateString}T${this.outTimeString}:00.000+00:00`
      ).getTime();

      diff = Math.abs(new Date(this._std).getTime() - millis);

      // Invalid if difference between OUT and STD is more than 12 hours
      if (diff > 12 * 60 * 60 * 1000) {
        this.computedTimesErrors.push(
          "Difference between OUT and STD is more than 12 hours"
        );
        return {
          isValid: false,
        };
      }

      _out = millis;

      dateString = this.outDateString;

      //---------------------------------------------------------- SCHEDULED or BLOCKED-OUT
      if (
        this.selectedStatus === "SCHEDULED" ||
        this.selectedStatus === "BLOCKED-OUT"
      ) {
        //Calculate the IN time
        millis = new Date(
          `${dateString}T${this.inTimeString}:00.000+00:00`
        ).getTime();

        diff = millis - _out;

        if (diff <= 0) {
          // Use next day
          millis += 24 * 60 * 60 * 1000;
        }

        _in = millis;

        dateString = new Date(millis).toISOString().substring(0, 10);
      }

      //---------------------------------------------------------- AIRBORNE
      else if (this.selectedStatus === "AIRBORNE") {
        // Minimum values to calculate OFF
        if (!this.offTimeString) {
          this.computedTimesErrors.push("OFF time is invalid");
          return {
            isValid: false,
          };
        }

        //Calculate the OFF time
        millis = new Date(
          `${dateString}T${this.offTimeString}:00.000+00:00`
        ).getTime();

        diff = millis - _out;

        if (diff <= 0) {
          // Use next day
          millis += 24 * 60 * 60 * 1000;
        }

        _off = millis;

        dateString = new Date(millis).toISOString().substring(0, 10);

        //Calculate the IN time
        millis = new Date(
          `${dateString}T${this.inTimeString}:00.000+00:00`
        ).getTime();

        diff = millis - _off;

        if (diff <= 0) {
          // Use next day
          millis += 24 * 60 * 60 * 1000;
        }

        _in = millis;

        dateString = new Date(millis).toISOString().substring(0, 10);
      }

      //---------------------------------------------------------- BLOCKED-IN
      if (this.selectedStatus === "BLOCKED-IN") {
        // Minimum values to calculate OFF
        if (!this.noTakeoff) {
          if (!this.offTimeString) {
            this.computedTimesErrors.push("OFF time is invalid");
            return {
              isValid: false,
            };
          }

          if (!this.onTimeString) {
            this.computedTimesErrors.push("ON time is invalid");
            return {
              isValid: false,
            };
          }

          //Calculate the OFF time
          millis = new Date(
            `${dateString}T${this.offTimeString}:00.000+00:00`
          ).getTime();

          diff = millis - _out;

          if (diff <= 0) {
            // Use next day
            millis += 24 * 60 * 60 * 1000;
          }

          _off = millis;

          dateString = new Date(millis).toISOString().substring(0, 10);

          //Calculate the ON time
          millis = new Date(
            `${dateString}T${this.onTimeString}:00.000+00:00`
          ).getTime();

          diff = millis - _out;

          if (diff <= 0) {
            // Use next day
            millis += 24 * 60 * 60 * 1000;
          }

          _on = millis;

          dateString = new Date(millis).toISOString().substring(0, 10);

          //Calculate the IN time
          millis = new Date(
            `${dateString}T${this.inTimeString}:00.000+00:00`
          ).getTime();

          diff = millis - _on;

          if (diff <= 0) {
            // Use next day
            millis += 24 * 60 * 60 * 1000;
          }

          _in = millis;
        } else {
          //Calculate the IN time
          millis = new Date(
            `${dateString}T${this.inTimeString}:00.000+00:00`
          ).getTime();

          diff = millis - _out;

          if (diff <= 0) {
            // Use next day
            millis += 24 * 60 * 60 * 1000;
          }

          _in = millis;
        }
      }

      let h, m;

      //------------------------------------------------------------- Block Time
      totalMinutes = (_in - _out) / (60 * 1000);

      if (
        totalMinutes < this.minimumBlock ||
        totalMinutes > this.selectedAircraft.rangeInMinutes
      ) {
        return {
          isValid: false,
        };
      }

      h = Math.floor((totalMinutes % (24 * 60)) / 60); // Calculate remaining hours
      m = totalMinutes % 60; // Calculate remaining minutes

      block = {
        totalMinutes,
        hours: h,
        minutes: m,
        text: `${h.toString().padStart(2, "0")}+${m
          .toString()
          .padStart(2, "0")}`,
      };

      //------------------------------------------------------------- Flight Time

      if (_off && _on) {
        totalMinutes = (_on - _off) / (60 * 1000);

        if (
          totalMinutes < this.minimumFlight ||
          totalMinutes > this.selectedAircraft.rangeInMinutes
        ) {
          return {
            isValid: false,
          };
        }

        h = Math.floor((totalMinutes % (24 * 60)) / 60); // Calculate remaining hours
        m = totalMinutes % 60; // Calculate remaining minutes

        flight = {
          totalMinutes,
          hours: h,
          minutes: m,
          text: `${h.toString().padStart(2, "0")}+${m
            .toString()
            .padStart(2, "0")}`,
        };
      } else {
        flight = {
          totalMinutes: 0,
          text: "00+00",
        };
      }

      return {
        _out,
        _off,
        _on,
        _in,
        flight,
        block,
        isValid: true,
      };
    },

    isValid() {
      return (
        this.computedTimes.isValid &&
        this.computedOrigin &&
        this.computedDestination &&
        this.isValidDepartureFuel &&
        this.isValidArrivalFuel &&
        this.isValidCrew
      );
    },

    showTakeoffBy() {
      return (
        (this.selectedStatus === "AIRBORNE" ||
          this.selectedStatus === "BLOCKED-IN") &&
        !this.noTakeoff
      );
    },

    showLandingBy() {
      return this.selectedStatus === "BLOCKED-IN" && !this.noTakeoff;
    },
  },

  methods: {
    handleToggleTakeoffBySeat() {
      if (this.takeoffBySeat === "CAPT") {
        this.takeoffBySeat = "FO";
      } else {
        this.takeoffBySeat = "CAPT";
      }
    },

    handleToggleLandingBySeat() {
      if (this.landingBySeat === "CAPT") {
        this.landingBySeat = "FO";
      } else {
        this.landingBySeat = "CAPT";
      }
    },

    getSeatStyle(item) {
      let style = {
        backgroundColor: "var(--light-gray)",
      };

      if (item.employee) {
        style.backgroundColor = "var(--assigned)";
      } else {
        if (item.required) {
          style.backgroundColor = "var(--missing)";
        }
      }

      return style;
    },

    getSeatFooterText(item) {
      if (item.employee) {
        return `${item.employee.surname}, ${item.employee.givenName}`;
      } else {
        if (item.required) {
          return "Please Assign.";
        } else {
          return "Not Required";
        }
      }
    },

    getSeatIcon(item) {
      //TODO: Add logic to handle different icons

      let icon = "fa-solid fa-user-slash";

      switch (item.seat) {
        case "CAPT":
          if (item.required) {
            if (item.employee) {
              icon = "fa-solid fa-user-pilot";
            } else {
              icon = "fa-solid fa-exclamation";
            }
          }

          break;

        default:
          if (item.required) {
            if (item.employee) {
              icon = "fa-solid fa-user";
            } else {
              icon = "fa-solid fa-exclamation";
            }
          }
          break;
      }

      return icon;
    },

    handleSeatAction(item) {
      //TEMP

      this.snackbarColor = "orange";
      this.snackbarText = "Under Development" + item.seat;
      this.snackbarVisible = true;

      setTimeout(() => {
        this.snackbarVisible = false;
      }, 3000);

      // // Only assign if flight is scheduled
      // if (this.flight.status === "SCHEDULED") {
      //   this.$store.commit("updateSelectedSeat", item.seat);
      //   this.$store.commit("updateFlight", this.flight);
      //   this.$router.push({ name: "FlightAssignmentBoard" });
      // } else {
      //   this.snackbarColor = "red";
      //   this.snackbarText =
      //     "Flight crew can only be assigned when flight is scheduled.";
      //   this.snackbarVisible = true;

      //   setTimeout(() => {
      //     this.snackbarVisible = false;
      //   }, 3000);
      // }
    },

    handleShowCrewRequirementsDialog() {
      if (this.selectedStatus !== "SCHEDULED") {
        return;
      }

      if (!this.isValid) {
        this.snackbarColor = "red";
        this.snackbarText = "Please correct the errors before proceeding";
        this.snackbarVisible = true;
        return;
      }

      this.showCrewRequirementsDialog = true;
    },

    async handleUpdateCrewRequirements() {
      //TODO: Update crew requirements
      let i;

      const { crewmembers } = this.aicraftEvent;

      //---------------------------------Captain
      i = crewmembers.findIndex((item) => {
        return item.seat === "CAPT";
      });

      if (i >= 0) {
        crewmembers[i].required = this.captainIsRequired;
      }

      //---------------------------------First Officer
      i = crewmembers.findIndex((item) => {
        return item.seat === "FO";
      });

      if (i >= 0) {
        crewmembers[i].required = this.firstOfficerIsRequired;
      }

      //---------------------------------Flight Attendant A
      i = crewmembers.findIndex((item) => {
        return item.seat === "FA-A";
      });

      if (i >= 0) {
        crewmembers[i].required = this.flightAttendantAIsRequired;
      }

      //---------------------------------Flight Attendant B
      i = crewmembers.findIndex((item) => {
        return item.seat === "FA-B";
      });

      if (i >= 0) {
        crewmembers[i].required = this.flightAttendantBIsRequired;
      }

      //---------------------------------Flight Attendant C
      i = crewmembers.findIndex((item) => {
        return item.seat === "FA-C";
      });

      if (i >= 0) {
        crewmembers[i].required = this.flightAttendantCIsRequired;
      }

      //---------------------------------Flight Attendant D

      i = crewmembers.findIndex((item) => {
        return item.seat === "FA-D";
      });

      if (i >= 0) {
        crewmembers[i].required = this.flightAttendantDIsRequired;
      }

      //---------------------------------Flight Attendant E
      i = crewmembers.findIndex((item) => {
        return item.seat === "FA-E";
      });

      if (i >= 0) {
        crewmembers[i].required = this.flightAttendantEIsRequired;
      }

      //---------------------------------Flight Attendant F
      i = crewmembers.findIndex((item) => {
        return item.seat === "FA-F";
      });

      if (i >= 0) {
        crewmembers[i].required = this.flightAttendantFIsRequired;
      }

      //---------------------------------Ground Security Coordinator
      i = crewmembers.findIndex((item) => {
        return item.seat === "GSC";
      });

      if (i >= 0) {
        crewmembers[i].required = this.groundSecurityCoordinatorIsRequired;
      }

      //---------------------------------Flight Mechanic
      i = crewmembers.findIndex((item) => {
        return item.seat === "MX";
      });

      if (i >= 0) {
        crewmembers[i].required = this.flightMechanicIsRequired;
      }

      //---------------------------------Additional Crewmember 1
      i = crewmembers.findIndex((item) => {
        return item.seat === "ACM-1";
      });

      if (i >= 0) {
        crewmembers[i].required = this.additionalCrewmember1IsRequired;
      }

      //---------------------------------Additional Crewmember 2
      i = crewmembers.findIndex((item) => {
        return item.seat === "ACM-2";
      });

      if (i >= 0) {
        crewmembers[i].required = this.additionalCrewmember2IsRequired;
      }

      //---------------------------------Additional Crewmember 3
      i = crewmembers.findIndex((item) => {
        return item.seat === "ACM-3";
      });

      if (i >= 0) {
        crewmembers[i].required = this.additionalCrewmember3IsRequired;
      }

      //---------------------------------Additional Crewmember 4
      i = crewmembers.findIndex((item) => {
        return item.seat === "ACM-4";
      });

      if (i >= 0) {
        crewmembers[i].required = this.additionalCrewmember4IsRequired;
      }

      //---------------------------------Additional Crewmember 5
      i = crewmembers.findIndex((item) => {
        return item.seat === "ACM-5";
      });

      if (i >= 0) {
        crewmembers[i].required = this.additionalCrewmember5IsRequired;
      }

      //---------------------------------Additional Crewmember 6
      i = crewmembers.findIndex((item) => {
        return item.seat === "ACM-6";
      });

      if (i >= 0) {
        crewmembers[i].required = this.additionalCrewmember6IsRequired;
      }

      //---------------------------------Additional Crewmember 7
      i = crewmembers.findIndex((item) => {
        return item.seat === "ACM-7";
      });

      if (i >= 0) {
        crewmembers[i].required = this.additionalCrewmember7IsRequired;
      }

      //---------------------------------Additional Crewmember 8
      i = crewmembers.findIndex((item) => {
        return item.seat === "ACM-8";
      });

      if (i >= 0) {
        crewmembers[i].required = this.additionalCrewmember8IsRequired;
      }

      const res = await this.$store.dispatch("updateCrewmemberRequirements", {
        _id: this.aicraftEvent._id,
        crewmembers,
      });

      if (res.status !== 200) {
        this.snackbarColor = "red";
        this.snackbarText =
          "An error occurred while updating the crew requirements";
        this.snackbarVisible = true;
        return;
      }

      this.showCrewRequirementsDialog = false;
    },

    handleAssignCrew() {
      const flight = {
        ...this.flight,
        crewmembers: this.crewmembers,
      };

      this.$store.commit("updateFlight", flight);
      this.$router.push({ name: "FlightAssignmentBoard" });
    },

    getRowStyle(item) {
      let style = {
        backgroundColor: "#6EE7B7",
      };

      if (item.required && !item.employee) {
        style.backgroundColor = "#fca5a5";
      } else {
        if (!item.required) {
          style.backgroundColor = "#93C5FD";
        }
      }

      return style;
    },

    formatCrewmember(crewmember) {
      if (crewmember.employee) {
        return `${crewmember.employee.surname}, ${crewmember.employee.givenName}`;
      } else {
        if (crewmember.required) {
          return "Please Assign.";
        } else {
          return "Not Required";
        }
      }
    },

    formatPaxsBooked() {
      return `${this.bookedPaxAdults} + ${this.bookedPaxInfants}`;
    },

    formatPaxsActual() {
      return `${this.actualPaxAdults} + ${this.actualPaxInfants}`;
    },

    formatDelayCodes() {
      if (this.delays.length) {
        let codes = [];

        this.delays.forEach((item) => {
          codes.push(item.code);
        });

        return codes.join();
      } else {
        return "No delay codes";
      }
    },

    // findDuty(seat) {
    //   switch (seat) {
    //     case "CAPT":
    //       return "Captain";
    //     case "FO":
    //       return "First Officer";
    //     case "ACM-1":
    //       return "Additional Crewmember";

    //     case "FA-A":
    //       return "Flight Attendant A";

    //     case "FA-B":
    //       return "Flight Attendant B";

    //     case "FA-C":
    //       return "Flight Attendant ";

    //     case "FA-D":
    //       return "Flight Attendant ";

    //     case "FA-E":
    //       return "Flight Attendant ";

    //     case "FA-F":
    //       return "Flight Attendant ";

    //     case "GSC":
    //       return "Ground Security Coordinator";

    //     case "MX":
    //       return "Flight Mechanic";

    //     case "DH-1":
    //       return "Deadhead";

    //     case "DH-2":
    //       return "Deadhead";

    //     case "DH-3":
    //       return "Deadhead";

    //     case "DH-4":
    //       return "Deadhead";

    //     case "DH-5":
    //       return "Deadhead";

    //     case "DH-6":
    //       return "Deadhead";

    //     case "ACM-2":
    //       return "Additional Crewmember";

    //     case "ACM-3":
    //       return "Additional Crewmember";

    //     case "ACM-4":
    //       return "Additional Crewmember";

    //     case "ACM-5":
    //       return "Additional Crewmember";

    //     case "ACM-6":
    //       return "Additional Crewmember";

    //     case "ACM-7":
    //       return "Additional Crewmember";

    //     case "ACM-8":
    //       return "Additional Crewmember";

    //     default:
    //       return "Undefined";
    //   }
    // },

    handleRemoveEmployee(crewmember, e) {
      if (this.selectedStatus !== "SCHEDULED") {
        return;
      }

      e.stopPropagation();

      const i = this.crewmembers.findIndex((item) => {
        return crewmember.seat === item.seat;
      });

      let cm = {
        seat: crewmember.seat,
        duty: crewmember.duty,
        required: crewmember.required ? true : false,
      };

      if (i >= 0) {
        this.crewmembers.splice(i, 1, cm);
      }
    },

    // handleToggleRequired(crewmember) {
    //   const i = this.crewmembers.findIndex((item) => {
    //     return crewmember.seat === item.seat;
    //   });

    //   if (i >= 0) {
    //     this.crewmembers[i].required = !this.crewmembers[i].required;
    //   }
    // },

    handleAddDelay() {
      if (this.delayList.length >= 5) {
        this.snackbarColor = "red";
        this.snackbarText = "The maximum number of delays is 5";
        this.snackbarVisible = true;
        return;
      }

      const i = this.delayList.findIndex((item) => {
        return item.code === this.selectedDelay.code;
      });

      if (i >= 0) {
        this.delayList.splice(i, 1, { ...this.selectedDelay });
      } else {
        this.delayList.push({ ...this.selectedDelay });
      }
    },

    handleRemoveDelay(code) {
      const i = this.delayList.findIndex((item) => {
        return item.code === code;
      });

      if (i >= 0) {
        this.delayList.splice(i, 1);
      }
    },

    handleShowDelaysDialog() {
      this.delayList = [...this.delays];
      this.selectedDelay = { ...this.delayOptions[0] };
      this.showDelaysDialog = true;
    },

    handleUpdateDelays() {
      this.delays = [...this.delayList];
      this.showDelaysDialog = false;
    },

    handleShowPaxsDialog() {
      this.actualPaxAdultsInput = this.actualPaxAdults;
      this.actualPaxInfantsInput = this.actualPaxInfants;
      this.bookedPaxAdultsInput = this.bookedPaxAdults;
      this.bookedPaxInfantsInput = this.bookedPaxInfants;

      this.showPaxsDialog = true;
    },

    handleUpdatePaxs() {
      if (!this.isValidBookedPaxCount || !this.isValidActualPaxCount) {
        return;
      }
      this.actualPaxAdults = this.actualPaxAdultsInput;
      this.actualPaxInfants = this.actualPaxInfantsInput;
      this.bookedPaxAdults = this.bookedPaxAdultsInput;
      this.bookedPaxInfants = this.bookedPaxInfantsInput;

      this.showPaxsDialog = false;
    },

    handleShowFuelDialog() {
      if (!this.enableFuelUplift) {
        return;
      }
      this.fuelTicketList = [...this.fuelTickets];

      this.fuelTicketVendor = "";
      this.fuelTicketInvoiceNumber = "";
      this.fuelTicketQuantity = "";

      this.showFuelDialog = true;
    },

    handleAddFuelTicket() {
      if (this.fuelTicketList.length >= 10) {
        this.snackbarColor = "red";
        this.snackbarText = "The maximum number of fuel invoices is 10";
        this.snackbarVisible = true;
        return;
      }

      if (!this.isValidFuelTicket) {
        return;
      }

      if (this.totalGallons + this.fuelTicketQuantity > 7000) {
        this.snackbarColor = "red";
        this.snackbarText = "The total gallons cannot exceed 7000";
        this.snackbarVisible = true;
        return;
      }

      const i = this.fuelTicketList.findIndex((item) => {
        return item.invoiceNumber === this.fuelTicketInvoiceNumber;
      });

      const fuelTicket = {
        vendor: this.fuelTicketVendor.trim(),
        invoiceNumber: this.fuelTicketInvoiceNumber.trim(),
        quantity: this.fuelTicketQuantity,
      };

      if (i >= 0) {
        this.fuelTicketList.splice(i, 1, fuelTicket);
      } else {
        this.fuelTicketList.push(fuelTicket);
      }

      this.fuelTicketInvoiceNumber = "";
      this.fuelTicketQuantity = 0;
    },

    handleRemoveFuelTicket(invoiceNumber) {
      const i = this.fuelTicketList.findIndex((item) => {
        return item.invoiceNumber === invoiceNumber;
      });

      if (i >= 0) {
        this.fuelTicketList.splice(i, 1);
        this.fuelTicketVendor = "";
        this.fuelTicketInvoiceNumber = "";
        this.fuelTicketQuantity = "";
      }
    },

    handleUpdateFuel() {
      if (!this.fuelTicketList.length) {
        return;
      }

      let uplift = 0;

      this.fuelTicketList.forEach((item) => {
        uplift += item.quantity;
      });

      this.fuelTickets = [...this.fuelTicketList];

      this.fuelUplift = uplift;

      this.showFuelDialog = false;
    },

    handleShowComputedTimesErrors() {
      this.showComputedTimesErrorsDialog = true;
    },

    handleUpdateStatus() {
      switch (this.selectedStatus) {
        case "AIRBORNE":
          this.onTimeString = null;

          this.enableOffTime = true;
          this.enableOnTime = false;
          this.enableFuelRemaining = false;
          this.enableFuelUplift = false;

          this.outDynamicLabel = "OUT";
          this.offDynamicLabel = "OFF";
          this.onDynamicLabel = "ON";
          this.inDynamicLabel = "ETA";

          this.arrivalFuel = 0;
          break;

        case "BLOCKED-IN":
          this.enableOffTime = true;
          this.enableOnTime = true;
          this.enableFuelRemaining = true;
          this.enableFuelUplift = false;

          this.outDynamicLabel = "OUT";
          this.offDynamicLabel = "OFF";
          this.onDynamicLabel = "ON";
          this.inDynamicLabel = "IN";
          break;

        case "BLOCKED-OUT":
          this.offTimeString = null;
          this.onTimeString = null;

          this.enableOffTime = false;
          this.enableOnTime = false;
          this.enableFuelRemaining = false;
          this.enableFuelUplift = false;

          this.outDynamicLabel = "OUT";
          this.offDynamicLabel = "OFF";
          this.onDynamicLabel = "ON";
          this.inDynamicLabel = "ETA";

          this.arrivalFuel = 0;
          break;

        default: //"SCHEDULED"
          this.offTimeString = null;
          this.onTimeString = null;

          this.enableOffTime = false;
          this.enableOnTime = false;
          this.enableFuelRemaining = false;
          this.enableFuelUplift = true;

          this.outDynamicLabel = "ETD";
          this.offDynamicLabel = "OFF";
          this.onDynamicLabel = "ON";
          this.inDynamicLabel = "ETA";

          this.arrivalFuel = 0;
          break;
      }
    },

    async handleUpdateFlight() {
      if (!this.isValid) {
        return;
      }

      try {
        this.loading = true;

        let res = await this.$store.dispatch("precheckAircraftEvent", {
          _id: this._id,
          aircraftId: this.selectedAircraft._id,
          _start: this.computedTimes._out,
          _end: this.computedTimes._in,
        });

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        const conflicts = res.data.conflicts;

        if (conflicts.length === 0) {
          let unscheduledEvent;
          let destinationId;

          // unscheduledEventOptions
          //   [
          //     "None",
          //     "Air Turn Back (Airborne)",
          //     "Air Turn Back (Blocked-In)",
          //     "Block Turn Back",
          //     "Diversion (Airborne)",
          //     "Diversion (Blocked-In)",
          //   ]

          //unscheduledEvent = ""

          let delays = [];
          const allowedDelay = 15;

          function delayMinutes(out, std) {
            return (new Date(out).getTime() - new Date(std).getTime()) / 60000;
          }

          const status = this.selectedStatus;

          function getTakeoffBy() {
            let takeoffBy = {
              seat: "CAPT",
              employeeId: null,
            };

            if (
              (status === "AIRBORNE" || status === "BLOCKED-IN") &&
              !this.noTakeoff
            ) {
              const i = this.crewmembers.findIndex((item) => {
                return item.seat === this.takeoffBySeat;
              });

              if (i >= 0) {
                takeoffBy = {
                  seat: this.takeoffBySeat,
                  employeeId: this.crewmembers[i].employee._id,
                };
              }
            }

            return takeoffBy;
          }

          function getLandingBy() {
            let landingBy = {
              seat: "CAPT",
              employeeId: null,
            };

            if (status === "BLOCKED-IN" && !this.noTakeoff) {
              const i = this.crewmembers.findIndex((item) => {
                return item.seat === this.landingBySeat;
              });

              if (i >= 0) {
                landingBy = {
                  seat: this.landingBySeat,
                  employeeId: this.crewmembers[i].employee._id,
                };
              }
            }

            return landingBy;
          }

          switch (this.status) {
            case "AIRBORNE":
              if (
                this.computedDestination._id !== this.plannedDestination._id
              ) {
                if (this.computedDestination._id === this.computedOrigin._id) {
                  unscheduledEvent = "Air Turn Back (Airborne)";
                } else {
                  unscheduledEvent = "Diversion (Airborne)";
                }
                destinationId = this.computedDestination._id;
              } else {
                destinationId = this.computedDestination._id;
                unscheduledEvent = "None";
              }

              if (delayMinutes(this._start, this._std) > allowedDelay) {
                delays = this.delays;
              }

              break;

            case "BLOCKED-IN":
              if (this.noTakeoff) {
                destinationId = this.computedOrigin._id;
                unscheduledEvent = "Block Turn Back";
              } else {
                if (
                  this.computedDestination._id !== this.plannedDestination._id
                ) {
                  if (
                    this.computedDestination._id === this.computedOrigin._id
                  ) {
                    unscheduledEvent = "Air Turn Back (Blocked-In)";
                  } else {
                    unscheduledEvent = "Diversion (Blocked-In)";
                  }
                  destinationId = this.computedDestination._id;
                } else {
                  destinationId = this.computedDestination._id;
                  unscheduledEvent = "None";
                }
              }

              if (delayMinutes(this._start, this._std) > allowedDelay) {
                delays = this.delays;
              }

              break;

            case "BLOCKED-OUT":
              destinationId = this.computedDestination._id;
              unscheduledEvent = "None";

              if (delayMinutes(this._start, this._std) > allowedDelay) {
                delays = this.delays;
              }
              break;

            default: //"SCHEDULED"
              destinationId = this.computedDestination._id;
              unscheduledEvent = "None";
              break;
          }

          const flight = {
            _id: this._id,
            type: "Flight",
            status,
            flightRules: this.selectedFlightRules,
            unscheduledEvent,
            logpage: this.logpage,
            aircraftId: this.selectedAircraft._id,
            customerId: this.selectedCustomer._id,
            flightNumber: this.flightNumber.toUpperCase(),
            actualPaxAdults: this.actualPaxAdults,
            actualPaxInfants: this.actualPaxInfants,
            bookedPaxAdults: this.bookedPaxAdults,
            bookedPaxInfants: this.bookedPaxInfants,
            fuelTickets: this.fuelTickets,
            departureFuel: this.departureFuel,
            arrivalFuel: this.arrivalFuel,
            parkingDeparture: this.parkingDeparture
              ? this.parkingDeparture.toUpperCase()
              : "",
            parkingArrival: this.parkingArrival
              ? this.parkingArrival.toUpperCase()
              : "",
            hidden: this.hidden ? true : false,
            noTakeoff: this.noTakeoff ? true : false,
            takeoffBy: getTakeoffBy(),
            landingBy: getLandingBy(),
            deiced: this.deiced ? true : false,
            needsUsCrew: this.needsUsCrew ? true : false,
            originId: this.computedOrigin._id,
            destinationId,
            _start: this.computedTimes._out,
            _end: this.computedTimes._in,
            _off: this.computedTimes._off,
            _on: this.computedTimes._on,
            crewmembers: this.crewmembers,
            delays,
            duration: this.computedTimes.block,
            remarks: this.remarks ? this.remarks : null,
            updatedById: this.auth.employee._id,
          };

          res = await this.$store.dispatch("saveAircraftEvent", flight);

          if (res.status !== 200) {
            this.snackbarColor = "red";
            this.snackbarText = res.message;
            this.snackbarVisible = true;
            return;
          }

          //TODO add option to go back to the previous page
          this.handleGoBack();
        } else {
          this.snackbarColor = "red";
          this.snackbarText = `Unable to save schedule due to conlicts.`;
          this.snackbarVisible = true;

          setTimeout(() => {
            this.snackbarVisible = false;
          }, 3000);
        }
      } catch (error) {
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      } finally {
        this.loading = false;
      }
    },

    handleGoBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.crewmembers-container {
  display: grid;
  grid-template-columns: repeat(27, 1fr);
  row-gap: 5px;
  margin: 5px auto;
  padding: 10px 0;
  border: 1px solid gray;
  border-radius: 5px;
}

.seat {
  width: 60px;
  height: 70px;
  border: 1px solid gray;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
}

.seat i {
  font-size: 1.8rem;
  color: #3b82f6;
}

.capt-seat {
  grid-column: 2/4;
  grid-row: 2;
}

.fo-seat {
  grid-column: 2/4;
  grid-row: 1;
}

.acm1-seat {
  grid-column: 4/6;
  grid-row: 2;
}

.faa-seat {
  grid-column: 8/10;
  grid-row: 2;
}

.fab-seat {
  grid-column: 8/10;
  grid-row: 1;
}

.mx-seat {
  grid-column: 10/12;
  grid-row: 2;
}

.gsc-seat {
  grid-column: 10/12;
  grid-row: 1;
}

.dh1-seat {
  grid-column: 12/14;
  grid-row: 2;
}

.dh2-seat {
  grid-column: 12/14;
  grid-row: 1;
}

.dh3-seat {
  grid-column: 14/16;
  grid-row: 2;
}

.dh4-seat {
  grid-column: 14/16;
  grid-row: 1;
}

.dh5-seat {
  grid-column: 16/18;
  grid-row: 2;
}

.dh6-seat {
  grid-column: 16/18;
  grid-row: 1;
}

.acm2-seat {
  grid-column: 18/20;
  grid-row: 2;
}

.acm3-seat {
  grid-column: 18/20;
  grid-row: 1;
}

.acm4-seat {
  grid-column: 20/22;
  grid-row: 2;
}

.acm5-seat {
  grid-column: 20/22;
  grid-row: 1;
}

.acm6-seat {
  grid-column: 22/24;
  grid-row: 2;
}

.acm7-seat {
  grid-column: 22/24;
  grid-row: 1;
}

.fac-seat {
  grid-column: 24/26;
  grid-row: 2;
}

.fad-seat {
  grid-column: 24/26;
  grid-row: 1;
}

.fae-seat {
  grid-column: 26/28;
  grid-row: 2;
}

.faf-seat {
  grid-column: 26/28;
  grid-row: 1;
}

textarea {
  resize: none;
}

.aircraft-events-table {
  color: #fff;
  font-size: 1.3rem;
  padding: 0 5px;
  grid-column: 1/13;
}

.grid-card {
  box-sizing: border-box;
  border: 1px solid gray;
  border-radius: 3px;
  padding: 0 5px;
  margin-bottom: 1px;
  background-color: #cef6f5;
  color: black;
  cursor: pointer;
}

.footer {
  color: #fff;
  font-size: 1.3rem;
  margin-top: 5px;
  padding: 0 5px;
  grid-column: 1/13;
}

.warning {
  background-color: red;
  color: white;
}

.edit-mode {
  background-color: yellowgreen;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.aircraft-operational {
  background-color: #f5d0a9;
}

.is-invalid {
  background-color: #fef08a;
}

.fuel-ticket {
  box-sizing: border-box;
  border: 1px solid gray;
  border-radius: 3px;
  padding: 0 5px;
  margin-bottom: 1px;
  background-color: #cef6f5;
  color: black;
  cursor: pointer;
}
</style>
