import { createWebHistory, createRouter } from "vue-router";
import { reactive } from "vue";
import store from "@/store";

import About from "../views/About.vue";
import Manuals from "../views/Manuals.vue";
import Home from "../views/Home.vue";
import FlightBoard from "../views/FlightBoard.vue";
import Administration from "../views/Administration.vue";
import EmployeeCreate from "../views/EmployeeCreate.vue";
import EmployeeDetails from "../views/EmployeeDetails.vue";
import Bidlines from "../views/Bidlines.vue";
import FlightAssignmentBoard from "../views/FlightAssignmentBoard.vue";
import EmployeesBoard from "../views/EmployeesBoard.vue";
import MyApp from "../views/MyApp.vue";
import SecurityGroups from "../views/SecurityGroups.vue";
import SecurityGroup from "../views/SecurityGroup.vue";
import Planning from "../views/Planning.vue";
import FlightView from "../views/FlightView.vue";
import EmployeeTrainingRecords from "../views/EmployeeTrainingRecords.vue";
import TrainingRecords from "../views/TrainingRecords.vue";
import Airports from "../views/Airports.vue";
import PasswordReset from "../views/PasswordReset.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/airports",
    name: "Airports",
    component: Airports,
  },
  {
    path: "/password-reset/:token",
    name: "PasswordReset",
    component: PasswordReset,
    props: true,
  },

  {
    path: "/manuals",
    name: "Manuals",
    component: Manuals,
  },
  {
    path: "/training-records",
    name: "TrainingRecords",
    component: TrainingRecords,
  },
  {
    path: "/employee/training-records",
    name: "EmployeeTrainingRecords",
    component: EmployeeTrainingRecords,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },

  {
    path: "/planning",
    name: "Planning",
    component: Planning,
  },
  {
    path: "/security-groups",
    name: "SecurityGroups",
    component: SecurityGroups,
  },
  {
    path: "/security-group",
    name: "SecurityGroup",
    component: SecurityGroup,
  },
  {
    path: "/flight-board",
    name: "FlightBoard",
    component: FlightBoard,
  },
  {
    path: "/flight",
    name: "FlightView",
    component: FlightView,
  },

  {
    path: "/flight-assignment-board",
    name: "FlightAssignmentBoard",
    component: FlightAssignmentBoard,
  },
  {
    path: "/employees-board",
    name: "EmployeesBoard",
    component: EmployeesBoard,
  },

  {
    path: "/my-app",
    name: "MyApp",
    component: MyApp,
  },
  {
    path: "/bidlines",
    name: "Bidlines",
    component: Bidlines,
  },
  {
    path: "/administration",
    name: "Administration",
    component: Administration,
  },
  {
    path: "/employee-create",
    name: "EmployeeCreate",
    component: EmployeeCreate,
  },
  {
    path: "/employee-update",
    name: "EmployeeDetails",
    component: EmployeeDetails,
  },
];

// Create a reactive variable to hold the previous route name
const previousRoute = reactive({ name: null });

// beforeEnter: (to, from, next) => {
//   if (store.state.user) {
//     next();
//   } else {
//     next({
//       name: "home",
//     });
//   }
// },

const router = createRouter({
  history: createWebHistory(),
  routes,
});

function isAuthenticated() {
  return store.state.user;
}

router.beforeEach((to, from, next) => {
  previousRoute.name = from.name; // Set the previous route name
  if ((to.name !== "Home" || to.name !== "PasswordReset") && !isAuthenticated)
    next({ name: "Home" });
  else next();
});

export { router, previousRoute };
