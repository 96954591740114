<template>
  <!-- #region NAVBAR -->
  <nav>
    <ul class="nav-wrapper">
      <li class="icon" @click="handleGoBack">
        <a href="#">
          <span class="tooltip">Go Back</span>
          <span><i class="fa-solid fa-arrow-left text-white ml-5"> </i></span>
        </a>
      </li>

      <li class="icon">
        <RouterLink to="/">
          <span class="tooltip">Home</span>
          <span><i class="fa-solid fa-house text-white ml-3"> </i></span
        ></RouterLink>
      </li>

      <li>
        <a href="#"> </a>
      </li>
    </ul>
  </nav>
  <!-- #endregion -->

  <!-- SNACKBAR -->
  <r-snackbar
    v-if="snackbarVisible"
    :backgroundColor="snackbarColor"
    :message="snackbarText"
    :timeout="3000"
    @close="snackbarVisible = false"
  >
  </r-snackbar>

  <div class="h-screen-minus-toolbar overflow-auto p-5">
    <!-- COMPANY ID  -->
    <r-modal
      v-if="showCompanyIdModal"
      @close="[(showCompanyIdModal = false), (newCompanyId = null)]"
    >
      <div v-if="newCompanyId" class="p-5">
        <div class="flex justify-between mb-5">
          <div class="text-2xl">Company ID</div>
          <i
            @click="[(showCompanyIdModal = false), (newCompanyId = null)]"
            class="fa-solid fa-xmark cursor-pointer"
          ></i>
        </div>

        <div class="flex mb-2">
          <div>
            <p class="pl-3 text-sm text-gray-500">Employee ID</p>
            <input
              class="block w-32 rounded-md border-0 py-2 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              :class="{ missing: !newCompanyId.number }"
              type="text"
              maxlength="40"
              v-model="newCompanyId.number"
            />
          </div>

          <div class="ml-2">
            <p class="pl-3 text-sm text-gray-500">Expiration Date</p>
            <input
              class="block w-48 py-2 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="date"
              v-model="newCompanyId.expirationDate"
            />
          </div>
        </div>

        <div class="p-3 border border-gray-500 rounded-md mb-2">
          <div class="flex justify-between mb-2">
            <div class="text-xl">Profile Image</div>
            <i class="fa-solid fa-image-user ml-3 text-blue-500"></i>
          </div>

          <input
            @change="handleOnFileOneChange($event)"
            type="file"
            accept="image/png, image/jpeg"
          />

          <p class="mt-1 text-sm text-gray-500">
            Image should be of type png or jpeg and smaller than 1,000 KB
          </p>

          <div
            class="flex justify-between border border-gray-500 rounded-md p-2 my-2"
            v-if="fileOne"
          >
            <div>{{ `Size: ${fileOneSize} KB` }}</div>
            <div>{{ `Type: ${fileOneType}` }}</div>
            <button
              v-if="fileOne"
              @click="fileOne = null"
              class="ml-1 btn-text"
            >
              Reset Image
            </button>
          </div>
        </div>

        <div class="flex justify-end">
          <div class="flex justify-between">
            <button
              @click="[(showCompanyIdModal = false), (newCompanyId = null)]"
              class="btn bg-gray-500 text-white"
            >
              Cancel
            </button>

            <button
              @click="handleSaveCompanyId"
              class="ml-1 btn bg-blue-500 text-white"
            >
              Save Information
            </button>
          </div>
        </div>
      </div>
    </r-modal>

    <!-- PASSPORT -->
    <r-modal
      v-if="showPassportModal"
      @close="[(showPassportModal = false), (newPassport = null)]"
    >
      <div class="p-5" v-if="newPassport">
        <div class="flex justify-between mb-5">
          <div class="text-2xl">Passport</div>
          <i
            @click="[(showPassportModal = false), (newPassport = null)]"
            class="fa-solid fa-xmark cursor-pointer"
          ></i>
        </div>

        <div class="mb-2">
          <p class="pl-3 text-sm text-gray-500">Country</p>
          <select
            class="block w-full py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
            v-model="newPassport.country"
          >
            <option
              v-for="(country, index) in countries"
              :key="index"
              :value="country"
            >
              {{ country }}
            </option>
          </select>
        </div>

        <div class="flex">
          <div>
            <p class="pl-3 text-sm text-gray-500">Number</p>
            <input
              class="block w-48 rounded-md border-0 py-2 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              :class="{
                missing: !newPassport.number || newPassport.number.length < 4,
              }"
              type="text"
              maxlength="20"
              v-model="newPassport.number"
            />
          </div>

          <div class="ml-2">
            <p class="pl-3 text-sm text-gray-500">Expiration Date</p>
            <input
              class="block w-48 py-2 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="date"
              v-model="newPassport.expirationDate"
            />
          </div>
        </div>

        <div class="flex justify-end mt-3">
          <button
            @click="[(showPassportModal = false), (newPassport = null)]"
            class="btn bg-gray-500 text-white"
          >
            Cancel
          </button>
          <button
            @click="handleAddPassport"
            class="ml-1 btn bg-blue-500 text-white"
            :class="{ disabled: !isValidPassport }"
          >
            Save Information
          </button>
        </div>
      </div>
    </r-modal>

    <!-- FAA MEDICAL -->
    <r-modal
      v-if="showMedicalCertificateModal"
      @close="
        [(showMedicalCertificateModal = false), (newMedicalCertificate = null)]
      "
    >
      <div class="p-5" v-if="newMedicalCertificate">
        <div class="flex justify-between mb-5">
          <div class="text-2xl">FAA Medical</div>
          <i
            @click="
              [
                (showMedicalCertificateModal = false),
                (newMedicalCertificate = null),
              ]
            "
            class="fa-solid fa-xmark cursor-pointer"
          ></i>
        </div>

        <div class="mb-2">
          <p class="pl-3 text-sm text-gray-500">Class</p>
          <select
            class="block w-full py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
            v-model="newMedicalCertificate.class"
          >
            <option
              v-for="(classOption, index) in classOptions"
              :key="index"
              :value="classOption"
            >
              {{ classOption }}
            </option>
          </select>
        </div>

        <div class="flex">
          <div>
            <p class="pl-3 text-sm text-gray-500">Date of Examination</p>
            <input
              class="block w-48 py-2 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="date"
              v-model="newMedicalCertificate.dateOfExamination"
            />
          </div>

          <div class="ml-2">
            <p class="pl-3 text-sm text-gray-500">Valid Until</p>
            <input
              class="block w-48 py-2 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="date"
              v-model="newMedicalCertificate.validUntil"
            />
          </div>
        </div>

        <div class="flex justify-end mt-3">
          <button
            @click="
              [
                (showMedicalCertificateModal = false),
                (newMedicalCertificate = null),
              ]
            "
            class="btn bg-gray-500 text-white"
          >
            Cancel
          </button>
          <button
            @click="handleAddMedicalCertificate"
            class="ml-1 btn bg-blue-500 text-white"
            :class="{ disabled: !isValidMedicalCertificate }"
          >
            Save Information
          </button>
        </div>
      </div>
    </r-modal>

    <!-- FAA CERTIFICATE -->
    <r-modal
      v-if="showFaaCertificateModal"
      @close="[(showFaaCertificateModal = false), (newFaaCertificate = null)]"
    >
      <div class="p-5" v-if="newFaaCertificate">
        <div class="flex justify-between mb-5">
          <div class="text-2xl">FAA Certificate</div>
          <i
            @click="
              [(showFaaCertificateModal = false), (newFaaCertificate = null)]
            "
            class="fa-solid fa-xmark cursor-pointer"
          ></i>
        </div>

        <div class="flex items-center mb-2">
          <div>
            <p class="pl-3 text-sm text-gray-500">Class</p>
            <select
              class="block w-64 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="newFaaCertificate.type"
            >
              <option
                v-for="(typeOption, index) in typeOptions"
                :key="index"
                :value="typeOption"
              >
                {{ typeOption }}
              </option>
            </select>
          </div>

          <div class="ml-3">
            <p class="pl-3 text-sm text-gray-500">Temporary Certificate</p>
            <div class="flex items-center">
              <SwitchGroup as="div" class="flex items-center ml-5">
                <Switch
                  v-model="newFaaCertificate.isTemporary"
                  :class="[
                    newFaaCertificate.isTemporary
                      ? 'bg-red-500'
                      : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                  ]"
                >
                  <span
                    aria-hidden="true"
                    :class="[
                      newFaaCertificate.isTemporary
                        ? 'translate-x-5'
                        : 'translate-x-0',
                      'pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                    ]"
                  />
                </Switch>
              </SwitchGroup>
              <div class="ml-2">This Certificate is Temporary</div>
            </div>
          </div>
        </div>

        <div class="flex">
          <div>
            <p class="pl-3 text-sm text-gray-500">Number</p>
            <input
              class="block w-48 rounded-md border-0 py-2 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              :class="{
                missing:
                  !newFaaCertificate.number ||
                  newFaaCertificate.number.length < 4,
              }"
              type="text"
              maxlength="20"
              v-model="newFaaCertificate.number"
            />
          </div>

          <div class="ml-3" v-if="newFaaCertificate.isTemporary">
            <p class="pl-3 text-sm text-gray-500">Valid Until</p>
            <input
              class="block w-48 py-2 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="date"
              v-model="newFaaCertificate.validUntil"
            />
          </div>
        </div>

        <div class="flex justify-end mt-3">
          <button
            @click="
              [(showFaaCertificateModal = false), (newFaaCertificate = null)]
            "
            class="btn bg-gray-500 text-white"
          >
            Cancel
          </button>
          <button
            @click="handleAddFaaCertificate"
            class="ml-1 btn bg-blue-500 text-white"
            :class="{ disabled: !isValidFaaCertificate }"
          >
            Save Information
          </button>
        </div>
      </div>
    </r-modal>

    <r-spinner v-if="loading"> </r-spinner>

    <div v-if="!loading">
      <div class="flex justify-between">
        <div>
          <div class="text-2xl text-blue-700">
            {{ `${surname}, ${givenName}` }}
          </div>
          <div class="text-xl text-blue-700">
            {{ `Emp. No ${companyId.number}` }}
          </div>
        </div>
        <figure class="profile-pic-figure" @click="handleShowCompanyIdModal">
          <img
            :src="imageUrl"
            alt="Profile Image"
            title="Profile Image"
            width="150"
            height="150"
          />
        </figure>
      </div>

      <form @submit.prevent="onSubmit">
        <!-- ROW 1 -->
        <div class="flex gap-2 items-center flex-wrap mb-2">
          <div>
            <p class="pl-3 text-sm text-gray-500">First Name</p>
            <input
              class="block w-64 rounded-md border-0 pl-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
              type="text"
              maxlength="40"
              v-model="givenName"
            />
          </div>

          <div>
            <p class="pl-3 text-sm text-gray-500">Last Name</p>
            <input
              class="block w-64 rounded-md border-0 pl-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="text"
              maxlength="40"
              v-model="surname"
            />
          </div>

          <div>
            <p class="pl-3 text-sm text-gray-500">Date of Hire</p>
            <input
              class="block w-48 py-2 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="date"
              v-model="dateOfHire"
            />
          </div>
          <div
            class="employee-id cursor-pointer"
            @click="handleShowCompanyIdModal"
          >
            <p class="pl-3 text-sm text-gray-500">Employee ID</p>
            <div
              class="block w-32 rounded-md border-0 py-2 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            >
              {{ companyId.number }}
            </div>
          </div>

          <div class="cursor-pointer" @click="handleShowCompanyIdModal">
            <p class="pl-3 text-sm text-gray-500">ID Expiration Date</p>

            <div
              class="block w-32 py-2 rounded-md border-0 text-center text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
            >
              {{ companyId.expirationDate }}
            </div>
          </div>

          <div>
            <p class="pl-3 text-sm text-gray-500">Base</p>
            <input
              class="block w-16 rounded-md border-0 py-2 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              :class="{ missing: !baseIata }"
              type="text"
              maxlength="4"
              v-model="baseIata"
            />
          </div>

          <div>
            <p class="pl-3 text-sm text-gray-500">Seniority No.</p>
            <input
              class="block w-24 py-2 rounded-md border-0 text-center text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              :class="{ missing: !seniorityNumber }"
              type="number"
              step="1"
              min="0"
              max="99999"
              v-model="seniorityNumber"
            />
          </div>
        </div>

        <!-- ROW 2 -->
        <div class="flex gap-2 items-center flex-wrap mb-2">
          <div>
            <p class="pl-3 text-sm text-gray-500">Job Title</p>
            <select
              class="block w-64 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
              :class="{ missing: !jobTitle }"
              v-model="jobTitle"
            >
              <option
                v-for="(jobTitle, index) in jobTitles"
                :key="index"
                :value="jobTitle"
              >
                {{ jobTitle }}
              </option>
            </select>
          </div>

          <div>
            <p class="pl-3 text-sm text-gray-500">Status</p>
            <select
              class="block w-32 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
              v-model="status"
            >
              <option
                v-for="(sts, index) in statusOptions"
                :key="index"
                :value="sts"
              >
                {{ sts }}
              </option>
            </select>
          </div>

          <div>
            <p class="pl-3 text-sm text-gray-500">Security Group</p>
            <select
              class="block w-96 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
              v-model="securityGroupId"
            >
              <option
                v-for="securityGroup in securityGroups"
                :key="securityGroup._id"
                :value="securityGroup._id"
              >
                {{ securityGroup.name }}
              </option>
            </select>
          </div>
        </div>

        <!-- ROW 3 -->
        <div class="flex gap-2 items-center flex-wrap mb-2">
          <div>
            <p class="pl-3 text-sm text-gray-500">Email</p>
            <input
              class="block w-80 rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="text"
              maxlength="100"
              v-model="mail"
            />
          </div>

          <div>
            <p class="pl-3 text-sm text-gray-500">Mobile Phone</p>
            <input
              class="block w-48 rounded-md border-0 text-center py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="text"
              maxlength="40"
              v-model="primaryPhone"
            />
          </div>

          <div>
            <p class="pl-3 text-sm text-gray-500">Alternate Phone</p>
            <input
              class="block w-48 rounded-md border-0 text-center py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="text"
              maxlength="40"
              v-model="secondaryPhone"
            />
          </div>
        </div>

        <!-- ROW 4 -->
        <div class="flex gap-2 items-center flex-wrap mb-2">
          <div>
            <p class="pl-3 text-sm text-gray-500">Emergency Contact Name</p>
            <input
              class="block w-48 rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="text"
              maxlength="40"
              v-model="emergencyContact.name"
            />
          </div>

          <div>
            <p class="pl-3 text-sm text-gray-500">Emergency Contact number</p>
            <input
              class="block w-48 rounded-md border-0 text-center pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="text"
              maxlength="40"
              v-model="emergencyContact.phone"
            />
          </div>

          <div>
            <p class="pl-3 text-sm text-gray-500">Emergency Contact Type</p>
            <input
              class="block w-48 rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="text"
              maxlength="40"
              v-model="emergencyContact.relationship"
            />
          </div>
        </div>
        <!-- ROW 5 -->
        <div class="flex gap-2 items-center flex-wrap mb-2">
          <div>
            <p class="pl-3 text-sm text-gray-500">Street Address</p>
            <input
              class="block w-64 rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="text"
              maxlength="40"
              v-model="address.street"
            />
          </div>

          <div>
            <p class="pl-3 text-sm text-gray-500">City</p>
            <input
              class="block w-48 rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="text"
              maxlength="40"
              v-model="address.city"
            />
          </div>

          <div>
            <p class="pl-3 text-sm text-gray-500">State</p>
            <input
              class="block w-16 rounded-md border-0 text-center py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="text"
              maxlength="2"
              v-model="address.state"
            />
          </div>

          <div>
            <p class="pl-3 text-sm text-gray-500">ZIP Code</p>
            <input
              class="block w-32 rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="text"
              maxlength="10"
              v-model="address.postalCode"
            />
          </div>

          <div>
            <p class="pl-3 text-sm text-gray-500">Country</p>
            <select
              class="block w-64 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
              v-model="address.country"
            >
              <option
                v-for="(country, index) in countries"
                :key="index"
                :value="country"
              >
                {{ country }}
              </option>
            </select>
          </div>
        </div>
      </form>

      <!-- Line -->
      <div class="my-2 border-b border-gray-400"></div>

      <div class="ml-3 font-black text-lg">Documents</div>
      <!-- PASSPORT -->
      <div
        class="border border-gray-500 rounded-md py-2 px-3 my-1 cursor-pointer"
        :class="{ 'bg-yellow-200': passport?.status !== 'OK' }"
        @click="handleShowPassportModal"
      >
        <div>
          <div class="flex justify-between mb-2">
            <div>
              <i class="fa-solid fa-passport ml-3 text-blue-800"></i>
              <span class="ml-3 text-lg">Passport</span>
            </div>

            <i
              @click="handleRemovePassport($event)"
              class="fa-solid fa-trash-can cursor-pointer"
            ></i>
          </div>

          <div v-if="passport">
            <div class="flex gap-5 items-center">
              <div>{{ `Number: ${passport.number}` }}</div>
              <div>{{ `Country: ${passport.country}` }}</div>
              <div>{{ `Expiration Date: ${passport.expirationDate}` }}</div>
              <div v-if="passport.status !== 'OK'">
                <i class="fa-solid fa-exclamation-triangle"></i>
                {{ `Status: ${passport.status}` }}
              </div>
            </div>
          </div>
          <div v-else>
            <div class="text-sm text-gray-500">Employee has no passport</div>
          </div>
        </div>
      </div>

      <!-- MEDICAL CERTIFICATE -->
      <div
        class="border border-gray-500 rounded-md py-2 px-3 my-1 cursor-pointer"
        :class="{ 'bg-yellow-200': medicalCertificate?.status !== 'OK' }"
        @click="handleShowMedicalCertificateModal"
      >
        <div>
          <div class="flex justify-between mb-2">
            <div>
              <i class="fa-solid fa-file-medical ml-3 text-red-600"></i>
              <span class="ml-3 text-lg">FAA Medical Certificate</span>
            </div>

            <i
              @click="handleRemoveMedicalCertificate($event)"
              class="fa-solid fa-trash-can cursor-pointer"
            ></i>
          </div>

          <div v-if="medicalCertificate">
            <div class="flex gap-5 items-center">
              <div>{{ `Class: ${medicalCertificate.class}` }}</div>
              <div>
                {{
                  `Date of Examination: ${medicalCertificate.dateOfExamination}`
                }}
              </div>
              <div>{{ `Valid Until: ${medicalCertificate.validUntil}` }}</div>
              <div v-if="medicalCertificate.status !== 'OK'">
                <i class="fa-solid fa-exclamation-triangle"></i>
                {{ `Status: ${medicalCertificate.status}` }}
              </div>
            </div>
          </div>
          <div v-else>
            <div class="text-sm text-gray-500">
              Employee has no FAA Medical Certificate
            </div>
          </div>
        </div>
      </div>

      <!-- FAA CERTIFICATE -->
      <div
        class="border border-gray-500 rounded-md py-2 px-3 my-1 cursor-pointer"
        :class="{ 'bg-yellow-200': faaCertificate?.status !== 'OK' }"
        @click="handleShowFaaCertificateModal"
      >
        <div>
          <div class="flex justify-between mb-2">
            <div>
              <i class="fa-solid fa-file-certificate ml-3 text-yellow-500"></i>
              <span class="ml-3 text-lg">FAA Certificate</span>
            </div>

            <i
              @click="handleRemoveMedicalCertificate($event)"
              class="fa-solid fa-trash-can cursor-pointer"
            ></i>
          </div>

          <div v-if="faaCertificate">
            <div class="flex gap-5 items-center">
              <div>{{ `Type: ${faaCertificate.type}` }}</div>
              <div>
                {{ `Number: ${faaCertificate.number}` }}
              </div>
              <div v-if="faaCertificate.isTemporary">
                {{
                  `Temporary Certificate Valid Until: ${faaCertificate.validUntil}`
                }}
              </div>
              <div v-else>
                {{ `Permanent Certificate` }}
              </div>
              <div v-if="faaCertificate.status !== 'OK'">
                <i class="fa-solid fa-exclamation-triangle"></i>
                <div>
                  {{ `Status: ${faaCertificate.status}` }}
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="text-sm text-gray-500">
              Employee has no FAA Medical Certificate
            </div>
          </div>
        </div>
      </div>

      <!-- Line -->
      <div class="my-2 border-b border-gray-400"></div>

      <!-- TRAINING RECORDS -->
      <div class="ml-3 mt-2 font-black text-lg">Training Records</div>

      <div v-if="trainingRecords.length" class="border border-gray-600">
        <!-- Data Table -->

        <div class="overflow-x-auto max-h-[200px]">
          <table
            class="table-auto w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
          >
            <thead
              class="text-xs text-gray-200 bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
            >
              <tr>
                <th scope="col" class="px-6 w-1/3 py-2">Training Name</th>
                <th scope="col" class="text-center py-2">Start</th>
                <th scope="col" class="text-center py-2">End</th>
                <th scope="col" class="text-center py-2">Valid Until</th>
                <th scope="col" class="w-[200px] py-2 text-center">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in trainingRecords"
                :key="index"
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                :style="getRowStyle(item)"
              >
                <td
                  scope="row"
                  class="px-6 py-1.5 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {{ item.name }}
                </td>
                <td class="text-center">
                  {{ formatDateTime(item._start, "UTC") }}
                </td>
                <td class="text-center">
                  {{
                    item._end ? formatDateTime(item._end, "UTC") : "In progress"
                  }}
                </td>
                <td class="text-center">
                  {{
                    !item.isPermanent
                      ? formatDate(item.validUntil, "UTC")
                      : "Permanent"
                  }}
                </td>
                <td class="text-center">{{ item.status }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        v-else
        class="text-gray-500 border border-gray-400 rounded-md text-base p-2"
      >
        No training records.
      </div>

      <div class="flex justify-end mt-2">
        <div class="flex justify-between">
          <button @click="goBack" class="btn bg-gray-500 text-white">
            Cancel
          </button>

          <button
            @click="handleSaveProfile"
            class="ml-1 btn bg-green-500 text-white"
          >
            Save Profile
          </button>
        </div>
      </div>

      <!-- WARNING  DELETE -->
      <div class="p-2 border border-gray-500 rounded-md mt-2">
        <div class="flex">
          <div>
            <p class="pl-3 text-sm text-gray-500">
              To confirm deletion, type the Employee's ID Number below
            </p>

            <div class="flex">
              <input
                class="block w-80 rounded-md border-0 py-2 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                type="text"
                maxlength="40"
                placeholder="Employee ID Number"
                v-model="confirmText"
              />

              <button
                @click="handleDeleteEmployee"
                class="ml-3 btn bg-red-500 text-white"
                :class="{ disabled: !isValidDelete }"
              >
                Delete Profile
              </button>
            </div>
          </div>
        </div>
        <p class="text-lg font-extrabold text-red-600 mt-1">WARNING</p>
        <div
          class="flex items-center border border-red-600 rounded-md p-2 bg-yellow-100"
        >
          <i class="fa-solid fa-exclamation-triangle text-red-500"></i>
          <p class="p-2 text-sm">
            Unexpected bad things will happen if you don't read this warning
            carefully.
          </p>
        </div>

        <p class="text-lg text-gray-500">
          This action cannot be undone. This will permanently delete the
          employee's profile from the system.
        </p>
      </div>

      <div class="flex justify-between text-sm text-gray-300 p-2">
        <div>{{ `ID: ${_id}` }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import { mixin } from "../mixins/mixin";
import axios from "axios";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";
import RModal from "../components/RModal.vue";
import { Switch, SwitchGroup } from "@headlessui/vue";

export default {
  name: "employeeUpdate",
  components: { RSnackbar, RSpinner, RModal, Switch, SwitchGroup },
  mixins: [mixin],
  data() {
    return {
      //-------------------------Employee
      _id: "",
      displayName: "",
      givenName: "",
      surname: "",
      jobTitle: "",
      mail: "",
      primaryPhone: "",
      secondaryPhone: "",
      emergencyContact: {
        name: "",
        phone: "",
        relationship: "",
      },
      dateOfHire: new Date().toISOString().substring(0, 10),
      seniorityNumber: 0,
      imageKey: "",
      baseIata: "",
      address: {
        street: "",
        city: "",
        state: "",
        postalCode: "",
        country: "United States",
      },
      status: "",
      securityGroupId: "",
      companyId: {
        number: "",
        expirationDate: new Date().toISOString().substring(0, 10),
        image: "employee-id-default.png",
      },
      passport: null,
      medicalCertificate: null,
      faaCertificate: null,
      trainingRecords: [],

      //-------------------------Form Options
      statusOptions: ["Active", "Inactive"],
      classOptions: ["First", "Second", "Third"],
      typeOptions: ["Pilot", "Dispatcher", "Flight Attendant", "Mechanic"],

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 3000,

      //--------------------------Data
      loading: false,
      confirmText: "",

      //--------------------------Modals
      showPassportModal: false,
      showFaaCertificateModal: false,
      showMedicalCertificateModal: false,
      showCompanyIdModal: false,

      allowedFileTypes: ["image/png", "image/jpeg", "image/jpg"],

      // Company ID
      newCompanyId: null,

      fileOne: null, //Profile Image

      // Passport
      newPassport: null,

      // Medical Certificate
      newMedicalCertificate: null,

      // FAA Certificate
      newFaaCertificate: null,

      baseImageUrl:
        "https://dev-flighttimes-employee-profile-images.s3.amazonaws.com/",

      //TEMP
      securityGroups: [
        {
          _id: "67784fcd1ba5010970c1d68e",

          name: "System Administrator",
          modules: ["Flight Board", "Assignments"],
          authorizations: [
            {
              name: "View flights",
              description:
                "Allows the user to view flights on the flight board",
              module: "Flight Board",
              department: "Flight Operations",
            },
          ],
          restrictions: [],
        },
      ],
    };
  },

  computed: {
    employee() {
      return this.$store.getters.employee;
    },

    auth() {
      return this.$store.state.auth;
    },

    imageUrl() {
      return `${this.baseBucketUrl}${this.imageKey}`;
    },

    isValidDelete() {
      return (
        this.confirmText.toUpperCase().trim() === this.employee.companyId.number
      );
    },

    isValidPassport() {
      if (this.newPassport) {
        const diff = new Date(this.newPassport.expirationDate) - new Date();
        return (
          diff > 0 &&
          this.newPassport.country &&
          this.newPassport.number &&
          this.newPassport.number.length >= 4
        );
      } else {
        return false;
      }
    },

    isValidMedicalCertificate() {
      if (this.newMedicalCertificate) {
        const diff =
          new Date(this.newMedicalCertificate.validUntil) - new Date();
        return (
          diff > 0 &&
          this.newMedicalCertificate.class &&
          this.newMedicalCertificate.dateOfExamination &&
          this.newMedicalCertificate.validUntil
        );
      } else {
        return false;
      }
    },

    isValidFaaCertificate() {
      if (this.newFaaCertificate) {
        let validUntil;

        if (this.newFaaCertificate.isTemporary) {
          validUntil = new Date(
            `${this.newFaaCertificate.validUntil}T23:59:59.999Z`
          );
        } else {
          validUntil = new Date().getTime() + 1000 * 60 * 60 * 24 * 365 * 5;
        }

        const diff = validUntil - new Date();

        return (
          diff > 0 &&
          this.newFaaCertificate.type &&
          this.newFaaCertificate.number &&
          this.newFaaCertificate.number.length >= 4
        );
      } else {
        return false;
      }
    },

    fileOneName() {
      if (this.fileOne) {
        return this.fileOne.name;
      } else {
        return null;
      }
    },

    fileOneSize() {
      if (this.fileOne) {
        return Math.floor(this.fileOne.size / 1000);
      } else {
        return 0;
      }
    },

    fileOneExtension() {
      if (this.fileOneName) {
        return this.fileOneName.substr(this.fileOneName.lastIndexOf(".") + 1);
      } else {
        return null;
      }
    },

    fileOneType() {
      if (this.fileOne) {
        return this.fileOne.type;
      } else {
        return null;
      }
    },
  },

  created() {
    this.$nextTick(() => {
      this.loading = true;
      this._id = this.employee._id;
      this.displayName = this.employee.displayName;
      this.givenName = this.employee.givenName;
      this.surname = this.employee.surname;
      this.jobTitle = this.employee.jobTitle;
      this.mail = this.employee.mail;
      this.primaryPhone = this.employee.primaryPhone;
      this.secondaryPhone = this.employee.secondaryPhone;
      this.emergencyContact = this.employee.emergencyContact
        ? { ...this.employee.emergencyContact }
        : {
            name: "",
            phone: "",
            relationship: "",
          };
      this.dateOfHire = new Date(this.employee.dateOfHire)
        .toISOString()
        .substring(0, 10);
      this.seniorityNumber = this.employee.seniorityNumber;
      this.imageKey = this.employee.imageKey;
      this.baseIata = this.employee.baseIata;
      this.address = this.employee.address;
      this.status = this.employee.status;
      this.securityGroupId = this.employee.securityGroup._id;
      this.companyId = {
        number: this.employee.companyId.number,
        expirationDate: new Date(this.employee.companyId.expirationDate)
          .toISOString()
          .substring(0, 10),
        image: this.employee.companyId.image,
      };

      this.passport = null;
      this.medicalCertificate = null;
      this.faaCertificate = null;

      //----------------------------------------------------Update training record status
      let trainingRecords = this.employee.trainingRecords;
      const now = new Date().getTime();
      const in30Days = new Date().getTime() + 30 * 24 * 60 * 60 * 1000;
      let trainingRecordsExpiredCount = 0;
      let trainingRecordsExpiringCount = 0;

      trainingRecords.forEach((record) => {
        if (!record.isPermanent) {
          if (new Date(record.validUntil).getTime() < now) {
            record.status = "Expired";
            trainingRecordsExpiredCount++;
          } else if (new Date(record.validUntil).getTime() < in30Days) {
            record.status = "Expiring in less than 30 days";
            trainingRecordsExpiringCount++;
          } else {
            record.status = "OK";
          }
        } else {
          record.status = "Permanent";
        }
      });

      this.trainingRecords = trainingRecords;
      this.trainingRecords.sort((a, b) => {
        return new Date(b._start).getTime() - new Date(a._start).getTime();
      });

      //---------------------------------------------------------- Update Document status
      let documents = this.employee.documents;

      let documentsExpiredCount = 0;
      let documentsExpiringCount = 0;

      documents.forEach((document) => {
        if (!document.isPermanent) {
          if (new Date(document.validUntil).getTime() < now) {
            document.status = "Expired";
          } else if (new Date(document.validUntil).getTime() < in30Days) {
            document.status = "Expiring in less than 30 days";
          } else {
            document.status = "OK";
          }
        }
      });

      documents.forEach((doc) => {
        if (doc.type === "Passport") {
          this.passport = {
            number: doc.number,
            country: doc.country,
            expirationDate: new Date(doc.expirationDate)
              .toISOString()
              .substring(0, 10),
            status: doc.status,
          };
        } else if (doc.type === "Medical Certificate") {
          this.medicalCertificate = {
            class: doc.class,
            dateOfExamination: new Date(doc.dateOfExamination)
              .toISOString()
              .substring(0, 10),
            validUntil: new Date(doc.validUntil).toISOString().substring(0, 10),
            status: doc.status,
          };
        } else if (doc.type === "FAA Certificate") {
          this.faaCertificate = {
            type: doc.type,
            number: doc.number,
            isTemporary: doc.isTemporary,
            validUntil: doc.isTemporary
              ? new Date(doc.validUntil).toISOString().substring(0, 10)
              : new Date().toISOString().substring(0, 10),
            status: doc.status,
          };
        }
      });

      this.loading = false;
    });
  },

  methods: {
    handleGoBack() {
      this.$router.go(-1);
    },

    getRowStyle(item) {
      if (item.status === "Expired") {
        return "background-color: #FFC7CE";
      } else if (item.status === "Expiring in less than 30 days") {
        return "background-color: #FFEB9C";
      } else {
        return "background-color: #A7F3D0";
      }
    },

    sortCertificates() {
      //TODO: Implement sorting
    },

    handleOnFileOneChange(e) {
      this.fileOne = e.target.files[0];

      this.companyIdError = null;
      let errors = [];

      if (this.fileOneSize > 100000) {
        errors.push("Image should be smaller than 1,000 KB");
      }

      if (
        this.fileOneType !== "image/png" &&
        this.fileOneType !== "image/jpeg"
      ) {
        errors.push("Image should be of type png or jpeg");
      }

      if (errors.length) {
        console.error(errors);
      }
    },

    isValidEmail(email) {
      const validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return email.match(validRegex);
    },

    //----------------------------Company ID
    handleShowCompanyIdModal() {
      this.newCompanyId = {
        number: this.companyId.number,
        expirationDate: new Date(this.companyId.expirationDate)
          .toISOString()
          .substring(0, 10),
        image: this.companyId.image,
      };

      this.fileOne = null;
      this.showCompanyIdModal = true;
    },

    async handleSaveCompanyId() {
      //-------------------------------Profile Image
      // Run only if fileOne !== null

      if (this.fileOne) {
        if (
          !this.allowedFileTypes.includes(this.fileOneType) ||
          this.fileOneSize > 1000000
        ) {
          this.snackbarColor = "red";
          this.snackbarText = "Please check image requirements";
          this.snackbarVisible = true;
          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(this.fileOne);
        reader.onload = async () => {
          const encodedFile = reader.result.split(",")[1];
          const imageOneData = {
            fileOne: encodedFile,
            fileName: this.fileOneName,
            fileExtension: this.fileOneExtension,
            fileOneType: this.fileOneType,
          };

          const token = this.auth.token;

          try {
            const res = await api.post(
              `/employees/profile-image/${this._id}`,
              {
                contentType: imageOneData.fileOneType,
              },
              {
                headers: {
                  Authorization: "Bearer " + token,
                },
              }
            );

            if (res.status !== 200) {
              this.snackbarColor = "red";
              this.snackbarText = res.message;
              this.snackbarVisible = true;
              return;
            }

            const s3url = res.data.url;

            const s3Response = await axios.put(s3url, this.fileOne, {
              headers: {
                "Content-Type": imageOneData.fileOneType,
              },
            });

            this.loading = false;

            if (s3Response.status === 200) {
              this.imageKey = res.data.key;
              this.showEmployeeImageDialog = false;
            } else {
              this.snackbarColor = "red";
              this.snackbarText = s3Response.statusText;
              this.snackbarVisible = true;
            }
          } catch (error) {
            this.snackbarColor = "red";
            this.snackbarText = error.message;
            this.snackbarVisible = true;
          }
        };
      }

      this.companyId = { ...this.newCompanyId };

      this.showCompanyIdModal = false;
    },

    // async handleSaveCompanyId() {
    //   //-------------------------------Profile Image
    //   // Run only if fileOne !== null

    //   if (!this.isValidCompanyId) return;

    //   if (this.fileOne) {
    //     this.loading = true;

    //     const reader = new FileReader();
    //     reader.readAsDataURL(this.fileOne);
    //     reader.onload = async () => {
    //       const encodedFile = reader.result.split(",")[1];
    //       const imageOneData = {
    //         fileOne: encodedFile,
    //         fileName: this.fileOne.name,
    //         fileExtension: this.fileOne.extension,
    //         fileOneType: this.fileOne.type,
    //       };

    //       try {
    //         const token = this.auth.token;

    //         const res = await api.post(
    //           `/employees/profile-image/${this._id}`,
    //           {
    //             contentType: imageOneData.fileOneType,
    //           },
    //           {
    //             headers: {
    //               Authorization: "Bearer " + token,
    //             },
    //           }
    //         );

    //         if (res.status !== 200) {
    //           this.snackbarColor = "red";
    //           this.snackbarText = res.message;
    //           this.snackbarVisible = true;
    //           return;
    //         }

    //         const s3url = res.data.url;

    //         if (this.fileOne && this.fileOne.type !== "image/png") {
    //           console.error("Invalid file type. Expected a PNG file.");
    //         } else {
    //           console.log("Valid PNG file:", this.fileOne);
    //         }

    //         const s3Response = await axios.put(s3url, this.fileOne, {
    //           headers: {
    //             "Content-Type": "image/png",
    //           },
    //         });

    //         if (s3Response.status === 200) {
    //           this.imageKey = res.data.key;
    //           this.showEmployeeImageModal = false;
    //         } else {
    //           this.snackbarColor = "red";
    //           this.snackbarText = s3Response.statusText;
    //           this.snackbarVisible = true;
    //         }
    //       } catch (error) {
    //         this.snackbarColor = "red";
    //         this.snackbarText = error.message;
    //         this.snackbarVisible = true;

    //         setTimeout(() => {
    //           this.snackbarVisible = false;
    //         }, this.snackbarTimeout);
    //       } finally {
    //         this.loading = false;
    //       }
    //     };
    //   }

    //   this.companyId = {
    //     number: this.newCompanyId.number.toUpperCase().trim(),
    //     expirationDate: new Date(this.newCompanyId.expirationDate)
    //       .toISOString()
    //       .substring(0, 10),
    //     image: this.newCompanyId.image,
    //   };

    //   this.showCompanyIdModal = false;
    // },

    //-----------------------------Passport
    handleShowPassportModal() {
      if (this.passport) {
        this.newPassport = {
          number: this.passport.number,
          country: this.passport.country,
          expirationDate: this.passport.expirationDate,
        };
      } else {
        this.newPassport = {
          number: "",
          country: "United States",
          expirationDate: new Date(
            new Date().getTime() + 5 * 365 * 24 * 60 * 60 * 1000
          )
            .toISOString()
            .substring(0, 10), //Five years from now
        };
      }

      this.showPassportModal = true;
    },

    handleAddPassport() {
      if (!this.isValidPassport) return;

      this.passport = {
        number: this.newPassport.number,
        country: this.newPassport.country,
        expirationDate: this.newPassport.expirationDate,
        status: "OK",
      };

      this.newPassport = null;
      this.showPassportModal = false;
    },

    handleRemovePassport(e) {
      e.stopPropagation();

      this.passport = null;
    },

    //--------------------------Medical Certificate
    handleShowMedicalCertificateModal() {
      if (this.medicalCertificate) {
        this.newMedicalCertificate = {
          class: this.medicalCertificate.class,
          dateOfExamination: this.medicalCertificate.dateOfExamination,
          validUntil: this.medicalCertificate.validUntil,
        };
      } else {
        this.newMedicalCertificate = {
          class: "First",
          dateOfExamination: new Date().toISOString().substring(0, 10),
          validUntil: new Date(new Date().getTime() + 180 * 24 * 60 * 60 * 1000)
            .toISOString()
            .substring(0, 10), //180 days from now
        };
      }

      this.showMedicalCertificateModal = true;
    },

    handleAddMedicalCertificate() {
      if (!this.isValidMedicalCertificate) return;

      this.medicalCertificate = {
        class: this.newMedicalCertificate.class,
        dateOfExamination: this.newMedicalCertificate.dateOfExamination,
        validUntil: this.newMedicalCertificate.validUntil,
        status: "OK",
      };

      this.newMedicalCertificate = null;
      this.showMedicalCertificateModal = false;
    },

    handleRemoveMedicalCertificate(e) {
      e.stopPropagation();
      this.medicalCertificate = null;
    },

    //--------------------------FAA Certificate
    handleShowFaaCertificateModal() {
      if (this.faaCertificate) {
        this.newFaaCertificate = {
          type: this.faaCertificate.type,
          number: this.faaCertificate.number,
          isTemporary: this.faaCertificate.isTemporary,
          validUntil: this.faaCertificate.validUntil,
        };
      } else {
        this.newFaaCertificate = {
          type: "Pilot",
          number: "",
          isTemporary: false,
          validUntil: new Date(new Date().getTime() + 90 * 24 * 60 * 60 * 1000)
            .toISOString()
            .substring(0, 10), //Five years from now
        };
      }

      this.showFaaCertificateModal = true;
    },

    handleAddFaaCertificate() {
      if (!this.isValidFaaCertificate) return;

      this.faaCertificate = {
        type: this.newFaaCertificate.type,
        number: this.newFaaCertificate.number,
        isTemporary: this.newFaaCertificate.isTemporary,
        validUntil: this.newFaaCertificate.validUntil,
        status: "OK",
      };

      this.newFaaCertificate = null;
      this.showFaaCertificateModal = false;
    },

    handleRemoveFaaCertificate(e) {
      e.stopPropagation();
      this.faaCertificate = null;
    },

    openEmployeeIdModal() {
      this.showEmployeeIdImageModal = true;
    },

    async handleSaveProfile() {
      //--------------------------Documents
      let documents = [];

      if (this.passport) {
        documents.push({
          type: "Passport",
          number: this.passport.number,
          expirationDate: new Date(
            `${this.passport.expirationDate}T23:59:59.999+00:00`
          ),
          country: this.passport.country,
        });
      }

      if (this.medicalCertificate) {
        documents.push({
          type: "Medical Certificate",
          class: this.medicalCertificate.class,
          dateOfExamination: new Date(
            `${this.medicalCertificate.dateOfExamination}T23:59:59.999+00:00`
          ),
          validUntil: new Date(
            `${this.medicalCertificate.validUntil}T23:59:59.999+00:00`
          ),
        });
      }

      if (this.faaCertificate) {
        documents.push({
          type: "FAA Certificate",
          number: this.faaCertificate.number,
          isTemporary: this.faaCertificate.isTemporary,
          validUntil: new Date(
            `${this.faaCertificate.validUntil}T23:59:59.999+00:00`
          ),
        });
      }

      let employee = {
        displayName: `${this.surname}, ${this.givenName}`,
        givenName: this.givenName,
        surname: this.surname,
        jobTitle: this.jobTitle,
        mail: this.mail,
        primaryPhone: this.primaryPhone,
        secondaryPhone: this.secondaryPhone,
        emergencyContact: { ...this.emergencyContact },
        dateOfHire: `${this.dateOfHire}T23:59:59.999+00:00`,
        seniorityNumber: this.seniorityNumber,
        baseIata: this.baseIata,
        address: {
          ...this.address,
        },
        status: this.status,
        securityGroupId: this.securityGroupId,
        companyId: { ...this.companyId },
        documents,
        trainingRecords: this.trainingRecords,
      };

      //--------------------------------Profile Image

      employee.imageKey = this.imageKey;

      this.loading = true;

      try {
        const token = this.auth.token;

        const res = await api.put(`/employees/${this._id}`, employee, {
          headers: { Authorization: "Bearer " + token },
        });

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        // this.snackbarColor = "var(--green)";
        // this.snackbarText = "Employee profile updated successfully";
        // this.snackbarVisible = true;

        // setTimeout(() => {
        //   this.snackbarVisible = false;
        // }, 3000);

        this.$router.go(-1);
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async handleDeleteEmployee() {
      this.confirmText = "";
      this.loading = true;
      try {
        const token = this.auth.token;
        const res = await api.delete(`/employees/${this._id}`, {
          headers: { Authorization: "Bearer " + token },
        });
        this.loading = false;
        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }
        this.$router.go(-1);
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    navigateToFlightBoard() {
      this.$router.push({ name: "FlightBoard" });
    },

    navigateToAdministration() {
      this.$router.push({ name: "Administration" });
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.is-expired {
  background-color: tomato;
  color: white;
}

.profile-pic-figure {
  border: 1px solid gray;
  width: 150px;
  cursor: pointer;
}

.profile-pic-figure img {
  width: 100%;
  height: auto;
  min-width: 100px;
  max-width: 150px;
  border: 1px solid var(--gray);
}

.container p {
  margin: 0;
  padding: 0;
  font-size: 0.8rem;
  color: var(--gray);
}

.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.fa-exclamation-triangle {
  color: var(--orange);
}
</style>
