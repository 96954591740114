<template>
  <div
    class="flex flex-col h-screen justify-between bg-cover bg-center bg-[url(../assets/customer_logo_large.png)] lg:bg-[url(../assets/home_page.jpg)]"
  >
    <!-- LOADING -->

    <r-spinner v-if="loading"> </r-spinner>

    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <div class="content">
      <header class="text-xl bg-white/60">
        <div>
          <div class="flex justify-between mb-3 opacity-100">
            <div class="customer-banner"></div>
            <div class="app-banner"></div>
          </div>

          <div class="flex justify-center mt-3">
            <div class="flex gap-5 border border-white rounded-md p-5">
              <div>
                <form>
                  <div>
                    <p class="text-sm text-gray-500">New Password</p>
                    <div class="flex gap-1 items-center">
                      <input
                        id="passwordOne"
                        name="passwordOne"
                        class="block w-64 rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        :type="hideOne ? 'password' : 'text'"
                        maxlength="20"
                        v-model="passwordOne"
                        placeholder="New Password"
                      />
                      <i
                        @click="hideOne = !hideOne"
                        class="cursor-pointer text-blue-800"
                        :class="{
                          'fa-solid fa-eye': !hideOne,
                          'fa-solid fa-eye-slash': hideOne,
                        }"
                      ></i>
                    </div>
                  </div>

                  <div class="mt-5">
                    <p class="text-sm text-gray-500">Confirm New Password</p>
                    <div class="flex gap-1 items-center">
                      <input
                        id="passwordTwo"
                        name="passwordTwo"
                        class="block w-64 rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        :type="hideTwo ? 'password' : 'text'"
                        maxlength="20"
                        v-model="passwordTwo"
                        placeholder="Confirm New Password"
                      />
                      <i
                        @click="hideTwo = !hideTwo"
                        class="cursor-pointer text-blue-800"
                        :class="{
                          'fa-solid fa-eye': !hideTwo,
                          'fa-solid fa-eye-slash': hideTwo,
                        }"
                      ></i>
                    </div>
                  </div>

                  <div class="flex justify-end mt-5">
                    <div
                      @click="handleResetPassword"
                      class="w-full rounded-md bg-blue-600 px-2.5 py-1.5 text-sm text-white text-center hover:shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 cursor-pointer"
                      :class="{ disabled: !passwordOne || !passwordTwo }"
                    >
                      Submit
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="flex justify-center my-3 text-sm text-gray-700">
            <div>
              <p class="font-bold mb-2">Password Must have:</p>
              <ul>
                <li>Between 6 and 20 characters</li>
                <li>At least one uppercase letter</li>
                <li>At least one lowercase letter</li>
                <li>At least one number</li>
                <li>At least one special character</li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import { mixin } from "../mixins/mixin";
import RSpinner from "../components/RSpinner.vue";
import RSnackbar from "../components/RSnackbar.vue";

export default {
  name: "PasswordReset",
  mixins: [mixin],
  props: ["token"],
  components: { RSpinner, RSnackbar },
  data() {
    return {
      loading: false,

      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
      passwordOne: "",
      passwordTwo: "",
      hideOne: true,
      hideTwo: true,
    };
  },

  methods: {
    validatePassword(password, confirmPassword) {
      const minLength = 6;
      const maxLength = 20;
      const hasUpperCase = /[A-Z]/.test(password);
      const hasLowerCase = /[a-z]/.test(password);
      const hasNumber = /\d/.test(password);
      const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(
        password
      );
      const hasNoSpaces = !/\s/.test(password);

      if (password !== confirmPassword) {
        return { valid: false, message: "Passwords do not match." };
      }
      if (password.length < minLength || password.length > maxLength) {
        return {
          valid: false,
          message: `Password must be between ${minLength} and ${maxLength} characters.`,
        };
      }
      if (!hasUpperCase) {
        return {
          valid: false,
          message: "Password must include at least one uppercase letter.",
        };
      }
      if (!hasLowerCase) {
        return {
          valid: false,
          message: "Password must include at least one lowercase letter.",
        };
      }
      if (!hasNumber) {
        return {
          valid: false,
          message: "Password must include at least one number.",
        };
      }
      if (!hasSpecialChar) {
        return {
          valid: false,
          message: "Password must include at least one special character.",
        };
      }
      if (!hasNoSpaces) {
        return { valid: false, message: "Password must not contain spaces." };
      }

      return { valid: true, message: "Password is valid." };
    },

    async handleResetPassword() {
      if (this.loading) return; // Prevent multiple clicks

      const result = this.validatePassword(this.passwordOne, this.passwordTwo);

      if (!result.valid) {
        this.snackbarColor = "red";
        this.snackbarText = result.message;
        this.snackbarVisible = true;

        setTimeout(() => {
          this.snackbarVisible = false;
        }, 3000);

        return;
      }

      try {
        this.loading = true;

        const token = this.token;

        const res = await api.put(`/employees/reset-password/${token}`, {
          password: this.passwordOne,
        });

        this.passwordOne = null;
        this.passwordTwo = null;

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.snackbarColor = "green";
        this.snackbarText = "Password reset successfully";
        this.snackbarVisible = true;

        setTimeout(() => {
          this.$router.push({ name: "Home" });
        }, 5000);
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },
  },
};
</script>

<style scoped>
.app-banner {
  background-image: url(../assets/app_banner.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 50px;
  width: 150px;
  margin: 5px;
}

.customer-banner {
  background-image: url(../assets/customer_banner.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 50px;
  width: 150px;
  margin: 5px;
}

.app-logo {
  background-image: url(../assets/flighttimes_logo_mini.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 30px;
  width: 24px;
}

.content {
  margin: 10px 2%;
  padding: 10px;
  min-height: 500px;
}

header {
  margin: 10px 2%;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  color: black;
}
</style>
