<template>
  <div class="main-frame">
    <div class="top-frame">
      {{ `${assignment.flightNumber} | ${assignment.seat}` }}
    </div>

    <div :style="cLabelStyle">
      <div class="text-sm text-center overflow-x-hidden whitespace-nowrap py-1">
        {{ `${assignment.origin.iata}-${assignment.destination.iata}` }}
      </div>
    </div>

    <div class="bottom-frame" :class="{ 'local-time': localTime }">
      <div>
        {{
          formatTime(assignment._start, assignment.origin.timeZone).substring(
            0,
            5
          )
        }}
      </div>
      <div>
        {{
          formatTime(
            assignment._end,
            assignment.destination.timeZone
          ).substring(0, 5)
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { mixin } from "../mixins/mixin";
export default {
  name: "flightAssignmentTile",
  mixins: [mixin],
  data() {
    return {};
  },
  props: ["assignment"],

  computed: {
    localTime() {
      return this.$store.state.localTime;
    },

    cLabelStyle() {
      let backgroundColor = "var(--scheduled)";
      let borderTop = "1px solid var(--gray)";
      let borderRight = "1px solid var(--gray)";
      let borderBottom = "1px solid var(--gray)";
      let borderLeft = "1px solid var(--gray)";

      //--scheduled: #ffe4c4;
      //--delayed: #fcfad4;
      //--blocked-out: #5cfa5c;
      //--airborne: #b0e9fc;
      //--blocked-in: #93c3c7;

      switch (this.assignment.status) {
        case "BLOCKED-OUT":
          backgroundColor = "var(--blocked-out)";

          break;
        case "AIRBORNE":
          backgroundColor = "var(--airborne)";

          break;
        case "BLOCKED-IN":
          backgroundColor = "var(--blocked-in)";

          break;
        default: //SCHEDULED
          // if (this.isDelayed) {
          //   backgroundColor = "var(--delayed)";
          // }

          break;
      }

      return {
        backgroundColor,
        borderTop,
        borderRight,
        borderBottom,
        borderLeft,
      };
    },
  },
};
</script>
<style scoped>
.main-frame {
  position: absolute;
  width: 100%;
  top: 0;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
}

.inner-frame {
  position: relative;
  justify-content: center;
  align-items: center;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #bebebe;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.top-frame {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px;
  text-align: start;
}

/* .bottom-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */

.bottom-frame {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bottom-frame div {
  font-size: 10px;
}
</style>
