<template>
  <div>
    <!-- #region NAVBAR -->
    <nav>
      <ul class="nav-wrapper">
        <li class="icon" @click="handleGoBack">
          <a href="#">
            <span class="tooltip">Go Back</span>
            <span><i class="fa-solid fa-arrow-left text-white"> </i></span>
          </a>
        </li>

        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white ml-3"> </i></span
          ></RouterLink>
        </li>

        <li>
          <a href="#">
            <div class="mx-3 text-base lg:text-2xl text-white">Assignments</div>
          </a>
        </li>

        <li>
          <a href="#">
            <div
              v-if="boardStyle === 'Board' && pages.length"
              class="flex justify-between fnt-sm"
            >
              <button class="btn-icon" @click="handlePageUp">
                <i class="fa-solid fa-chevrons-up text-white"></i>
              </button>
              <div class="text-white">
                {{ `${pageNumber + 1} of ${pages.length}` }}
              </div>

              <button class="btn-icon" @click="handlePageDown">
                <i class="fa-solid fa-chevrons-down text-white"></i>
              </button>
            </div>
          </a>
        </li>

        <li @click="zoomIn" class="icon" v-if="boardStyle !== 'List'">
          <a href="#">
            <span class="tooltip">Zoom In</span>
            <span><i class="fa-regular fa-magnifying-glass-plus"></i></span>
          </a>
        </li>
        <li @click="zoomOut" class="icon" v-if="boardStyle !== 'List'">
          <a href="#">
            <span class="tooltip">Zoom Out</span>
            <span><i class="fa-regular fa-magnifying-glass-minus"></i></span>
          </a>
        </li>
        <li @click="getData" class="icon">
          <a href="#">
            <span class="tooltip">Refresh</span>
            <span><i class="fa-solid fa-rotate"></i></span>
          </a>
        </li>

        <li @click="handleToggleTimeZone" class="icon">
          <a href="#">
            <span class="tooltip">Change Time Zone</span>
            <span>
              <i
                :class="[
                  'fa-solid fa-clock',
                  localTime ? 'text-red-500' : 'text-white',
                ]"
              ></i>
            </span>
          </a>
        </li>
      </ul>
    </nav>
    <!-- #endregion -->

    <!-- SNACKBAR -->
    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <!-- DATE FILTER DIALOG -->
    <r-modal v-if="showDateFilterDialog" @close="showDateFilterDialog = false">
      <div v-if="showDateFilterDialog" class="p-5">
        <div class="flex justify-between mb-2">
          <div class="text-2xl font-bold">Date Filter</div>
          <button @click="showDateFilterDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="flex">
          <div>
            <label
              for="date-filter"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Start Date</label
            >

            <input
              class="block w-32 rounded-md border-0 py-1.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="startDate"
              type="date"
              id="date-filter"
            />
          </div>

          <div class="ml-3">
            <label
              for="date-filter"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Number of Days</label
            >

            <select
              class="block w-24 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="numberOfDays"
            >
              <option
                v-for="option in numberOfDaysOptions"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
        </div>

        <div class="flex justify-end mt-1">
          <button
            @click="handleSaveDateFilter"
            class="btn bg-blue-500 text-white"
          >
            Apply Filter
          </button>
        </div>
      </div>
    </r-modal>

    <r-spinner v-if="loading"> </r-spinner>

    <div class="board" ref="board">
      <!-- LEFT PANEL -->
      <!-- Each page has a list of employees -->
      <div v-if="boardStyle === 'Board' && page" class="left-panel">
        <div class="left-panel-header bg-gray-500-l2">
          <div class="text-base flex justify-center employees-center w-[90px]">
            Employees
          </div>
        </div>
        <div
          class="left-panel-row bg-gray-500-l4"
          v-for="item in page.items"
          :key="item.employee._id"
          :style="{ height: `${rowHeight}px` }"
        >
          <div class="text-base text-align text-bold text-truncate">
            {{
              `${item.employee.surname}, ${item.employee.givenName.substring(
                0,
                1
              )}`
            }}
          </div>
          <div class="text-[10px] text-truncate">
            {{ `${item.employee.jobTitle} ` }}
          </div>
          <div class="text-xs text-truncate">
            {{ `${item.employee.companyId.number}` }}
          </div>
        </div>
      </div>
      <!-- RIGHT PANEL -->
      <div
        v-if="boardStyle === 'Board'"
        class="right-panel-container"
        ref="scrollContainer"
      >
        <div v-if="page" class="right-panel">
          <!-- Header -->
          <div
            class="right-panel-header bg-gray-500-l4 cursor-pointer"
            @click="handleOpenDateFilter(item)"
            v-for="(item, index) in dateFilter.days"
            :key="index"
            :style="{
              top: 0,
              left: `${(1440 / scale) * index}px`,
              width: `${dayWidth}px`,
            }"
          >
            {{ formatHeaderDate(item) }}
          </div>

          <!-- Day Lines -->
          <div
            class="day-line"
            v-for="(day, index) in dateFilter.days"
            :key="day"
            :style="{
              left: `${(1440 / scale) * index}px`,
              height: `${availableBoardHeight + 50}px`,
            }"
          ></div>

          <!-- Hour Boxes -->
          <div
            class="hour-box text-[10px]"
            :class="{ hidden: marker.value === '00' || scale > 4 }"
            v-for="(marker, index) in dateFilter.hourBoxes"
            :key="marker.key"
            :style="{ left: `${(60 / scale) * index}px` }"
          >
            {{ marker.value }}
          </div>

          <!-- Hour Lines -->
          <div
            class="hour-line"
            :class="{ hidden: marker.value === '00' || scale > 6 }"
            v-for="(marker, index) in dateFilter.hourLines"
            :key="marker.key"
            :style="{
              left: `${(60 / scale) * index}px`,
              height: `${availableBoardHeight + 7}px`,
            }"
          ></div>

          <!-- Now Box -->
          <div
            class="now-box"
            v-if="showCurrentTime"
            :style="{
              left: `${nowLineLeft}px`,
            }"
          >
            {{ formatTime(new Date(), "UTC").substring(0, 5) }}
          </div>

          <!-- Now Line -->
          <div
            class="now-line"
            v-if="showCurrentTime"
            :style="{
              left: `${nowLineLeft}px`,
              height: `${availableBoardHeight + 7}px`,
            }"
          ></div>

          <!-- Employees -->
          <div
            class="right-panel-row"
            v-for="(item, index) in page.items"
            :key="item.employee._id"
            :style="{
              top: `${index * rowHeight + 50}px`,
              width: `${boardWidth}px`,
              height: `${rowHeight}px`,
            }"
          >
            <!--Assignments-->
            <div
              v-for="_assignment in item.employee.flightAssignments"
              :key="_assignment._id"
              class="board-label-frame"
              :style="{
                width: `${_assignment.duration.totalMinutes / scale}px`,
                left: `${getLocationLeft(
                  _assignment._start,
                  dateFilter.days[0],
                  scale
                )}px`,
              }"
            >
              <div>
                <FlightAssignmentTile :assignment="_assignment" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import { mixin } from "../mixins/mixin";
import FlightAssignmentTile from "../components/FlightAssignmentTile.vue";

import EmployeeInformation from "../components/EmployeeInformation.vue";

import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";
import RModal from "../components/RModal.vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
  Switch,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";

export default {
  name: "EmployeesBoard",
  components: {
    EmployeeInformation,
    FlightAssignmentTile,
    RSnackbar,
    RSpinner,
    RModal,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronUpDownIcon,
    Switch,
  },
  data() {
    return {
      loading: false,

      //---------------------------------------Dialogs
      showDateFilterDialog: false,

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,

      sortedAssignments: [],

      //#region-----------------------------------Paginate
      availableBoardHeight: 0,
      schedule: null,
      itemsPerPage: 0,
      pages: [],
      page: null,
      rowHeight: 60,
      disableNextPageButton: true,
      disablePreviousPageButton: true,
      //#endregion

      //----------------------------------------Board
      boardStyle: "Board",
      scale: 4,

      // assignmentsListDialog: false,
      originIata: "", //Used to sort station assignment flights
      paddingBefore: 30,
      paddingAfter: 30,

      //------------------------------------------Employee
      employeeDialog: false,
      showEmployeeInformationDialog: false,
      employee: null,
      workMonth: null,

      //------------------------------------------Filters and Settings

      search: "",

      startDate: null,
      numberOfDays: 7,
      numberOfDaysOptions: [3, 5, 7, 14, 21, 31],
    };
  },
  mixins: [mixin],
  created() {
    this.getData();
  },

  mounted() {
    this.$nextTick(() => {
      const board = this.$refs.board;

      this.availableBoardHeight = board.clientHeight - 120;

      this.itemsPerPage = Math.floor(
        this.availableBoardHeight / this.rowHeight
      );
    });

    window.addEventListener("resize", this.onResize, { passive: true });
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  computed: {
    localTime() {
      return this.$store.state.localTime;
    },

    flight() {
      return this.$store.getters.flight;
    },

    pageNumber() {
      return this.$store.getters.employeesBoardPageNumber;
    },

    showCurrentTime() {
      const now = new Date().getTime();

      return (
        now > new Date(this.dateFilter.start).getTime() &&
        now < new Date(this.dateFilter.end).getTime()
      );
    },

    nowLineLeft() {
      const x = this.getLocationLeft(
        new Date().toISOString(),
        this.dateFilter.start,
        this.scale
      );

      if (x > 0 || x < this.boardWidth) {
        return x;
      } else {
        return 0;
      }
    },

    boardWidth() {
      return this.dateFilter.days.length * (1440 / this.scale) + 100;
    },

    dayWidth() {
      return 1440 / this.scale;
    },

    dateFilter() {
      return this.$store.getters.dateFilter;
    },

    auth() {
      return this.$store.state.auth;
    },
  },
  methods: {
    handleToggleTimeZone() {
      const localTime = !this.localTime;
      this.$store.commit("updateLocalTime", localTime);
    },

    formatCrewmember(crewmember) {
      if (crewmember.employee) {
        return `${crewmember.employee.surname}, ${crewmember.employee.givenName}`;
      } else {
        if (crewmember.required) {
          return "Please Assign.";
        } else {
          return "Not Required";
        }
      }
    },

    async getData() {
      if (!this.auth) {
        this.$router.push({ name: "Home" });
        return;
      }

      this.loading = true;

      this.schedule = null;
      this.pages = [];
      this.page = null;

      try {
        const token = this.auth.token;

        const res = await api.get("/assignments", {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: {
            _start: this.dateFilter.start,
            _end: this.dateFilter.end,
          },
        });

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.schedule = res.data.schedule;
        this.paginate();
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    paginate() {
      this.pages = [];
      let _pages = [];
      this.page = null;

      if (this.schedule) {
        let chunk = Math.min(
          Math.floor(this.availableBoardHeight / this.rowHeight),
          this.schedule.length
        );

        //Show warning if not enough space to display
        if (chunk < 1) {
          this.snackbarColor = "red";
          this.snackbarText = "Not enough screen space to display data";
          this.snackbarVisible = true;
          return;
        }

        //Split data into pages
        for (let i = 0; i < this.schedule.length; i += chunk) {
          let tempArray;
          tempArray = this.schedule.slice(i, i + chunk);
          _pages.push({
            index: _pages.length,
            items: tempArray,
          });
        }

        this.pages = [..._pages];

        const i = this.pages.findIndex((page) => {
          return page.index === this.pageNumber;
        });

        if (i < 0) {
          this.$store.commit("updateEmployeesBoardPageNumber", 0);
        }

        this.page = this.pages[this.pageNumber];
      }
    },

    sortArrayByKey(key) {
      const sortedAsc = this.isSortedAsc(this.sortedAssignments, key);

      if (sortedAsc) {
        this.sortedAssignments.sort((a, b) => (a[key] < b[key] ? 1 : -1));
      } else {
        this.sortedAssignments.sort((a, b) => (a[key] > b[key] ? 1 : -1));
      }
    },

    isSortedAsc(arr, key) {
      for (let i = 0; i < arr.length - 1; i++) {
        if (arr[i][key] > arr[i + 1][key]) {
          return false;
        }
      }
      return true;
    },

    onResize() {
      const board = this.$refs.board;

      if (!board) return;

      this.availableBoardHeight = board.clientHeight - 120;

      this.itemsPerPage = Math.floor(
        this.availableBoardHeight / this.rowHeight
      );

      this.paginate();
    },

    handlePageUp() {
      if (this.pageNumber > 0) {
        const val = this.pageNumber - 1;
        this.$store.commit("updateEmployeesBoardPageNumber", val);
        this.page = this.pages[this.pageNumber];
      }
    },

    handlePageDown() {
      if (this.pageNumber < this.pages.length - 1) {
        const val = this.pageNumber + 1;
        this.$store.commit("updateEmployeesBoardPageNumber", val);
        this.page = this.pages[this.pageNumber];
      }
    },

    handleGoBack() {
      // Access the previous route
      const previousRoute = this.$previousRoute();

      // Handle different types
      switch (previousRoute.name) {
        case "FlightView":
          this.$store.commit("updateFlight", this.flight);
          this.$router.push({ name: "FlightView" });
          break;
        default:
          break;
      }

      this.$router.go(-1);
    },

    handleNavigateToHome() {
      this.$router.go({ name: "home" });
    },

    handleShowFlightTooltip(assignment, index) {
      this.tooltip = { ...assignment };

      this.tooltipStyle = {
        display: "block",
        top: `${index * this.rowHeight + 50 + 45}px`,
        left: `${
          this.getLocationLeft(
            assignment.startTime,
            this.dateFilter.days[0],
            this.scale
          ) + 30
        }px`,
      };
    },

    handleHideFlightTooltip() {
      setTimeout(() => {
        this.tooltipStyle = {
          display: "none",
        };
      }, 150);
    },

    handleNavigateToEmployeeProfile() {
      this.$router.push({
        name: "employeeUpdate",
        params: { id: this.employee._id },
      });
    },

    zoomIn() {
      if (this.scale > 1) {
        this.scale -= 1;
      }
    },

    zoomOut() {
      if (this.scale < 8) {
        this.scale += 1;
      }
    },

    //-------------------------------------Filters and Settings
    handleOpenDateFilter(item) {
      this.startDate = item.substring(0, 10);
      this.numberOfDays = this.dateFilter.days.length;

      this.showDateFilterDialog = true;
    },

    handleSaveDateFilter() {
      const filterStart = new Date(this.startDate).getTime();

      this.showDateFilterDialog = false;

      //Pass filterStart in miliseconds, numberOfDays as integer
      this.$store.commit("updateDateFilter", {
        filterStart,
        numberOfDays: this.numberOfDays,
      });

      this.getData();
    },

    //--------------------------Employee Information
    handleShowEmployeeInformationDialog(employee) {
      if (this.canReadEmployeeContactInformation) {
        this.employee = { ...employee };

        this.showEmployeeInformationDialog = true;
      }
    },

    //------------------------Formatters and Stylers

    formatHeaderDate(string) {
      const date = new Date(string);

      let options = {
        timeZone: "UTC",
        year: "numeric",
        month: "short",
        day: "numeric",
        weekday: "long",
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    },

    getBidDayStyle(bidDay) {
      //Default color "DO" - Day Off
      let backgroundColor = "#E74C3C";

      switch (bidDay.code) {
        case "RES":
          backgroundColor = "#2ECC71";
          break;
        case "VAC":
          backgroundColor = "#A569BD";
          break;
        default:
          break;
      }

      const x =
        (new Date(Date.UTC(bidDay.year, bidDay.month, bidDay.day)).getTime() -
          new Date(this.dateFilter.days[0]).getTime()) /
        1000 /
        60 /
        this.scale;

      const style = {
        top: 0,
        left: `${x}px`,
        height: `${this.rowHeight}px`,
        width: `${this.dayWidth}px`,
        backgroundColor,
      };

      return style;
    },
  },
};
</script>

<style scoped>
.flight-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  min-width: 250px;
  padding: 5px;
  border-radius: 3px;
  background-color: rgba(128, 128, 128, 0.9);
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  z-index: 700;
}

.right-panel-row {
  position: absolute;
  top: 50px;
  left: 0;
  height: 0;
  width: 0;
  border-bottom: 1px solid gray;
  background-color: white;
  overflow: hidden;
}

.right-panel-row:nth-child(odd) {
  background-color: rgb(236, 225, 225);
}

section {
  padding: 0 1rem;
  min-height: 10rem;
  max-height: 26rem;
  overflow-y: auto;
}

.warning-tile {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 5px 10px;
  color: black;
  margin-bottom: 3px;
}

.fa-user:hover {
  color: #3b82f6;
}
</style>
