<template>
  <div>
    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <nav>
      <ul class="nav-wrapper">
        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white ml-3"> </i></span
          ></RouterLink>
        </li>

        <li>
          <a href="#">
            <div class="mx-3 text-base lg:text-2xl text-white">
              My Flight Times
            </div>
          </a>
        </li>

        <li @click="handleToggleBoardStyle" class="icon">
          <a href="#">
            <span class="tooltip">Switch Board</span>
            <span><i class="fa-solid fa-bolt text-yellow-500"></i></span>
          </a>
        </li>

        <li @click="zoomIn" class="icon" v-if="boardStyle === 0">
          <a href="#">
            <span class="tooltip">Zoom In</span>
            <span><i class="fa-regular fa-magnifying-glass-plus"></i></span>
          </a>
        </li>
        <li @click="zoomOut" class="icon" v-if="boardStyle === 0">
          <a href="#">
            <span class="tooltip">Zoom Out</span>
            <span><i class="fa-regular fa-magnifying-glass-minus"></i></span>
          </a>
        </li>

        <li @click="getData" class="icon">
          <a href="#">
            <span class="tooltip">Refresh</span>
            <span><i class="fa-solid fa-rotate"></i></span>
          </a>
        </li>
      </ul>
    </nav>

    <!-- LOADING -->

    <r-spinner v-if="loading"> </r-spinner>

    <!-- DATE FILTER DIALOG -->
    <r-modal v-if="showDateFilterDialog" @close="showDateFilterDialog = false">
      <div v-if="showDateFilterDialog" class="p-5">
        <div class="flex justify-between mb-2">
          <div class="text-2xl font-bold">Date Filter</div>
          <button @click="showDateFilterDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="flex">
          <div>
            <label
              for="date-filter"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Start Date</label
            >

            <input
              class="block w-32 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="startDate"
              type="date"
              id="date-filter"
            />
          </div>

          <div class="ml-3">
            <label
              for="date-filter"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Number of Days</label
            >

            <select
              class="block w-24 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="numberOfDays"
            >
              <option
                v-for="option in numberOfDaysOptions"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
        </div>

        <div class="flex justify-end mt-1">
          <button
            @click="handleSaveDateFilter"
            class="btn bg-blue-500 text-white"
          >
            Apply Filter
          </button>
        </div>
      </div>
    </r-modal>

    <!-- EMPLOYEE INFORMATION DIALOG -->
    <r-modal
      v-if="showEmployeeInformationDialog"
      @close="showEmployeeInformationDialog = false"
    >
      <employee-information
        :employee="employee"
        @close="showEmployeeInformationDialog = false"
        @employeeProfileButtonClick="handleNavigateToEmployeeProfile"
      ></employee-information>
    </r-modal>

    <!-- BOARD -->
    <div class="board" ref="board">
      <!-- LEFT PANEL -->
      <div v-if="boardStyle === 0 && employee" class="left-panel">
        <div class="left-panel-header bg-gray-500-l2">
          <div class="text-lg font-bold">
            <!-- {{ `${employee.surname}, ${employee.givenName.substring(0, 1)}` }} -->
          </div>
        </div>
        <div
          class="left-panel-row bg-gray-500-l4 cursor-pointer"
          @click="handleShowEmployeeInformationDialog(employee)"
          :style="{ height: `${rowHeight}px` }"
        >
          <div class="text-base text-align text-bold text-truncate">
            {{ `${employee.surname}, ${employee.givenName.substring(0, 1)}` }}
          </div>
          <div class="text-xs text-truncate">
            {{ `${employee.jobTitle}` }}
          </div>
          <div class="text-xs text-truncate">
            {{ `${employee.companyName}` }}
          </div>
        </div>
      </div>
      <!-- RIGHT PANEL -->
      <div v-if="boardStyle === 0 && employee" class="right-panel-container">
        <div class="right-panel">
          <!-- Header -->
          <div
            class="right-panel-header bg-gray-500-l4 cursor-pointer"
            @click="handleOpenDateFilter(item)"
            v-for="(item, index) in dateFilter.days"
            :key="index"
            :style="{
              top: 0,
              left: `${(1440 / scale) * index}px`,
              width: `${dayWidth}px`,
            }"
          >
            {{ formatHeaderDate(item) }}
          </div>

          <!-- Day Lines -->
          <div
            class="day-line"
            v-for="(day, index) in dateFilter.days"
            :key="day"
            :style="{
              left: `${(1440 / scale) * index}px`,
              height: `${rowHeight + 50}px`,
            }"
          ></div>

          <!-- Hour Boxes -->
          <div
            class="hour-box text-[10px]"
            :class="{ hidden: marker.value === '00' || scale > 4 }"
            v-for="(marker, index) in dateFilter.hourBoxes"
            :key="marker.key"
            :style="{ left: `${(60 / scale) * index}px` }"
          >
            {{ marker.value }}
          </div>

          <!-- Hour Lines -->
          <div
            class="hour-line"
            :class="{ hidden: marker.value === '00' || scale > 6 }"
            v-for="(marker, index) in dateFilter.hourLines"
            :key="marker.key"
            :style="{
              left: `${(60 / scale) * index}px`,
              height: `${rowHeight + 50 - 43}px`,
            }"
          ></div>

          <!-- Now Box -->
          <div
            class="now-box"
            v-if="showCurrentTime"
            :style="{
              left: `${nowLineLeft}px`,
            }"
          >
            {{ formatTime(new Date(), "UTC").substring(0, 5) }}
          </div>

          <!-- Now Line -->
          <div
            class="now-line"
            v-if="showCurrentTime"
            :style="{
              left: `${nowLineLeft}px`,
              height: `${rowHeight + 2}px`,
            }"
          ></div>

          <!-- Employee -->
          <div
            class="right-panel-row"
            :style="{
              top: `50px`,
              width: `${boardWidth}px`,
              height: `${rowHeight}px`,
            }"
          >
            <!-- Bid Days -->
            <div
              v-for="bidday in employee.biddays"
              :key="`${employee._id}-${bidday.year}-${bidday.month}-${bidday.day}`"
              class="bidday-panel"
              :style="getBidDayStyle(bidday)"
            >
              {{ bidday.description }}
            </div>
            <!--Assignments-->
            <div
              v-for="assignment in employee.assignments"
              :key="assignment._id"
            >
              <!-- Flight Assignment -->
              <div
                v-if="assignment.type === 'Flight'"
                class="board-label-frame"
                :style="{
                  width: `${assignment.duration / scale}px`,
                  left: `${getLocationLeft(
                    assignment.startTime,
                    dateFilter.days[0],
                    scale
                  )}px`,
                }"
              >
                <FlightAssignmentTile :assignment="assignment" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- LIST -->
      <!-- TODO add list view -->
    </div>
  </div>
</template>

<script>
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import api from "../services/api";
import { mixin } from "../mixins/mixin";
import RSnackbar from "../components/RSnackbar.vue";
import FlightAssignmentTile from "../components/FlightAssignmentTile.vue";

import EmployeeInformation from "../components/EmployeeInformation.vue";
import RSpinner from "../components/RSpinner.vue";
import RModal from "../components/RModal.vue";

export default {
  name: "myFlightTimes",
  components: {
    FlightAssignmentTile,

    EmployeeInformation,
    RSnackbar,
    RSpinner,
    RModal,
  },
  data() {
    return {
      rowHeight: 60,

      loading: false,

      reportAssignments: [],

      //----------------------------------------Board
      boardStyle: 0,
      sliderX: 0,
      scrollX: 0,
      scale: 4,
      assignmentsListDialog: false,
      originIata: "", //Used to sort station assignment flights
      paddingBefore: 30,
      paddingAfter: 30,

      //------------------------------------------Filters and Settings

      selectedCompany: "",
      selectedBoardType: "",
      boardTypes: ["Cargo", "Passenger", "Combined"],
      searchByStation: false,
      searchByStationCode: "",
      searchByStationCriteria: "Origin or Destination",
      searchByStationDialog: false,
      searchByStationOptions: [
        "Origin",
        "Destination",
        "Origin or Destination",
      ],

      showDateFilterDialog: false,
      startDate: null,
      numberOfDays: 7,
      numberOfDaysOptions: [3, 5, 7, 14, 21, 31],

      //-----------------------------Employee
      showEmployeeInformationDialog: false,
      employee: null,
      confirmed: false,

      assignment: null,

      //-------------------------------Flight Assignments
      flightAssignment: null,
      assignmentSearch: "",

      flightAssignmentRemarks: "",

      //-------------------------------Assignments (Shared)
      selectedAssignments: [],

      // -----------------------------------------Ground Assignments

      groundAssignment: null,
      showNewGroundAssignmentDialog: false,

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,

      //-------------------------------General Warnings
      warnings: [],
      showWarningsDialog: false,
    };
  },

  mixins: [mixin],
  created() {
    this.selectedCompany = this.user.securityGroup.companies[0];
    this.getData();
  },

  computed: {
    showCurrentTime() {
      const now = new Date().getTime();

      return (
        now > new Date(this.dateFilter.start).getTime() &&
        now < new Date(this.dateFilter.end).getTime()
      );
    },

    dateFilter() {
      return this.$store.getters.dateFilter;
    },

    monthFilter() {
      return this.$store.getters.monthFilter;
    },

    nowLineLeft() {
      const x = this.getLocationLeft(
        new Date().toISOString(),
        this.dateFilter.start,
        this.scale
      );

      if (x > 0 || x < this.boardWidth) {
        return x;
      } else {
        return 0;
      }
    },
    dayWidth() {
      return 1440 / this.scale;
    },

    boardWidth() {
      return this.dateFilter.days.length * (1440 / this.scale);
    },

    boardHeight() {
      if (this.employee) {
        return this.rowHeight + 50;
      } else {
        return 0;
      }
    },

    airports() {
      return this.$store.getters.airports;
    },

    user() {
      return this.$store.getters.user;
    },

    assignmentsNotAcknowledged() {
      return this.$store.getters.assignmentsNotAcknowledged;
    },

    // board() {
    //   return this.$store.getters.board;
    // },
  },

  methods: {
    //--------------------------Employee Information
    handleShowEmployeeInformationDialog(employee) {
      if (this.canReadEmployeeContactInformation) {
        this.employee = { ...employee };

        this.showEmployeeInformationDialog = true;
      }
    },

    handleNavigateToEmployeeProfile() {
      this.$router.push({
        name: "employeeUpdate",
        params: { id: this.employee._id },
      });
    },

    getAircraftLocationY(index) {
      if (index === 0) {
        return "50px";
      } else {
        return `${index * 80 + 50}px`;
      }
    },

    getDayLocationX(index) {
      if (index === 0) {
        return "0";
      } else {
        return `${360 * index}px`;
      }
    },

    handleGoBack() {
      this.$router.go(-1);
    },

    async getData() {
      if (!this.auth) {
        this.$router.push({ name: "Home" });
        return;
      }

      this.loading = true;

      try {
        const token = await aad.getIdTokenSilent();

        // const res = await api.get(`//${this.user._id}`, {
        //   headers: {
        //     Authorization: "Bearer " + token.idToken.rawIdToken,
        //   },
        //   params: {
        //     dateFilterStart: this.dateFilter.start,
        //     dateFilterEnd: this.dateFilter.end,
        //   },
        // });

        this.loading = false;

        if (res.status !== 200) {
          this.loading = false;
          this.snackbarColor = "red";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;

          return;
        }

        this.employee = {
          ...this.user,
          assignments: res.data.assignments,
          biddays: res.data.biddays,
        };

        if (this.assignmentsNotAcknowledged.length) {
          this.boardStyle = 2;
        }
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    handleOpenDateFilter(item) {
      this.startDate = item.substring(0, 10);
      this.numberOfDays = this.dateFilter.days.length;

      this.showDateFilterDialog = true;
    },

    handleSaveDateFilter() {
      const filterStart = new Date(this.startDate).getTime();
      this.showDateFilterDialog = false;

      //Pass filterStart in miliseconds, numberOfDays as integer
      this.$store.commit("updateDateFilter", {
        filterStart,
        numberOfDays: this.numberOfDays,
      });

      const month = new Date(this.startDate).getUTCMonth();
      const year = new Date(this.startDate).getUTCFullYear();

      this.$store.commit("updateMonthFilter", {
        year,
        month,
        period: "Second",
      });

      this.getData();
    },

    zoomIn() {
      if (this.scale === 16) {
        this.scale = 8;
      } else if (this.scale === 8) {
        this.scale = 4;
      } else if (this.scale === 4) {
        this.scale = 3;
      } else if (this.scale === 3) {
        this.scale = 2;
      } else {
        this.scale = 1;
      }
    },

    zoomOut() {
      if (this.scale === 1) {
        this.scale = 2;
      } else if (this.scale === 2) {
        this.scale = 3;
      } else if (this.scale === 3) {
        this.scale = 4;
      } else if (this.scale === 4) {
        this.scale = 8;
      } else {
        this.scale = 16;
      }
    },

    handleToggleBoardStyle() {
      switch (this.boardStyle) {
        case 0:
          if (this.employee.assignments.length) {
            this.boardStyle = 1;
          } else {
            this.loading = false;
            this.snackbarColor = "red";
            this.snackbarText = "No assignments to display.";
            this.snackbarVisible = true;
          }

          break;

        default:
          this.boardStyle = 0;
          break;
      }
    },

    //------------------------Formatters and Stylers

    formatHeaderDate(string) {
      const date = new Date(string);

      let options = {
        timeZone: "UTC",
        year: "numeric",
        month: "short",
        day: "numeric",
        weekday: "long",
        // hour: "numeric",
        // minute: "numeric",
        // hourCycle: "h23",
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    },

    getBidDayStyle(bidDay) {
      //Default color "DO" - Day Off
      let backgroundColor = "#E74C3C";

      switch (bidDay.code) {
        case "RES":
          backgroundColor = "#2ECC71";
          break;
        case "VAC":
          backgroundColor = "#A569BD";
          break;
        default:
          break;
      }

      const x =
        (new Date(Date.UTC(bidDay.year, bidDay.month, bidDay.day)).getTime() -
          new Date(this.dateFilter.days[0]).getTime()) /
        1000 /
        60 /
        this.scale;

      const style = {
        top: 0,
        left: `${x}px`,
        height: `${this.rowHeight}px`,
        width: `${this.dayWidth}px`,
        backgroundColor,
      };

      return style;
    },
  },
};
</script>

<style scoped>
.right-panel-row {
  position: absolute;
  top: 50px;
  left: 0;
  height: 0;
  width: 0;
  border-bottom: 1px solid gray;
  background-color: white;
  overflow: hidden;
}

.right-panel-row:nth-child(odd) {
  background-color: rgb(236, 225, 225);
}
</style>
