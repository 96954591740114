import { createApp } from "vue";
import App from "./App.vue";
import { router } from "./router";
import store from "@/store";
import "@/assets/style.css";

const app = createApp(App);

let previousRoute = null;

router.beforeEach((to, from, next) => {
  previousRoute = from;
  next();
});

app.config.globalProperties.$previousRoute = () => previousRoute;

app.use(router);
app.use(store);
app.mount("#app");
