<template>
  <div>
    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <nav>
      <ul>
        <li>
          <a href="#">
            <button class="btn-icon" @click="handleGoBack">
              <i class="fa-solid fa-arrow-left text-white ml-3"></i>
            </button>
          </a>
        </li>

        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white"> </i></span
          ></RouterLink>
        </li>

        <li>
          <a href="#"> </a>
        </li>
      </ul>
    </nav>

    <!-- LOADING -->

    <r-spinner v-if="loading"> </r-spinner>

    <div class="p-5 h-screen-minus-toolbar" v-if="employee">
      <div class="flex flex-row justify-between">
        <div class="flex justify-between px-5">
          <div>
            <div class="text-2xl text-blue-700">
              {{ `${employee.surname}, ${employee.givenName}` }}
            </div>
            <div class="text-xl text-blue-700">
              {{
                `${employee.companyName} - Emp. No ${employee.companyId.number}`
              }}
            </div>
          </div>
        </div>
      </div>

      <form class="my-5" @submit.prevent="onSubmit">
        <div class="main-grid">
          <div
            class="employee-id cursor-pointer"
            @click="handleShowCompanyIdDialog"
          >
            <p class="text-sm text-gray-500">Employee ID</p>
            <input
              class="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
              type="text"
              maxlength="40"
              v-model="employee.companyId.number"
              :class="{ missing: !employee.companyId.number }"
            />
          </div>

          <div class="id-expiration-date cursor-pointer">
            <p class="text-sm text-gray-500">Expiration Date</p>

            <input
              class="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
              type="date"
              maxlength="40"
              v-model="employee.companyId.expirationDate"
            />
          </div>

          <div class="given-name">
            <p class="text-sm text-gray-500">First Name</p>
            <input
              class="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
              disabled
              type="text"
              maxlength="40"
              v-model="employee.givenName"
            />
          </div>

          <div class="surname">
            <p class="text-sm text-gray-500">Last Name</p>
            <input
              class="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              disabled
              type="text"
              maxlength="40"
              v-model="employee.surname"
            />
          </div>

          <div class="job-title">
            <p class="text-sm text-gray-500">Job Title</p>
            <select
              class="block w-full py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 cursor-pointer"
              :class="{ missing: !employee.jobTitle }"
              v-model="employee.jobTitle"
            >
              <option
                v-for="(jobTitle, index) in user.securityGroup.jobTitles"
                :key="index"
                :value="jobTitle"
              >
                {{ jobTitle }}
              </option>
            </select>
          </div>

          <div class="company-name">
            <p class="text-sm text-gray-500">Company</p>
            <select
              class="block w-full py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              :class="{ missing: !employee.companyName }"
              v-model="employee.companyName"
            >
              <option
                v-for="(companyName, index) in user.securityGroup.companies"
                :key="index"
                :value="companyName"
              >
                {{ companyName }}
              </option>
            </select>
          </div>

          <div class="gateway">
            <p class="text-sm text-gray-500">Gateway</p>
            <input
              class="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              :class="{ missing: !employee.gatewayCode }"
              type="text"
              maxlength="4"
              v-model="employee.gatewayCode"
            />
          </div>

          <div class="seniority-number">
            <p class="text-sm text-gray-500">Seniority No.</p>
            <input
              class="block w-24 py-2 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              :class="{ missing: !employee.seniorityNumber }"
              type="number"
              step="1"
              min="0"
              max="99999"
              v-model="employee.seniorityNumber"
            />
          </div>

          <div class="date-of-hire">
            <p class="text-sm text-gray-500">Date of Hire</p>
            <input
              class="block w-48 py-2 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="date"
              v-model="employee.dateOfHire"
            />
          </div>

          <div class="mail">
            <p class="text-sm text-gray-500">Email</p>
            <input
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              disabled
              type="text"
              maxlength="100"
              v-model="employee.mail"
            />
          </div>

          <div class="mobile-phone">
            <p class="text-sm text-gray-500">Mobile Phone</p>
            <input
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="text"
              maxlength="40"
              v-model="employee.mobilePhone"
            />
          </div>
        </div>
      </form>

      <div
        class="flex justify-end"
        v-if="
          employee.jobTitle &&
          employee.companyId.number &&
          employee.gatewayCode?.length === 3
        "
      >
        <button class="btn bg-green-500 text-white" @click="handleSaveProfile">
          Save Profile
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import { mixin } from "../mixins/mixin";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";

export default {
  name: "employeeCreate",
  mixins: [mixin],
  components: { RSnackbar, RSpinner },
  data() {
    return {
      loading: false,

      showDateOfHireCalendar: false,

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,

      activeDirectoryEmployee: null,
      subGatewayCodesString: "",
    };
  },

  computed: {
    userCompanies() {
      let companies = [];

      this.user.securityGroup.companies.forEach((item) => {
        companies.push(item);
      });

      return companies;
    },
    user() {
      return this.$store.getters.user;
    },

    employee() {
      return this.$store.getters.employee;
    },
  },

  methods: {
    async handleSaveProfile() {
      this.loading = true;

      try {
        const token = await aad.getIdTokenSilent();

        const res = await api.post(`/employees`, this.employee, {
          headers: { Authorization: "Bearer " + token.idToken.rawIdToken },
        });

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.$router.push({ name: "Administration" });
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    handleGoBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
/* .container {
  position: relative;
  top: 54px;
  height: calc(100% - 55px);
  width: 100%;
  background-color: #fff;
  margin: 0 0 60px 0;
  padding: 10px;
} */

.profile-pic-figure {
  border: 1px solid gray;
  width: 150px;
  cursor: pointer;
}

.profile-pic-figure img {
  width: 100%;
  height: auto;
  min-width: 100px;
  max-width: 150px;
  border: 1px solid var(--gray);
}

.main-grid {
  display: grid;
  padding: 5px;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 5px;
  row-gap: 15px;
  align-items: center;
  margin: 0;
}

.container p {
  margin: 0;
  padding: 0;
  font-size: 0.8rem;
  color: var(--gray);
}

.employee-id,
.id-expiration-date,
.security-group,
.given-name,
.surname,
.job-title,
.company-name,
.gateway,
.sub-gateways,
.seniority-number,
.date-of-hire,
.mail,
.mobile-phone,
.emergency-contact-name,
.emergency-contact-number,
.address-street,
.address-city,
.address-zip,
.address-state,
.address-country {
  color: #fff;
  font-size: 1.3rem;
  padding: 0 5px;
  grid-column: 1/13;
}

@media (min-width: 768px) {
  .employee-id {
    grid-column: 1/4;
    grid-row: 1;
  }

  .id-expiration-date {
    grid-column: 4/8;
    grid-row: 1;
  }

  .security-group {
    grid-column: 8/13;
    grid-row: 1;
  }

  .given-name {
    grid-column: 1/4;
    grid-row: 2;
  }

  .surname {
    grid-column: 4/8;
    grid-row: 2;
  }

  .job-title {
    grid-column: 8/13;
    grid-row: 2;
  }

  .company-name {
    grid-column: 1/4;
    grid-row: 3;
  }

  .gateway {
    grid-column: 4/5;
    grid-row: 3;
  }

  .sub-gateways {
    grid-column: 5/8;
    grid-row: 3;
  }

  .seniority-number {
    grid-column: 8/9;
    grid-row: 3;
  }

  .date-of-hire {
    grid-column: 9/12;
    grid-row: 3;
  }

  .mail {
    grid-column: 1/9;
    grid-row: 4;
  }

  .mobile-phone {
    grid-column: 9/13;
    grid-row: 4;
  }

  .emergency-contact-name {
    grid-column: 1/9;
    grid-row: 5;
  }

  .emergency-contact-number {
    grid-column: 9/13;
    grid-row: 5;
  }

  .address-street {
    grid-column: 1/7;
    grid-row: 6;
  }

  .address-city {
    grid-column: 7/11;
    grid-row: 6;
  }

  .address-state {
    grid-column: 11/12;
    grid-row: 6;
  }

  .address-zip {
    grid-column: 12/13;
    grid-row: 6;
  }

  .address-country {
    grid-column: 1/10;
    grid-row: 7;
  }
}
</style>
